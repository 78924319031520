import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import Ably from 'ably';
import {
  selectSelectedLanguage,
  setSelectedLanguage,
  setSelectedMerchantIsOnline
} from '../store/appSlice';
import ContentLoader from 'react-content-loader';
import useNeatinAPIClient from '../utils/hooks/useNeatinAPIClient';
import MerchantContext from '../utils/contexts/MerchantContext';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useTranslation } from 'react-i18next';
import { Language } from '@neatin/api-client/dist/languages/types';
import {
  selectLabelsData,
  selectMerchantData,
  setLabelsData,
  setLoadingTranslations,
  setMerchantData,
  setLabels
} from '../store/merchantSlice';
import CategoriesStateContext from '../utils/contexts/CategoriesStateContext';
import { GetInitFontColours, GetMenuFont } from '../utils/useFullFunctions';
import { Helmet } from 'react-helmet-async';
import { colorEnums } from '../utils/enums/colourEnum';

const MenuRoute: React.FC<{}> = () => {
  const [restaurant, setRestaurant] = useState<any>(null);
  const [presence, setPresence] = useState<any>(null);
  const client = useNeatinAPIClient();
  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const merchantData = useAppSelector(selectMerchantData);
  const selectedLanguage = useAppSelector(selectSelectedLanguage);
  const labelsData = useAppSelector(selectLabelsData);
  const [openedCategories, setOpenedCategories] = useState<number[]>([]);
  const font = GetMenuFont(restaurant);
  const fontValue = font.replace(/ /gi, '+');
  GetInitFontColours(restaurant);

  useEffect(() => {
    if (restaurant?.id !== null && restaurant?.parallel_system_enable === 1) {
      const realtime = new Ably.Realtime({
        key: process.env.REACT_APP_ABLY_TOKEN,
        clientId: 'customer'
      });
      const presenceChannel = realtime.channels.get('presence-channel');
      const merchantChannel = realtime.channels.get(restaurant?.id);
      merchantChannel.subscribe((message) => {
        dispatch(
          setMerchantData({
            ...merchantData,
            can_accept_order: message.data.can_accept_order
          })
        );
        if (
          params.restaurant_id !== null &&
          params.restaurant_id !== undefined &&
          searchParams.get('table') !== null &&
          searchParams.get('table') !== undefined
        ) {
          getRestaurant(params.restaurant_id, searchParams.get('table'));
        }
      });
      presenceChannel.presence.subscribe(['enter', 'leave'], function (presenceUpdate) {
        setPresence(presenceUpdate);
      });
      presenceChannel.presence.enter();

      presenceChannel.presence.get((err, members) => {
        if (err !== null) {
          console.error(err);
        }
        const merchantIsAvailable = members?.findIndex(
          (member) =>
            member.action === 'present' &&
            member.clientId === `merchant-${restaurant?.id as string}`
        );
        if (merchantIsAvailable !== undefined && merchantIsAvailable >= 0) {
          dispatch(setSelectedMerchantIsOnline(true));
        } else {
          dispatch(setSelectedMerchantIsOnline(false));
        }
      });

      return () => {
        presenceChannel.presence.leave();
        presenceChannel.presence.unsubscribe();
        presenceChannel.unsubscribe();
        merchantChannel.unsubscribe();
      };
    }
  }, [restaurant?.id]);

  useEffect(() => {
    if (
      presence?.clientId === `merchant-${restaurant?.id as string}` &&
      presence?.action === 'enter'
    ) {
      dispatch(setSelectedMerchantIsOnline(true));
    }
    if (
      presence?.clientId === `merchant-${restaurant?.id as string}` &&
      presence?.action === 'leave'
    ) {
      dispatch(setSelectedMerchantIsOnline(false));
    }
  }, [presence]);

  const getLabels = useCallback(
    (id: string) => {
      client
        .getAppMerchantsLabels(id)
        .then((res: any) => {
          const langData =
            labelsData !== null && labelsData !== undefined ? labelsData[id] ?? {} : {};
          const data = {
            [id]: {
              ...langData,
              [selectedLanguage ?? 'undefined']: res
            }
          };
          dispatch(setLabelsData(data));
          dispatch(setLabels(res));
        })
        .catch((err: any) => {
          console.error(err);
        });
    },
    [client, dispatch, labelsData, selectedLanguage]
  );

  const setInitialLanguage = useCallback(
    (merchant: any) => {
      const currentLanguage = i18n?.language?.substring(0, 2)?.toLowerCase() ?? 'en';
      if (
        merchant?.languages
          ?.map((lang: Language) => lang.iso_639_1_code)
          .includes(currentLanguage) as boolean
      ) {
        i18n.changeLanguage(currentLanguage).then(
          () => {
            dispatch(setSelectedLanguage(currentLanguage));
          },
          () => {}
        );
      } else if (
        merchant?.languages?.map((lang: Language) => lang.iso_639_1_code).includes('en') as boolean
      ) {
        i18n.changeLanguage('en').then(
          () => {
            dispatch(setSelectedLanguage('en'));
          },
          () => {}
        );
      } else {
        i18n.changeLanguage(merchant.defaultLanguage).then(
          () => {
            dispatch(setSelectedLanguage(merchant.defaultLanguage));
          },
          () => {}
        );
      }
    },
    [dispatch, i18n]
  );

  const getRestaurant = useCallback(
    (id: string, tableId: string | null) => {
      client
        .getAppMerchant(id, tableId)
        .then((res: any) => {
          setInitialLanguage(res);
          const langData =
            merchantData !== null && merchantData !== undefined ? merchantData[id] ?? {} : {};
          const data = {
            [id]: {
              ...langData,
              [selectedLanguage ?? 'undefined']: res
            }
          };
          dispatch(setMerchantData(data));
          setRestaurant(res);
          dispatch(setLoadingTranslations(false));
          setLoading(false);
        })
        .catch((err: any) => {
          console.error(err);
          setLoading(false);
        });
    },
    [client, dispatch, selectedLanguage, merchantData, setInitialLanguage]
  );

  useEffect(() => {
    if (params?.restaurant_id !== null && params?.restaurant_id !== undefined) {
      let data =
        labelsData !== null && labelsData !== undefined ? labelsData[params.restaurant_id] : {};
      if (data === null || data === undefined) {
        data = { [params.restaurant_id]: {} };
      }
      const langData = data[selectedLanguage ?? 'undefined'];

      if (langData === null || langData === undefined) {
        getLabels(params.restaurant_id);
      } else {
        setLabelsData(langData);
        dispatch(setLabels(langData));
      }
    }
  }, [getLabels, params?.restaurant_id, dispatch, labelsData, selectedLanguage]);

  useEffect(() => {
    if (params?.restaurant_id !== null && params?.restaurant_id !== undefined) {
      let data =
        merchantData !== null && merchantData !== undefined
          ? merchantData[params.restaurant_id]
          : {};
      if (data === null || data === undefined) {
        data = { [params.restaurant_id]: {} };
      }
      const langData = data[selectedLanguage ?? 'undefined'];
      if (langData === null || langData === undefined) {
        getRestaurant(params.restaurant_id, searchParams.get('table'));
      } else {
        setRestaurant(langData);
        setLoading(false);
        dispatch(setLoadingTranslations(false));
      }
    }
  }, [getRestaurant, params?.restaurant_id, selectedLanguage, merchantData, dispatch]);

  return (
    <>
      {restaurant !== null && restaurant !== undefined ? (
        <Helmet>
          <title>{`${restaurant.name as string} (neatin - Digital Menus)`}</title>
          {colorEnums.CircularBook === font ? (
            <link rel="stylesheet" href={`https://fonts.cdnfonts.com/css/circular-book`} />
          ) : (
            <link
              rel="stylesheet"
              href={`https://fonts.googleapis.com/css?family=${fontValue as string}`}
            />
          )}
        </Helmet>
      ) : (
        <Helmet>
          <title>{'neatin - Digital Menus'}</title>
        </Helmet>
      )}

      <MerchantContext.Provider value={restaurant ?? {}}>
        <CategoriesStateContext.Provider value={[openedCategories, setOpenedCategories]}>
          {loading ? (
            <div className=" w-11/12 mx-auto h-full">
              <ContentLoader
                viewBox="0 0 1000 10000"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb">
                <rect x="10" y="70" rx="30" ry="30" width="1000" height="630" />
                <rect x="10" y="730" rx="30" ry="30" width="1000" height="300" />
                <rect x="10" y="1060" rx="30" ry="30" width="1000" height="300" />
                <rect x="10" y="1390" rx="30" ry="30" width="1000" height="300" />
                <rect x="10" y="1720" rx="30" ry="30" width="1000" height="300" />
              </ContentLoader>
            </div>
          ) : (
            <>
              <Outlet />
            </>
          )}
        </CategoriesStateContext.Provider>
      </MerchantContext.Provider>
    </>
  );
};

export default MenuRoute;
