import React, { useContext } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import CategoriesStateContext from '../utils/contexts/CategoriesStateContext';
import useSortedAndFilteredProducts from '../utils/hooks/useSortedAndFilteredProducts';
import { DarkModeValueTextColor, GetMenuColor } from '../utils/useFullFunctions';
import MenuCategoryItem from './MenuCategoryItem';
import type { Category } from '@neatin/api-client/dist/categories/types';
import MerchantContext from '../utils/contexts/MerchantContext';

interface MenuCategoryProps {
  category: Category;
}

const MenuCategory: React.FC<MenuCategoryProps> = ({ category }: any) => {
  const [openedCategories, setOpenedCategories] = useContext(CategoriesStateContext);
  const items = useSortedAndFilteredProducts(category?.products ?? []);
  const merchant = useContext(MerchantContext);

  const show = (): boolean => openedCategories.includes(category.id);

  const setShow = (): void => {
    if (show()) {
      setOpenedCategories((ids: number[]) => ids.filter((id) => id !== category.id));
    } else {
      setOpenedCategories((ids: number[]) => [...ids, category.id]);
    }
  };

  return (
    <>
      {items.length > 0 && (
        <div id={encodeURIComponent(category.name)} className="relt md:mx-4 px-3 pb-3">
          <div
            className={`relt flex justify-between px-1 py-2 text-3x items-center font-bold  rounded-lg ${
              !show() ? ' border border-1 border-gray-200 ' : ''
            }`}
            style={{
              fontSize: undefined,
              backgroundColor: show() ? GetMenuColor(merchant) : undefined,
              color: show() ? '#fff' : DarkModeValueTextColor(merchant, '#64748B')
            }}
            onClick={() => setShow()}>
            <span className="grow text-center">{category.name}</span>
            {show() ? <FaCaretUp /> : <FaCaretDown />}
          </div>
          {show() && (
            <div
              className="relt text-base text-center pt-1"
              style={{ fontSize: 14, color: DarkModeValueTextColor(merchant, '#64748B') }}
              dangerouslySetInnerHTML={{ __html: category.description }}></div>
          )}
          {show() &&
            items?.map((item: any, index) => (
              <div key={item.id}>
                <MenuCategoryItem item={item} />
                {index < items.length - 1 && (
                  <div
                    className="w-11/12 mx-auto"
                    style={{ height: '0.25px', backgroundColor: '#ccc' }}
                    id={`t${index}`}></div>
                )}
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default React.memo(MenuCategory);
