import React, { useContext, useState } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import PopupInfos from './PopupInfos';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import SocialLinks from './SocialLinks';
import QuillDisplay from './QuillDisplay';
import LanguageIndicator from './LanguageIndicator';
import MerchantContext from '../utils/contexts/MerchantContext';
import { SelectModeValue } from '../utils/useFullFunctions';
import { FaCookieBite } from 'react-icons/fa';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectSelecteCookie, setSelectedCookie } from '../store/appSlice';
import ReactTooltip from 'react-tooltip';

const MenuPageHeader: React.FC<{}> = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  // const merchant = useContext(MerchantContext);
  const selectCookie = useAppSelector(selectSelecteCookie);
  const dispatch = useAppDispatch();
  const closeModal = (): void => {
    setOpenModal(false);
  };
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const restaurant = useContext(MerchantContext);
  const { t } = useTranslation();

  return (
    <header className=" relt w-11/12 mx-auto ">
      <SwipeableDrawer
        className="customDrawer"
        anchor="bottom"
        open={openModal}
        onClose={() => {
          closeModal();
        }}
        onOpen={() => {
          setOpenModal(true);
        }}
        disableBackdropTransition={!iOS}
        disableDiscovery={true}
        disableSwipeToOpen={true}>
        <React.Suspense fallback={null}>
          <PopupInfos closePopup={closeModal} />
        </React.Suspense>
      </SwipeableDrawer>

      {restaurant?.languages?.length > 0 ? (
        <>
          <div className="dark pt-1 flex justify-between">
            <span></span>
            <span className="text-gray-400">
              {restaurant?.table !== null && restaurant?.table !== undefined ? (
                <>
                  {restaurant?.table?.parents != null && restaurant?.table?.parents?.length > 0
                    ? (restaurant.table.parents[0].label as string) + ' - '
                    : null}{' '}
                  {restaurant?.table?.label}
                </>
              ) : null}
            </span>
            <span className="flex space-x-1.5">
              {selectCookie?.isSet && (
                <p data-tip={t('set-your-cookie-preference')}>
                  <FaCookieBite
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(setSelectedCookie({ ...selectCookie, enable: false }));
                    }}
                  />
                </p>
              )}
              <MdInfoOutline size={24} onClick={() => setOpenModal(true)} />
            </span>
          </div>
        </>
      ) : null}

      <div
        className=" relt rounded-3xl mt-2 aspect-video"
        style={{
          backgroundSize: 'cover',
          backgroundImage:
            restaurant?.banner_image !== null && restaurant?.banner_image !== undefined
              ? `url(${process.env.REACT_APP_UPLOADS_URL ?? ''}/${
                  restaurant.banner_image as string
                }`
              : undefined
        }}></div>

      <div className=" relt w-16 md:w-32 h-16 md:h-32 -mt-12 md:-mt-16 bg-white rounded-full p-1">
        <div
          className=" relt w-full h-full rounded-full"
          style={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage:
              restaurant?.logo_image !== null &&
              restaurant?.logo_image !== undefined &&
              process.env.REACT_APP_UPLOADS_URL !== null &&
              process.env.REACT_APP_UPLOADS_URL !== undefined
                ? `url(${process.env.REACT_APP_UPLOADS_URL}/${restaurant.logo_image as string}`
                : undefined
          }}></div>
      </div>

      <div
        className=" w-full flex justify-between items-center"
        style={{ color: SelectModeValue(restaurant, 'white', '#1E293B') }}>
        <span className="w-3/4" style={{ fontSize: 24, fontWeight: 700 }}>
          {restaurant.name}
        </span>
        <LanguageIndicator />
      </div>
      <div className="restaurant-description">
        {/* <QuillDisplay html={restaurant.description} color={DarkModeValueTextColor(restaurant, '')} /> */}
        <QuillDisplay html={restaurant.description} />
      </div>
      <SocialLinks />
      <ReactTooltip />
    </header>
  );
};

export default React.memo(MenuPageHeader);
