import React, { useContext } from 'react';
import { Product } from '@neatin/api-client/dist/products/types';
import { useAppSelector } from '../store/hooks';
import { selectOrderItems } from '../store/orderSlice';
import { GetMenuColor } from '../utils/useFullFunctions';
import MerchantContext from '../utils/contexts/MerchantContext';

interface ProductQuantityIndicatorProps {
  item: Product;
}

const ProductQuantityIndicator: React.FC<ProductQuantityIndicatorProps> = ({ item }) => {
  const cart = useAppSelector(selectOrderItems);
  const merchant = useContext(MerchantContext);
  const quantity = (): number => {
    const filtered = cart.filter((e) => e.productId === item.id);
    // f (filtered.length !== 1) return 0;
    // console.log(filtered, cart, item.id);
    return filtered.map((i) => i.quantity).reduce((prev, curr) => prev + curr, 0);
  };

  const qty = quantity();

  return (
    <>
      {merchant.table !== null && merchant.table !== undefined && qty > 0 ? (
        <div
          className="text-white text-xs rounded-full aspect-square mr-1 h-4 flex items-center justify-evenly"
          style={{ backgroundColor: GetMenuColor(merchant) }}>
          {quantity()}
        </div>
      ) : null}
    </>
  );
};

export default ProductQuantityIndicator;
