import React, { useContext, useState } from 'react';
import PopupProduct from './PopupProduct';
// import { useAppSelector } from '../store/hooks';
import SavedIndicator from './SavedIndicator';
import PreferenceIndicator from './PreferenceIndicator';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { getParamByISO } from 'iso-country-currency';
import MerchantContext from '../utils/contexts/MerchantContext';
import { DarkModeValueTextColor, GetMenuColor } from '../utils/useFullFunctions';
// import { selectLabels } from '../store/merchantSlice';
import { Product } from '@neatin/api-client/dist/products/types';
import ProductQuantityIndicator from './ProductQuantityIndicator';
import GA4React from 'ga-4-react';
import { selectSelecteCookie } from '../store/appSlice';
import { useAppSelector } from '../store/hooks';

const htmlToPlainText = (html: string): string => {
  return new DOMParser().parseFromString(html, 'text/html')?.documentElement?.textContent ?? '';
};

interface MenuCategoryItemProps {
  item: Product;
}

const MenuCategoryItem: React.FC<MenuCategoryItemProps> = ({ item }) => {
  const ga4: any = GA4React.getGA4React();
  const selectCookie = useAppSelector(selectSelecteCookie);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const closeModal = (): void => {
    setOpenModal(false);
  };
  // const labels = useAppSelector(selectLabels);
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const merchant: any = useContext(MerchantContext);
  const currencySymbol =
    merchant?.country !== null && merchant?.country !== undefined
      ? getParamByISO(merchant.country ?? 'GE', 'symbol')
      : '';

  return (
    <>
      <SwipeableDrawer
        className="  customDrawer"
        anchor="bottom"
        open={openModal}
        onClose={() => {
          closeModal();
        }}
        onOpen={() => {
          setOpenModal(true);
        }}
        disableBackdropTransition={!iOS}
        disableDiscovery={true}
        disableSwipeToOpen={true}>
        <PopupProduct closePopup={closeModal} item={item} />
      </SwipeableDrawer>
      <div
        className={`flex justify-between gap-4 py-3 px-3 max-w-full`}
        onClick={() => {
          setOpenModal(true);
          try {
            if (selectCookie?.consent?.analytics) {
              ga4.event('view_product', merchant?.name, 'product', false);
            }
          } catch (error) {}
        }}>
        <div
          className={`grow ${item.image !== null && item.image !== undefined ? 'w-1/2' : 'w-3/4'}`}>
          <div className="flex items-center">
            {merchant?.order_pay_enable === 1 ? <ProductQuantityIndicator item={item} /> : null}
            <span className="font-bold truncate">{item.name}</span>
          </div>
          <div
            className="truncate-description"
            style={{
              fontSize: 14,
              color: DarkModeValueTextColor(merchant, '#64748B'),
              height: 40
            }}>
            {htmlToPlainText(item?.description ?? '')}
          </div>
          <div className="flex justify-between items-center">
            <div className="font-bold" style={{ color: GetMenuColor(merchant), fontSize: 16 }}>
              {item.price} {currencySymbol}
            </div>
            <div>
              <PreferenceIndicator item={item} />
            </div>
          </div>
        </div>
        <div
          className={
            (item.image !== null && item.image !== undefined ? ' aspect-video ' : ' w-auto ') +
            '  h-20 flex justify-end rounded-xl'
          }
          style={{
            backgroundImage:
              item.image !== null &&
              item.image !== undefined &&
              process.env.REACT_APP_UPLOADS_URL !== null &&
              process.env.REACT_APP_UPLOADS_URL !== undefined
                ? `url(${process.env.REACT_APP_UPLOADS_URL}/${item.image})`
                : undefined,
            backgroundSize: 'cover'
          }}>
          <SavedIndicator item={item} />
        </div>
      </div>
    </>
  );
};

export default React.memo(MenuCategoryItem);
