import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { selectSelecteCookie, setRemovePreferences, setSelectedCookie } from '../store/appSlice';
import { GetMenuColor } from '../utils/useFullFunctions';
import MerchantContext from '../utils/contexts/MerchantContext';
import Switch from 'react-switch';

interface settingsParans {
  analytics: boolean;
  targetedAdvertising: boolean;
}

const Cookies: React.FC<{}> = () => {
  const selectCookie = useAppSelector(selectSelecteCookie);
  const [shownBan, setShowBan] = useState(true);
  const [shownMoreConsent, setShowMoreConsent] = useState<boolean>(true);
  const merchant = useContext(MerchantContext);
  const color = GetMenuColor(merchant);
  const defaultSettings: settingsParans = selectCookie?.isSet
    ? {
        analytics: selectCookie?.consent?.analytics,
        targetedAdvertising: selectCookie?.consent?.targetedAdvertising
      }
    : { analytics: true, targetedAdvertising: true };
  const [settings, setSettings] = useState<settingsParans>(defaultSettings);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectCookie?.isSet || !selectCookie?.enable) {
      setSelectedCookie(
        selectCookie?.isSet
          ? {
              analytics: selectCookie?.consent?.analytics,
              targetedAdvertising: selectCookie?.consent?.targetedAdvertising
            }
          : { analytics: true, targetedAdvertising: true }
      );
      setShowBan(false);
    } else {
      setShowBan(true);
    }
    if (!selectCookie?.consent?.targetedAdvertising) {
      dispatch(setRemovePreferences(true));
    }
  }, [selectCookie]);

  const acceptCookie: any = () => {
    dispatch(setRemovePreferences(true));
    dispatch(
      setSelectedCookie({
        isSet: true,
        enable: true,
        consent: {
          all: true,
          analytics: true,
          targetedAdvertising: true
        }
      })
    );
    setShowBan(true);
  };

  const showMoreConsent: any = () => {
    setShowMoreConsent(false);
  };

  const acceptCookiePreference: any = () => {
    if (!selectCookie?.consent?.targetedAdvertising) {
      dispatch(setRemovePreferences(true));
    }
    dispatch(
      setSelectedCookie({
        isSet: true,
        enable: true,
        consent: {
          all: false,
          analytics: settings.analytics,
          targetedAdvertising: settings.targetedAdvertising
        }
      })
    );
    setShowBan(true);
  };

  const CookieIcon: React.FC<any> = () => {
    return (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5979 0C20.2567 0 20.7916 0.542132 20.7916 1.20968C20.7916 1.87723 20.2567 2.41936 19.5979 2.41936C18.9392 2.41936 18.4042 1.87723 18.4042 1.20968C18.4062 0.542132 18.9392 0 19.5979 0ZM19.9173 10.5352C20.9473 12.0928 22.2508 12.5702 23.9436 11.5163C23.9995 12.1029 24.0135 12.7017 23.9835 13.3085C23.6502 20.0164 18.015 25.1808 11.3957 24.843C4.77834 24.5031 -0.317896 18.7926 0.0154655 12.0867C0.34683 5.37884 6.17765 0.157785 12.795 0.495606C12.2081 2.35665 12.7051 4.00935 14.1983 4.57171C12.8449 8.97754 15.8671 11.8601 19.9173 10.5352ZM5.99401 9.66734C6.98611 9.66734 7.78857 10.4826 7.78857 11.4859C7.78857 12.4893 6.98411 13.3045 5.99401 13.3045C5.00191 13.3045 4.19945 12.4893 4.19945 11.4859C4.19945 10.4826 5.0039 9.66734 5.99401 9.66734ZM11.6472 11.9856C12.2181 11.9856 12.6812 12.4549 12.6812 13.0334C12.6812 13.612 12.2181 14.0813 11.6472 14.0813C11.0763 14.0813 10.6132 13.612 10.6132 13.0334C10.6112 12.4549 11.0743 11.9856 11.6472 11.9856ZM7.15977 16.3024C7.85644 16.3024 8.42135 16.8749 8.42135 17.5808C8.42135 18.2868 7.85644 18.8593 7.15977 18.8593C6.46311 18.8593 5.89819 18.2868 5.89819 17.5808C5.89819 16.8728 6.46311 16.3024 7.15977 16.3024ZM9.87856 6.51974C10.4255 6.51974 10.8667 6.96882 10.8667 7.52107C10.8667 8.07534 10.4235 8.52239 9.87856 8.52239C9.33161 8.52239 8.89046 8.07331 8.89046 7.52107C8.89046 6.96882 9.33361 6.51974 9.87856 6.51974ZM15.2483 16.7494C16.1645 16.7494 16.9051 17.502 16.9051 18.4284C16.9051 19.3569 16.1625 20.1074 15.2483 20.1074C14.332 20.1074 13.5914 19.3549 13.5914 18.4284C13.5914 17.502 14.334 16.7494 15.2483 16.7494ZM18.7376 4.67285C19.3524 4.67285 19.8514 5.17857 19.8514 5.80162C19.8514 6.42467 19.3524 6.93039 18.7376 6.93039C18.1228 6.93039 17.6237 6.42467 17.6237 5.80162C17.6237 5.17857 18.1228 4.67285 18.7376 4.67285Z"
          fill="url(#paint0_linear_936_8867)"></path>
        <defs>
          <linearGradient
            id="paint0_linear_936_8867"
            x1="11.9995"
            y1="0"
            x2="11.9995"
            y2="24.8587"
            gradientUnits="userSpaceOnUse">
            <stop stopColor={color}></stop>
            <stop offset="1" stopColor={color}></stop>
          </linearGradient>
        </defs>
      </svg>
    );
  };
  return (
    <div
      className={`shadow-2xl z-50 py-4 no-scrollbar overflow-x-auto  fixed msm:inset-x-0  sm:right-10 sm:bottom-10 rounded-2xl w-[85%] sm:w-[500px] ${
        shownMoreConsent
          ? 'msm:mx-auto msm:top-[29%] msm:bottom-[29%] msm:h-[42%]'
          : 'msm:mx-auto msm:top-[15%] msm:bottom-[15%] msm:h-[70%]'
      } `}
      style={{
        display: shownBan ? 'none' : 'block',
        backgroundColor: 'white',
        color: '#64748B',
        opacity: 1,
        maxHeight: '70%'
      }}>
      <div>
        <div className="justify-items">
          <div className="p-4">
            <p style={{ fontSize: 20 }} className="flex">
              <CookieIcon className="mr-3 h-6 w-6" fill="yellow" />
              <span className="pl-4">
                <strong>
                  <Trans i18nKey={'cookie-title'}></Trans>
                </strong>
              </span>
            </p>
            <div>
              <p className="justify-center p-4" style={{ color: '#64748B', fontSize: 14 }}>
                <Trans i18nKey={'cookie-content'}></Trans>
              </p>
              {shownMoreConsent && (
                <div className="w-full justify-between fond-bold" style={{ fontSize: 15 }}>
                  <div className="">
                    <button
                      onClick={acceptCookie}
                      className="flex  justify-center px-8  items-center rounded-lg text-white py-3 w-full"
                      style={{
                        backgroundColor: color
                      }}>
                      <Trans i18nKey={'Accept-All'}></Trans>
                    </button>
                  </div>
                  <div className="">
                    <button
                      onClick={showMoreConsent}
                      className="flex  justify-center   items-center rounded-lg text-default py-3 w-full"
                      style={{}}>
                      <Trans i18nKey={'Manage-Settings'}></Trans>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              display: shownMoreConsent ? 'none' : 'block'
            }}>
            <div className="px-8">
              <p style={{ fontSize: 20 }} className="flex ">
                <span className="mr-3">
                  {' '}
                  <Trans i18nKey={'Consent-preferences'}></Trans>
                </span>
              </p>
              <div>
                <div>
                  <div style={{ fontSize: 17 }} className="flex py-4 pb-2 justify-between">
                    <span className="mr-3">
                      {' '}
                      <Trans i18nKey={'Essential'}></Trans>
                    </span>
                    <span className="mr-3 text-slate-500">
                      {' '}
                      <Trans i18nKey={'Always-active'}></Trans>
                    </span>
                  </div>
                  <p className="text-justify " style={{ color: '#64748B', fontSize: 14 }}>
                    <Trans i18nKey={'Essential-text'}></Trans>
                  </p>
                </div>
                <div>
                  <div style={{ fontSize: 17 }} className="flex py-4 justify-between">
                    <span className="mr-3">
                      <Trans i18nKey={'Preferences'}></Trans>
                    </span>
                    <span className="mr-3">
                      <Switch
                        onColor={color}
                        height={30}
                        onChange={(checked: boolean) => {
                          setSettings({ ...settings, targetedAdvertising: checked });
                        }}
                        checked={settings.targetedAdvertising}
                      />
                    </span>
                  </div>
                  <p className="text-justify " style={{ color: '#64748B', fontSize: 14 }}>
                    <Trans i18nKey={'Preferences-text'}></Trans>
                  </p>
                </div>
                <div>
                  <div style={{ fontSize: 17 }} className="flex py-4 justify-between">
                    <span className="mr-3">
                      {' '}
                      <Trans i18nKey={'Google-Analytics'}></Trans>
                    </span>
                    <span className="mr-3">
                      <Switch
                        onColor={color}
                        height={30}
                        onChange={(checked: boolean) => {
                          setSettings({ ...settings, analytics: checked });
                        }}
                        checked={settings.analytics}
                      />
                    </span>
                  </div>
                  <p className="text-justify " style={{ color: '#64748B', fontSize: 14 }}>
                    <Trans i18nKey={'Google-Analytics-text'}></Trans>
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full justify-between fond-bold pt-4 px-4" style={{ fontSize: 15 }}>
              <div className="">
                <button
                  onClick={acceptCookiePreference}
                  className="flex  justify-center px-8  items-center rounded-lg text-white py-3 w-full"
                  style={{
                    backgroundColor: color
                  }}>
                  <Trans i18nKey={'Accept'}></Trans>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
