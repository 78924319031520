/* eslint-disable */
import NeatinAPIClient from '@neatin/api-client';
import { useContext, useEffect } from 'react';
import { selectSelectedLanguage } from '../../store/appSlice';
import { useAppSelector } from '../../store/hooks';
import MerchantContext from '../contexts/MerchantContext';

let client = new NeatinAPIClient({
  basePath: process.env.REACT_APP_API_BASE_URL + '/'
});

const useNeatinAPIClient = () => {
  const selectedLanguage = useAppSelector(selectSelectedLanguage);
  const restaurant = useContext(MerchantContext);

  useEffect(() => {
    client = new NeatinAPIClient({
      basePath: process.env.REACT_APP_API_BASE_URL + '/',
      language: selectedLanguage ?? restaurant?.defaultLanguage ?? 'en'
    });
  }, [selectedLanguage, restaurant]);

  return client;
};

export default useNeatinAPIClient;
