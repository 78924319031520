/* eslint-disable */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import AppReducer from './appSlice';
import MerchantReducer from './merchantSlice';
import OrderReducer from './orderSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

const persistConfig = {
  key: 'neatin',
  storage,
  blacklist: ['merchant'],
  // whiteList: ['app', 'order']
};

const reducers = combineReducers({
  app: AppReducer,
  merchant: MerchantReducer,
  order: OrderReducer
});
const persistedReducer = persistReducer(persistConfig, reducers);

/*const store = configureStore({
  reducers: (persistedReducer),
  devTools: true,// process.env.NODE_ENV != 'production',
})*/

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }),
  devTools: process.env.NODE_ENV === 'development'
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
