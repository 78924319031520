import { Labels } from "./labels";
import { StandardEmojis } from "./standard-emojis";
import { MerchantTypes } from "./merchant-types";
import { Categories } from "./categories";
import { Products } from "./products";
import { Users } from "./users";
import { Merchants } from "./merchants";
import { Auth } from "./auth";
import { App } from "./app";
import { MerchantSettings } from "./merchant-settings";
import { Languages } from "./languages";
import { Payments } from "./payments";

import { applyMixins } from "./utils";
import { Base } from "./base";
import { Orders } from "./orders";
import { Tables } from "./tables";
import { ProductVariants } from "./product_variants";
import { Poss } from "./pos";
import { MerchantPoss } from "./merchant-pos";
import { MerchantPaymentSettings } from "./merchant-payments-settings";
import { OrdersParallelSystem } from "./orders-parallel-system";
import { Printers } from "./printers";

class NeatinAPIClient extends Base {}
interface NeatinAPIClient
  extends Labels,
    StandardEmojis,
    MerchantTypes,
    Categories,
    Products,
    Users,
    Merchants,
    Auth,
    App,
    MerchantSettings,
    MerchantPaymentSettings,
    OrdersParallelSystem,
    Orders,
    Tables,
    Languages,
    ProductVariants,
    Poss,
    MerchantPoss,
    Payments,
    Printers {}

applyMixins(NeatinAPIClient, [
  Labels,
  StandardEmojis,
  MerchantTypes,
  Categories,
  Products,
  Users,
  Merchants,
  Auth,
  App,
  MerchantSettings,
  MerchantPaymentSettings,
  OrdersParallelSystem,
  Orders,
  Tables,
  Languages,
  ProductVariants,
  Poss,
  MerchantPoss,
  Payments,
  Printers
]);

export default NeatinAPIClient;
