/* eslint-disable */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import MerchantContext from '../utils/contexts/MerchantContext';
import { Product } from '@neatin/api-client/dist/products/types';
import { Language } from '@neatin/api-client/dist/languages/types';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  Order,
  orderTotalPrice,
  selectOrderItems,
  selectOrderNote,
  setOrderId
} from '../store/orderSlice';
import OrderItem from '../components/OrderItem';
import { ReactComponent as NoteIcon } from '../icons/note.svg';
import { SwipeableDrawer } from '@mui/material';
import PopupOrderNote from '../components/PopupOrderNote';
import { GetMenuColor, allItemsInCartPrintable, enableOrder } from '../utils/useFullFunctions';
import { getParamByISO } from 'iso-country-currency';
// import PaymentPage from '../pages/PaymentPage';
import { useNavigate, useParams } from 'react-router-dom';
import useNeatinAPIClient from '../utils/hooks/useNeatinAPIClient';
import { NewOrder } from '@neatin/api-client/dist/orders/types';
// import useNeatinAPIClient from '../utils/hooks/useNeatinAPIClient';
// import { SelectModeValue, GetMenuColor } from '../utils/useFullFunctions';
import toast, { Toaster } from 'react-hot-toast';
import {
  selectSelecteCookie,
  selectSelectedLanguage,
  selectSelectedMerchantIsOnline
} from '../store/appSlice';
import { selectMerchantData } from '../store/merchantSlice';
// import { Calc } from 'calc-js';
import Big from 'big.js';
import GA4React from 'ga-4-react';
import { GA4ReactResolveInterface } from 'ga-4-react/dist/models/gtagModels';

const CartPage: React.FC<{}> = () => {
  const client = useNeatinAPIClient();
  // const tableId = '1';
  const merchant = useContext(MerchantContext);
  const params = useParams();
  const selectedLanguage = useAppSelector(selectSelectedLanguage);
  const merchantLanguage: Language[] = merchant.languages;
  const language = merchantLanguage.find(
    (language) => language.iso_639_1_code === selectedLanguage
  );
  const cart: Order[] = useAppSelector(selectOrderItems);
  const { t } = useTranslation();
  const [openNotePopup, setOpenNotePopup] = useState<boolean>(false);
  // const [openPaymentPopup, setOpenPaymentPopup] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const note = useAppSelector(selectOrderNote);
  const navigate = useNavigate();
  const [ga4, setGa4] = useState<any>(null);
  const gaCode = process.env.REACT_APP_GA_CODE ?? '';
  const selectCookie = useAppSelector(selectSelecteCookie);
  const merchantIsOnline = useAppSelector(selectSelectedMerchantIsOnline);
  const merchantData = useAppSelector(selectMerchantData);

  const isMenu = useMemo((): boolean => {
    return (
      merchant?.categories?.[0]?.childs !== null && merchant?.categories?.[0]?.childs !== undefined
    );
  }, [merchant]);
  // memoized cart to prevent unnecessary rerenders
  //
  const merchantCategories = useMemo((): any[] => {
    if (isMenu) {
      const categories = [];
      for (const c of merchant.categories) {
        categories.push(...c.childs);
      }
      return categories;
    }
    return merchant.categories;
  }, [merchant]);

  const merchantProducts = useMemo(() => {
    const res = [];
    for (const category of merchantCategories) {
      for (const product of category.products) {
        res.push(product);
      }
    }
    return res;
  }, [merchantCategories]);

  const getCart = useMemo(() => {
    return cart.filter((order) =>
      merchantProducts.map((product) => product.id).includes(order.productId)
    );
  }, [cart]);

  const getGa4 = (): void => {
    try {
      if (selectCookie?.consent?.analytics) {
        switch (GA4React.isInitialized()) {
          case true: {
            const ga4 = GA4React.getGA4React();
            if (ga4 !== null && ga4 !== undefined) {
              setGa4(ga4);
            }
            break;
          }
          case false: {
            const ga4react = new GA4React(gaCode);
            ga4react.initialize().then(
              (ga4: GA4ReactResolveInterface) => {
                setGa4(ga4);
              },
              (err: Error) => {
                console.error(err);
              }
            );
            break;
          }
        }
      }
    } catch (error) {}
  };

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const currencySymbol =
    merchant?.country !== null && merchant?.country !== undefined
      ? getParamByISO(merchant.country ?? 'GE', 'symbol')
      : '';

  const getProductsInCart = useMemo((): Array<Product & { quantity: number }> => {
    const res = [];
    const productPrice = new Map<number, number>();
    for (const order of cart) {
      productPrice.set(order.productId, order.quantity);
    }
    for (const category of merchantCategories) {
      for (const product of category.products) {
        res.push(product);
      }
    }
    return res
      .filter((product) => cart.map((order) => order.productId).includes(product.id))
      .map((product) => ({
        ...product,
        quantity: productPrice.get(product.id) ?? 0
      }));
  }, [merchantCategories, cart]);

  useEffect(() => {
    if (getProductsInCart?.length <= 0 || merchant.order_pay_enable !== 1) {
      if (params.restaurant_id !== undefined) {
        if (merchant?.table?.url_id !== null && merchant?.table?.url_id !== undefined) {
          navigate(`/${params.restaurant_id}?table=${merchant.table.url_id as string}`);
        } else {
          navigate(`/${params.restaurant_id}`);
        }
      }
    }
    getGa4();
  }, [cart]);

  const subTotal = useMemo((): Big => {
    return orderTotalPrice(merchant, cart);
  }, [merchant, cart]);

  const formatedCart = (): any[] => {
    const items: any = [];

    getCart.forEach((el) => {
      items.push({
        quantity: el.quantity,
        product_id: el.variant ?? el.productId,
        addons: el?.addons?.map((a) => ({ addon_id: a.addonId, quantity: a.quantity }))
      });
    });
    return items;
  };

  const createOrder = async (): Promise<void> => {
    const orderParam: NewOrder = {
      language_id: language?.id,
      merchant_id: merchant.id,
      items: formatedCart(),
      note: note as any,
      table_id: merchant.table.id
    };
    const message: any = t('creating-order-process');
    toast.loading(message);
    ga4.event('proceed_to_checkout', merchant?.name, 'order', true);
    const orderResult: any = await client.createOrder(orderParam);
    if (orderResult?.status === true) {
      dispatch(setOrderId(orderResult?.data?.id));
      // if (params.restaurant_id !== undefined && params.table_id !== undefined) {
      if (merchant?.table?.url_id !== null && merchant?.table?.url_id !== undefined) {
        if (merchant.payment_enable === 1) {
          navigate(
            `/${params?.restaurant_id ?? ''}/payment-overview?table=${
              merchant.table.url_id as string
            }`
          );
        } else {
          navigate(
            `/${params?.restaurant_id ?? ''}/order-successful/${
              orderResult?.data?.id as string
            }?table=${merchant.table.url_id as string}`
          );
        }
      } else {
        // ??????????
        navigate(`/${params?.restaurant_id ?? ''}/payment-overview`);
      }
      // }
    }
  };

  const goBack = useCallback(() => {
    if (merchant.url_id !== undefined && merchant.url_id !== null) {
      if (merchant?.table?.url_id !== null && merchant?.table?.url_id !== undefined) {
        navigate(`/${merchant.url_id as string}?table=${merchant.table.url_id as string}`);
      } else {
        navigate(`/${merchant.url_id as string}`);
      }
    }
  }, [merchant]);

  return (
    <>
      {merchant.pos_enable === 1 ? (
        <>
          <SwipeableDrawer
            className="customDrawer"
            anchor="bottom"
            open={openNotePopup}
            onClose={() => {
              setOpenNotePopup(false);
            }}
            onOpen={() => {
              setOpenNotePopup(true);
            }}
            disableBackdropTransition={!iOS}
            disableDiscovery={true}
            disableSwipeToOpen={true}>
            <PopupOrderNote
              closePopup={() => {
                setOpenNotePopup(false);
              }}
            />
          </SwipeableDrawer>

          <div
            className="relt text-gray-800 pt-4 break-words"
            style={{ height: window.innerHeight }}>
            <div className=" relt flex">
              <div className=" relt p-2 pl-9 mr-1 absolute left-0 top-2 w-full" onClick={goBack}>
                <CloseIcon style={{ transform: 'scale(1.3)' }} />
              </div>
            </div>
            <div
              className="relt p-4 overflow-y-auto mt-5"
              style={{ height: window.innerHeight * 0.9 }}>
              <h1 className="relt font-bold text-center">
                <Trans i18nKey={'your-order'}></Trans>
              </h1>
              {merchant?.table !== null && merchant?.table !== undefined ? (
                <h4 className="text-center text-gray-400">
                  {merchant?.table?.parents != null && merchant?.table?.parents?.length > 0
                    ? (merchant.table.parents[0].label as string) + ' - '
                    : null}{' '}
                  {merchant?.table?.label}
                </h4>
              ) : null}
              <div className=" relt w-full mb-4">
                {getCart.map((order) => (
                  <div className="mb-2 px-2" key={order.id}>
                    <OrderItem item={order} />
                  </div>
                ))}
              </div>
              <div className="border border-1 w-full"></div>
              <div className="flex mt-2" onClick={() => setOpenNotePopup(true)}>
                <NoteIcon />
                <span className="font-bold pl-1">
                  <Trans i18nKey={'add-or-edit-note'}></Trans>
                </span>
              </div>
              {note !== null && note !== undefined && note !== '' ? (
                <div className="relt rounded-lg p-2">{note}</div>
              ) : null}
              <div className="h-64"></div>
            </div>
          </div>
          <div className="relt fixed bottom-0 w-full justify-evenly pb-5">
            {enableOrder(merchantData, merchantIsOnline) &&
              allItemsInCartPrintable(merchant, getProductsInCart) && (
                <button
                  disabled={subTotal.lte(0)}
                  className="p-3 mx-auto rounded-full text-white font-bold w-10/12 flex justify-between disabled:bg-gray-50"
                  onClick={() => {
                    // setOpenPaymentPopup(true);
                    createOrder().then(
                      () => {},
                      () => {}
                    );
                  }}
                  style={{
                    backgroundColor: GetMenuColor(merchant)
                  }}>
                  <span className="truncate">
                    {merchant.payment_enable === 1 ? (
                      <Trans i18nKey={'proceed-to-checkout'}></Trans>
                    ) : (
                      <Trans i18nKey={'send-order'}></Trans>
                    )}
                  </span>
                  <span>
                    {subTotal.valueOf()} {currencySymbol}
                  </span>
                </button>
              )}

            {!enableOrder(merchantData, merchantIsOnline) &&
              !allItemsInCartPrintable(merchant, getProductsInCart) && (
                <div className="col-span-3 w-10/12 m-auto text-center">
                  <span className="font-medium text-sm text-red-600 dark:text-red-500">
                    <Trans i18nKey={'merchant-cannot-accept-order'}></Trans>
                  </span>
                </div>
              )}
          </div>
        </>
      ) : null}
      <Toaster
        position="bottom-center"
        containerClassName=""
        toastOptions={{ className: 'rounded-full', duration: 2000 }}
      />
    </>
  );
};

export default CartPage;

// export default withTranslation()(PopupFilter);
