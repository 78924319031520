import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MenuRoute from './pages/MenuRoute';
import './App.css';
import SavedPage from './pages/SavedPage';
import PaymentOverviewPage from './pages/PaymentOverviewPage';
import PaymentPage from './pages/PaymentPage';
import MenuPage from './pages/MenuPage';
import PaymentSuccessfulPage from './pages/PaymentSuccessfulPage';
import CartPage from './pages/CartPage';
import GA4React from 'ga-4-react';
import { GA4ReactResolveInterface } from 'ga-4-react/dist/models/gtagModels';
import { useAppSelector } from './store/hooks';
import { selectSelecteCookie } from './store/appSlice';
import OrderSuccessfulPage from './pages/OrderSuccessfulPage';

const ga4react = new GA4React(process.env.REACT_APP_GA_CODE ?? '', { send_page_view: false });

const App: React.FC<{}> = () => {
  const selectCookie = useAppSelector(selectSelecteCookie);
  useEffect(() => {
    if (selectCookie?.consent?.analytics) {
      try {
        ga4react.initialize().then(
          (ga4: GA4ReactResolveInterface) => {},
          (err: Error) => {
            console.error(err);
          }
        );
      } catch (error) {}
    }
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/:restaurant_id" element={<MenuRoute />}>
            <Route index element={<MenuPage />} />
            <Route path="favorite" element={<SavedPage />} />
            <Route path="payment-overview" element={<PaymentOverviewPage />} />
            <Route path="payment/:checkoutId/:orderId" element={<PaymentPage />} />
            <Route path="cart" element={<CartPage />} />
            <Route path="payment-successful/:orderId" element={<PaymentSuccessfulPage />} />
            <Route path="order-successful/:orderId" element={<OrderSuccessfulPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
