/* eslint-disable */
import React, { useContext } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import MerchantContext from '../utils/contexts/MerchantContext';
import { GetMenuColor } from '../utils/useFullFunctions';
const OpenApp = require('react-open-app').default;

interface PopupInfosProps {
  closePopup: () => void;
}

const PopupInfos: React.FC<PopupInfosProps> = ({ closePopup }: any) => {
  const restaurant = useContext(MerchantContext);

  return (
    <div className=" relt bg-white text-gray-800 rounded-t-3xl pt-4 w-full pb-10">
      <div className=" relt flex">
        <span
          className=" relt rounded-3xl mx-auto"
          style={{ height: 4, width: 128, backgroundColor: '#CBD5E1' }}></span>
        <div className=" relt p-2 mr-1 absolute right-0 top-2" onClick={closePopup}>
          <CloseIcon />
        </div>
      </div>
      <div className=" relt w-full h-auto flex justify-center">
        <div
          className=" relt rounded-full bg-white mt-5"
          style={{
            height: 100,
            width: 100,
            backgroundSize: 'cover',
            backgroundImage:
              restaurant?.logo_image !== null &&
              restaurant?.logo_image !== undefined &&
              process.env.REACT_APP_UPLOADS_URL !== null &&
              process.env.REACT_APP_UPLOADS_URL !== undefined
                ? `url(${process.env.REACT_APP_UPLOADS_URL}/${restaurant.logo_image as string})`
                : undefined
          }}></div>
      </div>
      <p className=" relt text-center font-bold my-3">{restaurant.name}</p>
      <div className=" relt ml-5 w-fit">
        <div className=" relt flex w-fit" style={{ color: '#64748B', fontSize: 14 }}>
          <OpenApp
            blank={true}
            href={`https://maps.google.com/?q=${restaurant.address as string}+${
              restaurant.zip as string
            }+${restaurant.city as string}+${restaurant.name as string}`}>
            <div className=" relt flex">
              <span className=" relt mr-1">
                <FaMapMarkerAlt color={GetMenuColor(restaurant)} />
              </span>
              <span className=" relt underline">
                {restaurant.address}, {restaurant.zip} {restaurant.city}
              </span>
            </div>
          </OpenApp>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PopupInfos);
