/* eslint-disable */
import { useContext } from 'react';
import MerchantContext from '../contexts/MerchantContext';

export const GetMenuColor = (merchant: any) => {
  // const merchant = useContext(MerchantContext);
  return GetMenuColorApp(merchant);
};
export const GetMenuColorApp = (merchant: any): string => {
  const settings =
    merchant?.old_settings && merchant?.old_settings?.value
      ? JSON.parse(merchant.old_settings.value.replace('\n', ''))
      : null;
  const showDefaultColours = settings?.colours?.value?.length > 0 && settings?.colours?.active;
  const coloursValue = showDefaultColours ? settings?.colours?.value : '#0D9488';
  return coloursValue;
};

export const GetDarkModeClass = (merchant: any) => {
  //const merchant = useContext(MerchantContext);
  const settings =
    merchant?.old_settings && merchant?.old_settings?.value
      ? JSON.parse(merchant.old_settings.value.replace('\n', ''))
      : null;
  const showDefaultdarkmode = settings?.darkmode?.active && settings?.darkmode?.value;
  const darkmodeClass = showDefaultdarkmode ? 'relt' : '';
  //console.log(darkmodeClass)
  //console.log(settings?.darkmode)
  return darkmodeClass;
};

export enum backgroundColor {
  darkgrey = '#424543'
}

export const GetMenuFont = (merchant: any) => {
  //const merchant = useContext(MerchantContext);
  return GetMenuFontApp(merchant);
};

export const SelectModeValue = (merchant: any, darkModeValue: string, normalValue: string) => {
  if (GetMenuDarkMode(merchant)) {
    return darkModeValue;
  }
  return normalValue;
};
export const DarkModeValueTextColor = (merchant: any, normalValue: string) => {
  if (GetMenuDarkMode(merchant)) {
    return '#bab5b5';
  }
  return normalValue;
};

export const DarkModeValueClass = (merchant: any, normalValue: string) => {
  if (GetMenuDarkMode(merchant)) {
    return 'darkDescriptionColor';
  }
  return normalValue;
};

export const GetColorByDarkMode = (merchant: any, color: string) => {
  return SelectModeValue(merchant, backgroundColor.darkgrey, color);
};

export const GetMenuFontApp = (merchant: any) => {
  const settings =
    merchant?.old_settings && merchant?.old_settings?.value
      ? JSON.parse(merchant.old_settings.value.replace('\n', ''))
      : null;
  const showDefaultfonts = settings?.fonts?.value?.length > 0 && settings?.fonts?.active;
  const fontsValue = showDefaultfonts ? settings?.fonts?.value : 'Nunito';
  return fontsValue;
};

export const GetMenuDarkMode = (merchant: any): boolean => {
  //const merchant = useContext(MerchantContext);
  const settings =
    merchant?.old_settings && merchant?.old_settings?.value
      ? JSON.parse(merchant.old_settings.value.replace('\n', ''))
      : null;
  const showDefaultdarkmode = settings?.darkmode?.active;
  const darkmodeValue = showDefaultdarkmode ? settings?.darkmode?.value : false;
  return darkmodeValue;
};

export const GetInitColours = (merchant: any) => {
  if (GetMenuDarkMode(merchant)) {
    document.documentElement.style.setProperty('--neatin-selectbackground-font', '#292929');
    document.documentElement.style.setProperty('--neatin-selectbackground-color2', '#dcdcdc');
    document.documentElement.style.setProperty('--neatin-selectbackground-color', '#fff');
  } else {
    document.documentElement.style.setProperty('--neatin-selectbackground-font', '#fff');
  }
};

export const GetInitFontColours = (merchant: any) => {
  const font = GetMenuFont(merchant);
  document.documentElement.style.setProperty('--neatin-selected-font', font);
  document.documentElement.style.setProperty(
    '--neatin-category-selected-color',
    GetMenuColor(merchant)
  );
  GetInitColours(merchant);
};

export const enableOrder = (
  merchant: { [key: string]: any },
  merchantIsOnline: boolean
): boolean => {
  const actualMerchant: any = Object.entries(Object.entries(merchant)?.[0]?.[1])?.[0]?.[1];
  const canAcceptOrderSetting = JSON.parse(
    actualMerchant.settings.find((setting: any) => setting?.key === 'can_accept_order')?.value ??
      '{"value": false}'
  )?.value;
  if (actualMerchant?.parallel_system_enable) {
    if (canAcceptOrderSetting === false || merchant?.can_accept_order === false) return false;
    return actualMerchant?.printer_enable || (actualMerchant?.tablet_enable && merchantIsOnline);
    // return (
    //   (actualMerchant?.printer_enable &&
    //     (merchant?.printer_status_ok ?? actualMerchant?.printer_status_ok)) ||
    //   (actualMerchant?.tablet_enable && merchantIsOnline)
    // );
  }
  return false;
};

export const allItemsInCartPrintable = (merchant: any, products: any): boolean => {
  if (merchant.printer_enable === 0) return true;
  return products?.every((product: any) => product.printable === 1);
};
