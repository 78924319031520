import React, { useContext, useState } from 'react';
import { Product } from '@neatin/api-client/dist/products/types';
import { Trans } from 'react-i18next';
import { FaCaretDown, FaCaretUp, FaCircle } from 'react-icons/fa';
import MerchantContext from '../utils/contexts/MerchantContext';
import { GetMenuColor, GetMenuDarkMode } from '../utils/useFullFunctions';

interface PreferenceAndAllergensProps {
  item: Product;
}

const PreferenceAndAllergensSection: React.FC<PreferenceAndAllergensProps> = ({ item }) => {
  const merchant = useContext(MerchantContext);
  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      {item?.labels !== null && item?.labels !== undefined && item?.labels.length > 0 ? (
        <div
          className={`${
            !GetMenuDarkMode(merchant) ? 'bg-gray-100' : ''
          } p-3 mt-2 flex justify-between items-center rounded-full border border-gray-300`}
          onClick={() => setShow((s) => !s)}>
          <span>
            <Trans i18nKey={'product-infos'}></Trans>
          </span>
          <span>{show ? <FaCaretUp color="#555" /> : <FaCaretDown color="#555" />}</span>
        </div>
      ) : null}
      {show &&
        item.labels !== null &&
        item.labels !== undefined &&
        item.labels.filter((label: any) => label.type === 'preference').length > 0 && (
          <div className=" relt mb-3 mt-2 px-2">
            <h1 className=" relt font-bold capitalize" style={{ color: '#64748B' }}>
              <Trans i18nKey={'preferences'}></Trans>
            </h1>
            <ul className="flex flex-wrap gap-2 w-full ">
              {item.labels
                .filter((label: any) => label.type === 'preference')
                .map((preference: any) => (
                  <li
                    className=" relt flex mt-1 border rounded-full border-gray-200 items-center pl-1 pr-2 w-fit"
                    key={preference.id}>
                    <span className="relt">
                      <FaCircle color={GetMenuColor(merchant)} size={10} />
                    </span>
                    <p className=" relt pl-1" style={{ fontSize: 16 }}>
                      <Trans i18nKey={preference?.name?.toLowerCase()}></Trans>
                    </p>
                  </li>
                ))}
            </ul>
          </div>
        )}
      {show &&
        item.labels !== null &&
        item.labels !== undefined &&
        item.labels.filter((label: any) => label.type === 'allergen').length > 0 && (
          <div className=" relt mb-3 mt-2 px-2">
            <h1 className=" relt font-bold capitalize" style={{ color: '#64748B' }}>
              <Trans i18nKey={'allergens'}></Trans>
            </h1>
            <ul className="flex flex-wrap gap-2 w-full">
              {item.labels
                .filter((label: any) => label.type === 'allergen')
                .map((preference: any) => (
                  <li
                    className=" relt flex mt-1 border rounded-full border-gray-200 items-center pl-1 pr-2 w-fit"
                    key={preference.id}>
                    <span className="relt">
                      <FaCircle color="#D01F46" size={10} />
                    </span>
                    <p className=" relt pl-1" style={{ fontSize: 16 }}>
                      <Trans i18nKey={preference?.name?.toLowerCase()}></Trans>
                    </p>
                  </li>
                ))}
            </ul>
          </div>
        )}

      {show &&
        item.labels !== null &&
        item.labels !== undefined &&
        item.labels.filter((label: any) => label.type === 'additive').length > 0 && (
          <div className=" relt mb-3 mt-2 px-2">
            <h1 className=" relt font-bold capitalize" style={{ color: '#64748B' }}>
              <Trans i18nKey={'additives'}></Trans>
            </h1>
            <ul className="flex flex-wrap gap-2 w-full">
              {item.labels
                .filter((label: any) => label.type === 'additive')
                .map((preference: any) => (
                  <li
                    className=" relt flex mt-1 border rounded-full border-gray-200 items-center pl-1 pr-2 w-fit"
                    key={preference.id}>
                    <span className="relt">
                      <FaCircle color="#D01F46" size={10} />
                    </span>
                    <p className=" relt pl-1" style={{ fontSize: 16 }}>
                      <Trans i18nKey={preference?.name?.toLowerCase()}></Trans>
                    </p>
                  </li>
                ))}
            </ul>
          </div>
        )}
    </>
  );
};

export default PreferenceAndAllergensSection;
