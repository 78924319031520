import { getParamByISO } from 'iso-country-currency';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import { AddonOrder } from '../store/orderSlice';
import MerchantContext from '../utils/contexts/MerchantContext';
import { GetMenuColor } from '../utils/useFullFunctions';

interface AddonGroupProps {
  item: any;
  setAddonsCb?: any; // function
}

const AddonGroup: React.FC<AddonGroupProps> = ({ item, setAddonsCb }) => {
  const [selected, setSelected] = useState<AddonOrder[]>([]);
  const merchant = useContext(MerchantContext);
  const { t } = useTranslation();
  const currencySymbol =
    merchant?.country !== null && merchant?.country !== undefined
      ? getParamByISO(merchant.country ?? 'GE', 'symbol')
      : '';

  const selectAddon = (addon: any): void => {
    if (item.multi_select === 0) {
      if (selected.map((s) => s.addonId).includes(addon.id)) {
        setSelected((s) => s.filter((i) => addon.id !== i.addonId));
      } else {
        setSelected([{ addonId: addon.id, quantity: 1 }]);
      }
    } else {
      if (selected.map((s) => s.addonId).includes(addon.id)) {
        setSelected((curr) => curr.filter((i) => addon.id !== i.addonId));
      } else {
        if (selected.length < item.max_selected) {
          setSelected((curr) => [...curr, { addonId: addon.id, quantity: 1 }]);
        } else {
          toast(t('maximum-selected'), {
            position: 'bottom-center'
          });
        }
      }
    }
  };

  useEffect(() => {
    if (setAddonsCb !== null && setAddonsCb !== undefined) {
      setAddonsCb((addons: AddonOrder[]) => {
        const addonsIds: number[] = item.products.map((p: any) => p.id);
        const tmp = addons.filter((e) => !addonsIds.includes(e.addonId));
        return [...tmp, ...selected];
      });
    }
  }, [selected]);

  const orderAndPayAndTable = useMemo(() => {
    return (
      merchant?.order_pay_enable === 1 && merchant.table !== null && merchant.table !== undefined
    );
  }, [merchant]);

  return (
    <>
      <div className="my-10" key={item.id}>
        <div className="border-b border-black flex justify-between pb-1">
          <h1 className="text-lg font-bold">{item.name}</h1>
          {item.skippable === 1 ? (
            <span className="border border-gray-400 rounded-full p-1 text-sm">
              <Trans i18nKey={'optional'}></Trans>
            </span>
          ) : (
            <span
              className="border rounded-full p-1 text-sm"
              style={{ borderColor: GetMenuColor(merchant) }}>
              <Trans i18nKey={'required'}></Trans>
            </span>
          )}
        </div>
        <ul>
          {item.products.map((addon: any) => (
            <li
              className={`grid ${
                orderAndPayAndTable ? 'grid-cols-6' : 'grid-cols-5'
              } justify-between my-2`}
              key={addon.id}
              onClick={() => selectAddon(addon)}>
              <span className="col-span-4">{addon.name}</span>
              <span className="text-gray-400 text-xs text-right pr-2">
                {addon.price < 0 ? addon.price : `+${addon.price as string}`} {currencySymbol}
              </span>
              {orderAndPayAndTable ? (
                <span className="flex justify-end">
                  {selected.map((s) => s.addonId).includes(addon.id) ? (
                    <FaCheckCircle color={GetMenuColor(merchant)} />
                  ) : (
                    <FaRegCircle color={GetMenuColor(merchant)} />
                  )}
                </span>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default AddonGroup;
