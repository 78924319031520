import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import MerchantContext from '../utils/contexts/MerchantContext';
import useNeatinAPIClient from '../utils/hooks/useNeatinAPIClient';
import { SelectModeValue, GetMenuColor } from '../utils/useFullFunctions';
import toast from 'react-hot-toast';
interface PopupOrderSendMailProps {
  closePopup: () => void;
  orderId: string | null;
  type?: string;
}

const PopupOrderSendMail: React.FC<PopupOrderSendMailProps> = ({
  closePopup,
  orderId,
  type = 'payment'
}: any) => {
  const merchant = useContext(MerchantContext);
  const client = useNeatinAPIClient();
  const [email, setEmail] = useState<string>('');
  const { t } = useTranslation();

  const handleChange = (e: any): void => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: any): void => {
    e.preventDefault();
    if (email?.length > 0) {
      if (type === 'payment') {
        client
          .sendOrderReciepMail(orderId, { email })
          .then(() => {
            const message: any = t('mail-success');
            toast.success(message);
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        client
          .sendSimpleOrderReceiptMail(orderId, { email })
          .then(() => {
            const message: any = t('mail-success');
            toast.success(message);
          })
          .catch((err) => {
            console.error(err);
          });
      }
      closePopup();
    }
  };

  const tEmailPlaceholder: any = t('your-email');

  return (
    <div className=" relt bg-white text-gray-800 rounded-t-3xl pt-4">
      <div className=" relt flex">
        <span
          className=" relt rounded-3xl mx-auto"
          style={{ height: 4, width: 128, backgroundColor: '#CBD5E1' }}></span>
        <div className=" relt p-2 mr-1 absolute right-0 top-2" onClick={closePopup}>
          <CloseIcon />
        </div>
      </div>

      <div className=" relt p-4">
        <h4 className="font-bold text-gray-400">
          <Trans i18nKey="your-email">Your Email</Trans>
        </h4>
        <div className=" relt w-full mb-4">
          <div className=" relt mx-auto bg-white w-2/6 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className=" relt flex flex-wrap w-full text-left mx-auto">
            <div className=" relt relative w-full">
              <input
                name="email"
                value={email}
                placeholder={tEmailPlaceholder}
                className={`${SelectModeValue(
                  merchant,
                  'customBarkBackground placeholder-white ',
                  'bg-gray-100 placeholder-gray-400 placeholder:italic placeholder:text-slate-400'
                )}  rounded-xl p-3 text-sm shadow focus:outline-none w-full resize-none`}
                maxLength={500}
                onChange={handleChange}
              />
            </div>

            <button
              className={` mx-auto lg:mx-0 text-white w-full text-gray-800 rounded-xl my-6 py-4 px-8`}
              style={{ backgroundColor: GetMenuColor(merchant), color: 'white' }}>
              <Trans i18nKey="send">Send</Trans>{' '}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupOrderSendMail;

// export default withTranslation()(PopupFilter);
