/* eslint-disable */
import React, { useContext } from 'react';
import instagramLogo from '../icons/instagram.png';
import googleLogo from '../icons/google.png';
import facebookLogo from '../icons/facebook.png';
import tripAdvisorLogo from '../icons/tripadvisor.jpg';
import { Trans } from 'react-i18next';
import MerchantContext from '../utils/contexts/MerchantContext';
import GA4React from 'ga-4-react';
import { GA4ReactResolveInterface } from 'ga-4-react/dist/models/gtagModels';
import { DarkModeValueTextColor, GetColorByDarkMode } from '../utils/useFullFunctions';
import { selectSelecteCookie } from '../store/appSlice';
import { useAppSelector } from '../store/hooks';

const OpenApp = require('react-open-app').default;

const SocialLinks: React.FC<{}> = () => {
  const gaCode = process.env.REACT_APP_GA_CODE ?? '';
  const merchant = useContext(MerchantContext);
  const settings =
    merchant?.old_settings?.value != null && merchant?.old_settings?.value !== undefined
      ? JSON.parse(merchant.old_settings.value.replace('\n', ''))
      : null;
  // console.log(merchant?.settings)
  const selectCookie = useAppSelector(selectSelecteCookie);
  const getGa4 = (action = 'click', data = ''): void => {
    try {
      if (selectCookie?.consent?.analytics) {
        switch (GA4React.isInitialized()) {
          case true: {
            const ga4 = GA4React.getGA4React();
            if (ga4 !== null && ga4 !== undefined) {
              ga4.event(action, data, data, true);
            }
            break;
          }
          case false: {
            const ga4react = new GA4React(gaCode);
            ga4react.initialize().then(
              (ga4: GA4ReactResolveInterface) => {},
              (err: Error) => {
                console.error(err);
              }
            );
            break;
          }
        }
      }
    } catch (error) {}
  };
  const GaEventTrackerSave = (action: string): void => {
    getGa4(action, merchant?.name ?? 'menu_page');
  };

  const showGoogleReviews: boolean =
    settings?.google?.review_url?.length > 0 && settings?.google?.active;

  const showInstagram: boolean =
    settings?.instagram?.username?.length > 0 && settings?.instagram?.active;

  const showFacebook: boolean =
    settings?.facebook?.username?.length > 0 && settings?.facebook?.active;

  const showTripAdvisor: boolean =
    settings?.tripadvisor?.review_url?.length > 0 && settings?.tripadvisor?.active;

  const show = showGoogleReviews || showInstagram || showFacebook || showTripAdvisor;

  return (
    <>
      {show && (
        <div
          className="  mt-4 w-100 rounded-xl flex justify-evenly py-4"
          style={{ backgroundColor: GetColorByDarkMode(merchant, '#F1F5F9') }}>
          {(showInstagram || showFacebook) && (
            <div>
              <h1
                className="  font-bold text-center"
                style={{ color: DarkModeValueTextColor(merchant, '#64748B') as any, fontSize: 14 }}>
                <Trans i18nKey={'follow-us-on'}></Trans>
              </h1>
              <ul className={`flex justify-center`}>
                {showInstagram && (
                  <li
                    className="  border b-1 border-gray-300 rounded-full p-1"
                    id="facebookclick"
                    onClick={() => {
                      GaEventTrackerSave('click_instagram');
                    }}>
                    <OpenApp
                      href={`https://www.instagram.com/${settings?.instagram?.username}`}
                      blank={true}>
                      <img src={instagramLogo} height={24} width={24} alt="Instagram" />
                    </OpenApp>
                  </li>
                )}
                {showFacebook && (
                  <li
                    className={`border b-1 border-gray-300 rounded-full p-1 ${
                      showInstagram && showFacebook ? 'ml-3' : ''
                    }`}
                    onClick={() => {
                      GaEventTrackerSave('click_facebook');
                    }}>
                    <OpenApp
                      href={`https://www.facebook.com/${settings?.facebook?.username as string}`}
                      android={`fb://facewebmodal/f?href=https://www.facebook.com/${
                        settings?.facebook?.username as string
                      }`}
                      blank={true}>
                      <img src={facebookLogo} height={24} width={24} alt="Facebook" />
                    </OpenApp>
                  </li>
                )}
              </ul>
            </div>
          )}

          {(showGoogleReviews || showTripAdvisor) && (
            <div>
              <h1
                className="  font-bold"
                style={{ color: DarkModeValueTextColor(merchant, '#64748B') as any, fontSize: 14 }}>
                <Trans i18nKey={'review-us-on'}></Trans>
              </h1>
              <ul className="  flex justify-center">
                {showGoogleReviews && (
                  <li
                    className={`border b-1 border-gray-300 rounded-full p-1`}
                    onClick={() => {
                      GaEventTrackerSave('click_googleview');
                    }}>
                    <OpenApp href={settings?.google?.review_url} blank={true}>
                      <img src={googleLogo} height={24} width={24} alt="Google reviews" />
                    </OpenApp>
                  </li>
                )}

                {showTripAdvisor && (
                  <li
                    className={` border b-1 border-gray-300 rounded-full p-1 ${
                      showGoogleReviews && showTripAdvisor ? 'ml-3' : ''
                    }`}
                    onClick={() => {
                      GaEventTrackerSave('click_tripadvisor');
                    }}>
                    <OpenApp href={settings?.tripadvisor?.review_url} blank={true}>
                      <img
                        src={tripAdvisorLogo}
                        height={24}
                        width={24}
                        alt="TripQdvisor"
                        className="rounded-full"
                      />
                    </OpenApp>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SocialLinks;
