import { Category } from '@neatin/api-client/dist/categories/types';
import React from 'react';
import { selectSelectedFav } from '../store/appSlice';
import { useAppSelector } from '../store/hooks';
import useSortedAndFilteredProducts from '../utils/hooks/useSortedAndFilteredProducts';
// import { useSortedAndFilteredProducts } from "../utils/customHooks";
import MenuCategoryItem from './MenuCategoryItem';

interface MenuCategorySavedProps {
  category: Category;
}

const MenuCategorySaved: React.FC<MenuCategorySavedProps> = ({ category }) => {
  const selectedFav = useAppSelector(selectSelectedFav);
  const items = useSortedAndFilteredProducts(category?.products ?? []);

  return (
    <>
      {category?.products != null &&
        category?.products?.filter((item: any) => selectedFav.includes(item.id)).length > 0 && (
          <div
            id={encodeURIComponent(category.name)}
            className=" relt w-full flex flex-wrap md:mx-4 px-4">
            <div className=" relt w-full">
              <div className=" relt w-full flex flex-wrap pt-4 ">
                <div className=" relt flex w-full text-4xl items-center">
                  <span className=" relt font-bold" style={{ fontSize: 20 }}>
                    {category.name}
                  </span>
                </div>
              </div>

              {items
                ?.filter((item: any) => selectedFav.includes(item.id))
                .map((item: any) => (
                  <MenuCategoryItem item={item} key={item.id} />
                ))}
            </div>
          </div>
        )}
    </>
  );
};

export default React.memo(MenuCategorySaved);
