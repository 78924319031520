import { AppBar, Box, SwipeableDrawer, Toolbar, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
// import PaymentMethodsSelect from '../components/PaymentMethodsSelect';
import {
  GetMenuColor,
  GetMenuFont,
  allItemsInCartPrintable,
  enableOrder
} from '../utils/useFullFunctions';
import { ReactComponent as BackArrow } from '../icons/back-arrow.svg';
import MerchantContext from '../utils/contexts/MerchantContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getParamByISO } from 'iso-country-currency';
import { useAppSelector } from '../store/hooks';
import {
  selectOrderItems,
  selectCustomTip,
  selectOrderId,
  orderTotalPrice
} from '../store/orderSlice';
// import { Product } from '@neatin/api-client/dist/products/types';
import PopupCustomTip from '../components/PopupCustomTip';
import useNeatinAPIClient from '../utils/hooks/useNeatinAPIClient';
import GA4React from 'ga-4-react';

// import toast, { Toaster } from 'react-hot-toast';
// import { Calc } from 'calc-js';
// import Dinero from 'dinero.js';
import Big from 'big.js';
// import PopupPayment from '../components/PopupPayment';
import { Toaster } from 'react-hot-toast';
import ElevationScroll from '../utils/helpers/ElevationScroll';
import {
  selectSelecteCookie,
  selectSelectedLanguage,
  selectSelectedMerchantIsOnline
} from '../store/appSlice';
import { selectMerchantData } from '../store/merchantSlice';
import { Product } from '@neatin/api-client/dist/products/types';

interface PaymentOverviewPageProps {
  closePopup?: () => void;
}

const PaymentOverviewPage: React.FC<PaymentOverviewPageProps> = (props) => {
  const [selectedTip, setSelectedTip] = useState<number | 'custom' | null>(0);
  const [openCustomTipPopup, setOpenCustomTipPopup] = useState<boolean>(false);
  const ga4: any = GA4React.getGA4React();
  const client = useNeatinAPIClient();
  const customTip = useAppSelector(selectCustomTip);
  const orderId = useAppSelector(selectOrderId);
  const merchant = useContext(MerchantContext);
  const cart = useAppSelector(selectOrderItems);
  const navigate = useNavigate();
  const params = useParams();
  const selectCookie = useAppSelector(selectSelecteCookie);
  const selectedLanguage = useAppSelector(selectSelectedLanguage);
  const merchantIsOnline = useAppSelector(selectSelectedMerchantIsOnline);
  const merchantData = useAppSelector(selectMerchantData);

  useEffect(() => {
    if (cart?.length <= 0 || merchant.order_pay_enable !== 1) {
      if (params.restaurant_id !== undefined) {
        navigate(`/${params.restaurant_id}`);
      }
    }
  }, []);
  // const salesTax = 1.1;
  const currencySymbol =
    merchant?.country !== null && merchant?.country !== undefined
      ? getParamByISO(merchant.country ?? 'GE', 'symbol')
      : '';

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const tips = [{ percentage: 10 }, { percentage: 15 }, { percentage: 20 }];

  const tipPercentage = (): number => {
    if (typeof selectedTip === 'number') {
      return tips[selectedTip].percentage;
    }
    return customTip * 100;
  };

  const subTotal = useCallback((): Big => {
    return orderTotalPrice(merchant, cart);
  }, [cart]);

  const tipsTotal = (): Big => {
    if (selectedTip !== undefined && selectedTip !== 'custom') {
      const st = subTotal();
      return Big(st).times(tipPercentage()).div(100).round(2, Big.roundHalfUp);
      // return new Calc(st).sum(salesTaxTotal()).multiply(tipPercentage()).divide(100).finish();
    } else if (selectedTip === 'custom') {
      return Big(customTip);
    }
    return Big(0);
  };

  const total = (): Big => {
    const total = subTotal().add(tipsTotal());
    return total.round(2, Big.roundHalfUp);
  };

  const onPay = async (): Promise<void> => {
    try {
      if (selectCookie?.consent?.analytics) {
        ga4.event('submit_payment', merchant?.name, 'payment', true);
      }
    } catch (error) {}
    const taxAmount = subTotal().times(9).div(100).round(2, Big.roundHalfUp).valueOf();

    const tipsAmount = tipsTotal();
    const payOrderParam: any = {
      merchant_id: merchant.id,
      order_id: orderId,
      tips_amount: tipsAmount,
      tax_amount: taxAmount,
      language: selectedLanguage,
      payment_type_id: 1
    };
    const orderResult = await client.payOrder(payOrderParam);
    const checkoutIdValue: string = orderResult?.data?.checkout_page_id;
    if (orderResult?.status) {
      const orderId: number | null = orderResult?.data?.id;
      if (params.restaurant_id !== undefined && orderId != null) {
        if (merchant?.table?.url_id !== null && merchant?.table?.url_id !== undefined) {
          navigate(
            `/${merchant.url_id as string}/payment/${checkoutIdValue}/${orderId}?table=${
              merchant.table.url_id as string
            }`
          );
        } else {
          navigate(`/${merchant.url_id as string}/payment/${checkoutIdValue}/${orderId}`);
        }

        try {
          if (selectCookie?.consent?.analytics) {
            ga4.event('payment_completed', merchant?.name, 'payment', true);
          }
        } catch (error) {}
      }
    }
  };

  const goBack = useCallback(() => {
    if (merchant.url_id !== undefined && merchant.url_id !== null) {
      if (merchant?.table?.url_id !== null && merchant?.table?.url_id !== undefined) {
        navigate(`/${merchant.url_id as string}/cart?table=${merchant.table.url_id as string}`);
      } else {
        navigate(`/${merchant.url_id as string}/cart`);
      }
    }
  }, [merchant]);

  const isMenu = useMemo((): boolean => {
    return (
      merchant?.categories?.[0]?.childs !== null && merchant?.categories?.[0]?.childs !== undefined
    );
  }, [merchant]);

  const merchantCategories = useMemo((): any[] => {
    if (isMenu) {
      const categories = [];
      for (const c of merchant.categories) {
        categories.push(...c.childs);
      }
      return categories;
    }
    return merchant.categories;
  }, [merchant]);

  const getProductsInCart = useMemo((): Array<Product & { quantity: number }> => {
    const res = [];
    const productPrice = new Map<number, number>();
    for (const order of cart) {
      productPrice.set(order.productId, order.quantity);
    }
    for (const category of merchantCategories) {
      for (const product of category.products) {
        res.push(product);
      }
    }
    return res
      .filter((product) => cart.map((order) => order.productId).includes(product.id))
      .map((product) => ({
        ...product,
        quantity: productPrice.get(product.id) ?? 0
      }));
  }, [merchantCategories, cart]);

  window.scroll(0, 0);
  return (
    <>
      {merchant.order_pay_enable === 1 ? (
        <>
          <SwipeableDrawer
            className="customDrawer"
            anchor="bottom"
            open={openCustomTipPopup}
            onClose={() => {
              setOpenCustomTipPopup(false);
            }}
            onOpen={() => {
              setOpenCustomTipPopup(true);
            }}
            disableBackdropTransition={!iOS}
            disableDiscovery={true}
            disableSwipeToOpen={true}>
            <PopupCustomTip
              closePopup={() => {
                setOpenCustomTipPopup(false);
              }}
            />
          </SwipeableDrawer>
          <div className="relt">
            <ElevationScroll>
              <AppBar color="inherit" className=" border-b border-gray-400">
                <Toolbar>
                  <Typography
                    align="center"
                    fontSize={14}
                    width="100%"
                    className="flex justify-between items-center"
                    fontWeight={700}
                    color="#1E293B">
                    <BackArrow onClick={goBack} />
                    <span
                      className="grow"
                      style={{ fontFamily: GetMenuFont(merchant), fontSize: 14 }}>
                      <Trans i18nKey={'overview-and-payment'}></Trans>
                    </span>
                  </Typography>
                </Toolbar>
              </AppBar>
            </ElevationScroll>
            <Toolbar />
            <Container disableGutters>
              <Box sx={{ my: 1 }}>
                <section className="text-center">
                  {merchant?.table !== null && merchant?.table !== undefined ? (
                    <p className="text-gray-400" style={{ fontSize: 14 }}>
                      <Trans i18nKey={'your-order'}></Trans>
                      {merchant?.table?.parents != null && merchant?.table?.parents?.length > 0
                        ? ' - ' + (merchant.table.parents[0].label as string)
                        : null}{' '}
                      {merchant?.table != null ? ' - ' + (merchant?.table?.label as string) : null}
                    </p>
                  ) : null}
                  <p className="font-bold" style={{ color: GetMenuColor(merchant), fontSize: 16 }}>
                    {subTotal().toFixed(2)} {currencySymbol}
                  </p>
                </section>

                <section className="border-b-8 border-gray-100 p-4">
                  <h3 className="font-bold mb-2">
                    <Trans i18nKey={'would-you-like-to-add-a-tip-question'}></Trans>
                  </h3>
                  <div className="grid grid-cols-4 gap-2">
                    {tips.map((tip, index) => (
                      <div
                        className="border border-1 p-2 rounded-xl text-center"
                        key={index}
                        onClick={() => {
                          setSelectedTip(index);
                        }}
                        style={{
                          backgroundColor:
                            selectedTip === index ? GetMenuColor(merchant) : undefined,
                          color: selectedTip === index ? '#fff' : undefined
                        }}>
                        <p className="font-bold">{tip.percentage}%</p>
                        <p className={`${selectedTip === index ? 'text-white' : 'text-gray-400'}`}>
                          {/* new Calc(subTotal())
                            .sum(salesTaxTotal())
                            .multiply(tip.percentage)
                            .divide(100)
                            .finish()
                      .toFixed(2) */}
                          {Big(subTotal())
                            .times(tip.percentage)
                            .div(100)
                            .round(2, Big.roundHalfUp)
                            .valueOf()}
                          {currencySymbol}
                        </p>
                      </div>
                    ))}
                    <div
                      onClick={() => {
                        setOpenCustomTipPopup(true);
                        setSelectedTip('custom');
                      }}
                      className="border border-1 p-2 rounded-xl text-gray-400 text-center flex items-center"
                      style={{
                        backgroundColor:
                          selectedTip === 'custom' ? GetMenuColor(merchant) : undefined,
                        color: selectedTip === 'custom' ? '#fff' : undefined,
                        fontSize: '3vw'
                      }}>
                      <Trans i18nKey={'custom-tip'}></Trans>
                    </div>
                  </div>
                </section>

                <section className="pt-5 border-b-8 border-gray-100 p-4">
                  <div className="flex justify-between font-bold">
                    <span>
                      <Trans i18nKey={'subtotal'}></Trans> (
                      <Trans i18nKey={'including-tax'}></Trans>)
                    </span>
                    <span>
                      {Big(subTotal()).round(2, Big.roundHalfUp).valueOf()} {currencySymbol}
                    </span>
                  </div>

                  <div className="flex justify-between text-gray-400">
                    <span>
                      <Trans i18nKey={'tips'}></Trans>
                      {selectedTip !== null &&
                      selectedTip !== undefined &&
                      selectedTip !== 'custom' ? (
                        <>({tipPercentage()}%)</>
                      ) : null}
                    </span>
                    <span>
                      {tipsTotal().valueOf()} {currencySymbol}
                    </span>
                  </div>

                  <div
                    className="flex justify-between font-bold"
                    style={{ color: GetMenuColor(merchant) }}>
                    <span>
                      <Trans i18nKey={'total'}></Trans>
                    </span>
                    <span>
                      {total().valueOf()} {currencySymbol}
                    </span>
                  </div>
                </section>
                <section className="pt-5">
                  {/* <h3 className="font-bold mb-2">
                    <Trans i18nKey={'payment-method'}></Trans>
                  </h3>
                   <PaymentMethodsSelect /> */}
                </section>
                <div className="h-48"></div>
              </Box>
            </Container>
            <section className="fixed bottom-2 w-full relt pt-2">
              <div className="mx-auto w-10/12">
                {enableOrder(merchantData, merchantIsOnline) &&
                  allItemsInCartPrintable(merchant, getProductsInCart) && (
                    <div
                      className="text-white rounded-lg h-10 flex items-center justify-evenly cursor-pointer"
                      style={{ backgroundColor: GetMenuColor(merchant) }}
                      onClick={() => {
                        onPay().then(
                          () => {},
                          () => {}
                        );
                      }}>
                      <Trans i18nKey={'select-payment-method'}></Trans> ({total().toFixed(2)}{' '}
                      {currencySymbol})
                    </div>
                  )}

                {!enableOrder(merchantData, merchantIsOnline) &&
                  !allItemsInCartPrintable(merchant, getProductsInCart) && (
                    <div className="col-span-3 w-10/12 m-auto text-center">
                      <span className="font-medium text-sm text-red-600 dark:text-red-500">
                        <Trans i18nKey={'merchant-cannot-accept-order'}></Trans>
                      </span>
                    </div>
                  )}
                <a
                  href={`${process.env.REACT_APP_WEBSITE_URL ?? ''}?section=terms`}
                  target="_blank"
                  rel="noreferrer">
                  <p className="text-gray-400 text-center text-xs p-1 underline">
                    <Trans i18nKey={'payment-agreement'}></Trans>
                  </p>
                </a>
              </div>
            </section>
          </div>
          <Toaster
            position="bottom-center"
            containerClassName=""
            toastOptions={{ className: 'rounded-full', duration: 2000 }}
          />
        </>
      ) : null}
    </>
  );
};

export default PaymentOverviewPage;
