import { Base, Response } from '../base'

const resourceName = 'merchant-pos'

export class Poss extends Base {
    syncPos(id: string, url_id: string) {
        return this.request<Response<String>>(`${resourceName}/${id}/sync/${url_id}`)
    }

}
