import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  selectRemoveAllergens,
  selectSelectedLabels,
  setSelectedLabels,
  setRemoveAllergens
} from '../store/appSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { styled, Switch, SwitchProps } from '@mui/material';
import { GetMenuColor, GetDarkModeClass } from '../utils/useFullFunctions';
import { selectLabels } from '../store/merchantSlice';
import MerchantContext from '../utils/contexts/MerchantContext';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => {
  const merchant = useContext(MerchantContext);
  return {
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          // backgroundColor: theme.palette.mode === 'relt' ? '#2ECA45' : '#65C466',
          backgroundColor: theme.palette.mode === 'dark' ? '#000000' : GetMenuColor(merchant),
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  };
});

interface PopupFilterProps {
  closePopup: () => void;
}

const PopupFilter: React.FC<PopupFilterProps> = ({ closePopup }) => {
  const selectedLabels = useAppSelector(selectSelectedLabels);
  const labels = useAppSelector(selectLabels);
  const removeAllergens = useAppSelector(selectRemoveAllergens);
  // const selectedLanguage = useAppSelector(selectSelectedLanguage)
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<any[]>(selectedLabels);
  // const [labelsArray, setLabelsArray] = useState<any[]>([])
  const [allergies, setAllergies] = useState<any[]>([]);
  const [preferences, setPreferences] = useState<any[]>([]);
  const [additives, setAdditives] = useState<any[]>([]);
  const [currentSelectedLabels] = useState<any>(selectedLabels);
  const merchant = useContext(MerchantContext);

  useEffect(() => {
    setAllergies(labels.filter((label: any) => label.type === 'allergen'));
    setPreferences(labels.filter((label: any) => label.type === 'preference'));
    setAdditives(labels.filter((label: any) => label.type === 'additive'));
  }, [labels]);

  // useEffect(() => { i18n.changeLanguage(selectedLanguage) }, [selectedLanguage])

  const isSelected = (label: any): boolean => selected.includes(label.id);

  const clearFilter = (): void => {
    setSelected([]);
  };

  const onRemoveAllergens = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setRemoveAllergens(event.target.checked));
  };

  const onSelect = (id: any, type: any): void => {
    // setActivateApply(true)
    if (selected.includes(id)) {
      setSelected(selected.filter((labelId) => labelId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const onSave = (): void => {
    // dispatch(setSelectedLabels([...selected]));
    closePopup();
  };

  const areEqual = (array1: number[], array2: number[]): boolean => {
    if (array1.length === array2.length) {
      return array1.every((element, index) => {
        if (element === array2[index]) {
          return true;
        }
        return false;
      });
    }
    return false;
  };

  const applyFilter = (): void => {
    onSave();
  };

  useEffect(() => {
    dispatch(setSelectedLabels([...selected]));
  }, [selected, dispatch]);

  // executed when popup is being closed
  useEffect(() => {
    return () => {
      if (!areEqual(selected, selectedLabels)) {
        dispatch(setSelectedLabels([...selected]));
        // console.log("testing", selected, selectedLabels)
      }
    };
  });

  return (
    <div className=" relt bottom-0 w-full h-auto m-auto p-4 bg-white text-gray-800 rounded-t-3xl">
      <div className=" relt flex">
        <span
          className=" relt rounded-3xl mx-auto"
          style={{ height: 4, width: 128, backgroundColor: '#CBD5E1' }}></span>
        <div className=" relt p-2 mr-1 absolute right-0 top-2" onClick={onSave}>
          <CloseIcon />
        </div>
      </div>

      <p style={{ fontWeight: 700, fontSize: 16 }} className=" relt text-center pt-5">
        <Trans i18nKey={'filtering-and-customization'}></Trans>
      </p>
      <div
        className=" relt overflow-y-scroll overflow-x-none"
        style={{ height: window.innerHeight * 0.7 }}>
        {preferences.length > 0 ? (
          <div className=" relt mt-10">
            <div className=" relt font-bold" style={{ color: '#64748B', fontSize: 16 }}>
              <Trans i18nKey={'food-regimes-preferences'}></Trans>
            </div>
            <div className=" relt flex flex-wrap my-3">
              {preferences.length > 0
                ? preferences.map((preference: any, index: number) => (
                    <div
                      key={index}
                      onClick={() => {
                        onSelect(preference.id, 'preferences');
                      }}
                      className=" relt rounded-3xl w-auto py-1 px-4 mr-3 mb-3 font-bold"
                      style={{
                        backgroundColor: isSelected(preference)
                          ? GetMenuColor(merchant)
                          : '#F1F5F9',
                        color: isSelected(preference) ? '#fff' : '#64748B',
                        fontSize: 14
                      }}>
                      <Trans i18nKey={preference?.name?.toLowerCase()}></Trans>
                    </div>
                  ))
                : null}
            </div>
          </div>
        ) : null}
        <div className={`${preferences.length > 0 ? 'mt-5' : 'mt-10'}`}>
          <div className=" relt font-bold " style={{ color: '#64748B', fontSize: 16 }}>
            <Trans i18nKey={'food-allergies-dislikes'}></Trans>
          </div>
          <p className=" relt font-bold" style={{ color: '#D01F46', fontSize: 12 }}>
            <Trans i18nKey={'allergies-warning'}></Trans>
          </p>
          <div className=" relt flex flex-wrap my-3">
            {allergies.length > 0
              ? allergies.map((allergy: any, index: number) => (
                  <div
                    key={index}
                    onClick={() => onSelect(allergy.id, 'allergies')}
                    className=" relt rounded-3xl w-auto py-1 px-4 mr-3 mb-3 font-bold"
                    style={{
                      backgroundColor: isSelected(allergy) ? '#D01F46' : '#F1F5F9',
                      color: isSelected(allergy) ? '#fff' : '#64748B',
                      fontSize: 14
                    }}>
                    <Trans i18nKey={allergy?.name?.toLowerCase()}></Trans>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className={`${preferences.length > 0 || allergies.length > 0 ? 'mt-5' : 'mt-10'}`}>
          <div className=" relt font-bold " style={{ color: '#64748B', fontSize: 16 }}>
            <Trans i18nKey={'additives'}></Trans>
          </div>
          <div className=" relt flex flex-wrap my-3">
            {additives.length > 0
              ? additives.map((additive: any, index: number) => (
                  <div
                    key={index}
                    onClick={() => onSelect(additive.id, 'additives')}
                    className=" relt rounded-3xl w-auto py-1 px-4 mr-3 mb-3 font-bold"
                    style={{
                      backgroundColor: isSelected(additive) ? '#D01F46' : '#F1F5F9',
                      color: isSelected(additive) ? '#fff' : '#64748B',
                      fontSize: 14
                    }}>
                    <Trans i18nKey={additive?.name?.toLowerCase()}></Trans>
                  </div>
                ))
              : null}
          </div>
        </div>
        {allergies.length > 0 ? (
          <div className=" relt flex justify-between items-center pb-2">
            <span className=" relt font-bold" style={{ color: '#64748B', fontSize: 14 }}>
              <Trans i18nKey={'remove-products-with-allergens-or-additives'}></Trans>
            </span>
            <span>
              <IOSSwitch onChange={onRemoveAllergens} checked={removeAllergens} />
            </span>
          </div>
        ) : null}
      </div>
      {labels.length > 0 ? (
        <>
          <div className=" relt pt-1" style={{ height: 45 }}>
            <button
              className=" relt rounded-xl w-full"
              style={{
                color: areEqual(selectedLabels, currentSelectedLabels)
                  ? 'rgb(100, 116, 139)'
                  : '#fff',
                backgroundColor: areEqual(selectedLabels, currentSelectedLabels)
                  ? 'rgb(243, 244, 246)'
                  : GetMenuColor(merchant),
                fontSize: 16,
                padding: '8px 16px'
              }}
              onClick={applyFilter}>
              <Trans i18nKey={'apply-filter'}></Trans>
            </button>
          </div>
          <div className="  pt-1" style={{ height: 45 }}>
            <button
              className={` ${
                GetDarkModeClass(merchant) !== '' ? 'border-2 border-gray-500' : 'bg-gray-100'
              } rounded-xl w-full `}
              style={{
                color: selected.length > 0 ? GetMenuColor(merchant) : 'rgb(100, 116, 139)',
                fontSize: 16,
                padding: '8px 16px'
              }}
              onClick={clearFilter}>
              <Trans i18nKey={'clear-all'}></Trans>
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default React.memo(PopupFilter);
