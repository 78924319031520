import React, { useContext } from 'react';
import 'react-quill/dist/quill.core.css';
import MerchantContext from '../utils/contexts/MerchantContext';
import { DarkModeValueClass, DarkModeValueTextColor } from '../utils/useFullFunctions';

interface QuillDisplayProps {
  html: string;
  style?: any | undefined;
}

const QuillDisplay: React.FC<QuillDisplayProps> = ({ html, style = undefined }) => {
  const merchant = useContext(MerchantContext);
  const color = DarkModeValueTextColor(merchant, '');
  return (
    <div
      className={`relt ql-editor force-default-font-family ${DarkModeValueClass(merchant, '')}`}
      style={style ?? { height: 'auto', padding: 0, color }}
      dangerouslySetInnerHTML={{ __html: html }}></div>
  );
};

export default QuillDisplay;
