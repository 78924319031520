import React, { useContext, useMemo } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../store/hooks';
import { selectRemoveAllergens, selectSelectedFav, selectSelectedLabels } from '../store/appSlice';
import { Trans } from 'react-i18next';
import MenuCategorySaved from '../components/MenuCategorySaved';
import { ReactComponent as HeartIcon } from '../icons/heart.svg';
import { ReactComponent as HeartSelectedIcon } from '../icons/heart-selected.svg';
import { GetMenuFont } from '../utils/useFullFunctions';
import { Helmet } from 'react-helmet-async';
import { colorEnums } from '../utils/enums/colourEnum';
import { selectLabels } from '../store/merchantSlice';
import MerchantContext from '../utils/contexts/MerchantContext';
import MenuPageFooter from '../components/MenuPageFooter';
import CartOverview from '../components/CartOverview';

const ElevationScroll = (props: any): any => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window !== null && window !== undefined ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
};

const SavedPage: React.FC<{}> = () => {
  const selectedFav = useAppSelector(selectSelectedFav);
  const removeAllergens = useAppSelector(selectRemoveAllergens);
  const labels = useAppSelector(selectLabels);
  const selectedLabels = useAppSelector(selectSelectedLabels);
  const merchant = useContext(MerchantContext);
  const font = GetMenuFont(merchant);
  const fontValue = font.replace(/ /gi, '+');
  // GetInitFontColours(restaurant);
  const isMenu = useMemo((): boolean => {
    return (
      merchant?.categories?.[0]?.childs !== null && merchant?.categories?.[0]?.childs !== undefined
    );
  }, [merchant]);
  // memoized cart to prevent unnecessary rerenders
  //
  const merchantCategories = useMemo((): any[] => {
    if (isMenu) {
      const categories = [];
      for (const c of merchant.categories) {
        categories.push(...c.childs);
      }
      return categories;
    }
    return merchant.categories;
  }, [merchant]);

  const containsFavs = (categories: any[]): boolean => {
    if (categories == null) return false;
    const allergyLabels = labels
      .filter((item) => selectedLabels.includes(item.id))
      .filter((item) => item.type === 'allergen')
      .map((item) => parseInt(item.id));

    for (const category of categories) {
      let data = category.products.filter((item: any) => selectedFav.includes(item.id));
      if (removeAllergens)
        data = data.filter(
          (item: any) =>
            item.labels.some((label: any) => allergyLabels.includes(label.id)) === false
        );
      if (data.length > 0) {
        return true;
      }
    }
    return false;
  };

  const showFavs = containsFavs(merchantCategories);

  const orderAndPayAndTable = useMemo(() => {
    return (
      merchant?.order_pay_enable === 1 && merchant.table !== null && merchant.table !== undefined
    );
  }, [merchant]);

  window.scrollTo({ top: 0, behavior: 'auto' });

  return (
    <>
      <Helmet>
        {colorEnums.CircularBook === font ? (
          <link rel="stylesheet" href={`https://fonts.cdnfonts.com/css/circular-book`} />
        ) : (
          <link
            rel="stylesheet"
            href={`https://fonts.googleapis.com/css?family=${fontValue as string}`}
          />
        )}
      </Helmet>

      <ElevationScroll>
        <AppBar color="inherit" className="  border-b border-gray-400">
          <Toolbar>
            <Typography align="center" fontSize={14} width="100%" fontWeight={700} color="#1E293B">
              <span
                className="  flex justify-center items-center"
                style={{ fontFamily: GetMenuFont(merchant) }}>
                <Trans i18nKey={'saved-products'}></Trans> <HeartSelectedIcon />
              </span>
            </Typography>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <Container disableGutters>
        <Box sx={{ my: 1 }}>
          {showFavs
            ? merchantCategories?.map((category: any, index: number) => (
                <MenuCategorySaved category={category} key={category.id} />
              ))
            : null}
          <div className="h-16"></div>
          {!showFavs ? (
            <div className="   flex max-h-screen w-3/4 pt-32 mx-auto">
              <p className="   text-gray-500 m-auto italic">
                <Trans i18nKey={'saved-description'}></Trans>
                <HeartIcon style={{ display: 'inline' }} />.
              </p>
            </div>
          ) : null}
        </Box>
      </Container>
      {orderAndPayAndTable ? <CartOverview /> : null}
      <MenuPageFooter />
    </>
  );
};

export default React.memo(SavedPage);
