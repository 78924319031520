import React, { useContext, useEffect, useState } from 'react';
import PopupFilter from './PopupFilter';
import PopupFeedback from './PopupFeedback';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import { selectSelecteCookie, selectSelectedLabels } from '../store/appSlice';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { t } from 'i18next';
import { SwipeableDrawer } from '@mui/material';
import { FaCircle } from 'react-icons/fa';
import {
  DarkModeValueTextColor,
  GetMenuColor,
  GetMenuDarkMode,
  GetMenuFont
} from '../utils/useFullFunctions';
import { selectLabels } from '../store/merchantSlice';
import toast from 'react-hot-toast';
import { ReactComponent as CustomizeIcon } from '../icons/customize.svg';
import { ReactComponent as CustomizeDarkIcon } from '../icons/customizeDark.svg';
import { ReactComponent as FeedbackIcon } from '../icons/feedback.svg';
import { ReactComponent as FeedbackDarkIcon } from '../icons/feedbackDark.svg';
import type { Label } from '@neatin/api-client/dist/labels/types';
import MerchantContext from '../utils/contexts/MerchantContext';

const CustomizedLogo: React.FC<{}> = () => {
  const merchantLabels = useAppSelector(selectLabels);
  const selectedLabels = useAppSelector(selectSelectedLabels);
  const merchant = useContext(MerchantContext);
  const labels = selectedLabels?.filter((id: number) =>
    merchantLabels?.map((label: Label) => label.id).includes(id)
  );

  return (
    <div className="relative" style={{ width: '20.83px', height: '20.83px' }}>
      {GetMenuDarkMode(merchant) ? (
        <CustomizeDarkIcon style={{ height: '20.83px' }} />
      ) : (
        <CustomizeIcon style={{ height: '20.83px' }} />
      )}
      {labels.length > 0 ? (
        <FaCircle color={GetMenuColor(merchant)} size={10} className="absolute top-0 right-0" />
      ) : null}
    </div>
  );
};

const CustomBottomNavigationAction: React.FC<any> = (props) => {
  const merchant = useContext(MerchantContext);
  const props2 = Object.assign({}, props);
  const select = props.select as boolean;
  const selectedIcon = props.selectedIcon;
  delete props2.select;
  delete props2.selectedIcon;
  return (
    <BottomNavigationAction
      {...props2}
      label={
        <p
          className=" w-max"
          style={{
            color: select ? GetMenuColor(merchant) : DarkModeValueTextColor(merchant, '#64748B'),
            paddingTop: 2,
            fontFamily: GetMenuFont(merchant)
          }}>
          {props.label}
        </p>
      }
      icon={select ? selectedIcon : props.icon}
    />
  );
};

const MenuPageFooter: React.FC<{}> = () => {
  const [openPopupFilter, setOpenPopupFilter] = useState<boolean>(false);
  const [openPopupFeedback, setOpenPopupFeedback] = useState<boolean>(false);
  const selectCookie = useAppSelector(selectSelecteCookie);
  const merchant = useContext(MerchantContext);
  // const labels = useAppSelector(selectLabels);
  // const selectedLabels = useAppSelector(selectSelectedLabels)
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  // const { t } = useTranslation()
  const menuValue = 'menu';
  const savedValue = 'saved';
  const customizeValue = 'customize';
  const feedbackValue = 'feedback';
  const [value, setValue] = useState<string>(
    location.pathname.includes('favorite') ? savedValue : menuValue
  );
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    setValue(location.pathname.includes('favorite') ? savedValue : menuValue);
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    // console.log(params)
    if ([menuValue, savedValue].includes(newValue)) setValue(newValue);
    if (
      newValue === menuValue &&
      params?.restaurant_id !== null &&
      params?.restaurant_id !== undefined
    ) {
      if (params.restaurant_id !== undefined) {
        navigate(`/${params.restaurant_id}`);
      }
    } else if (
      newValue === savedValue &&
      params?.restaurant_id !== null &&
      params?.restaurant_id !== undefined
    ) {
      if (params.restaurant_id !== undefined) {
        if (selectCookie?.consent?.targetedAdvertising) {
          navigate(`/${params.restaurant_id}/favorite`);
        } else {
          const message: any = t('activate-cookies-preferences');
          toast.error(message);
        }
      }
    } else if (newValue === customizeValue) {
      setOpenPopupFilter(!openPopupFilter);
    } else if (newValue === feedbackValue) {
      setOpenPopupFeedback(!openPopupFeedback);
    }
  };

  const closeFeedback = (): void => {
    setOpenPopupFeedback(false);
  };

  const closeFilter = (): void => {
    setOpenPopupFilter(false);
  };

  const color = GetMenuColor(merchant);

  const MenuSelectedIcon: React.FC<any> = () => {
    return (
      <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.5154 20.1389V16.9538C7.5154 16.1407 8.17935 15.4816 8.99837 15.4816H11.9923C12.3856 15.4816 12.7628 15.6367 13.0409 15.9128C13.319 16.1889 13.4753 16.5633 13.4753 16.9538V20.1389C13.4728 20.4769 13.6063 20.802 13.8462 21.0419C14.0861 21.2818 14.4126 21.4167 14.7531 21.4167H16.7957C17.7496 21.4191 18.6654 21.0446 19.3408 20.3759C20.0162 19.7071 20.3958 18.7989 20.3958 17.8519V8.77799C20.3958 8.01299 20.0542 7.28735 19.4631 6.79654L12.5146 1.28737C11.3059 0.321429 9.57408 0.352617 8.40145 1.36145L1.61148 6.79654C0.992443 7.27288 0.622455 8.00067 0.604172 8.77799V17.8427C0.604172 19.8165 2.21603 21.4167 4.20435 21.4167H6.20031C6.90753 21.4167 7.48229 20.8502 7.48742 20.1482L7.5154 20.1389Z"
          fill={color}
        />
      </svg>
    );
  };

  const MenuIcon: React.FC<any> = () => {
    return (
      <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.5154 20.1389V16.9538C7.5154 16.1407 8.17935 15.4816 8.99837 15.4816H11.9923C12.3856 15.4816 12.7628 15.6367 13.0409 15.9128C13.319 16.1889 13.4753 16.5633 13.4753 16.9538V20.1389C13.4728 20.4769 13.6063 20.802 13.8462 21.0419C14.0861 21.2818 14.4126 21.4167 14.7531 21.4167H16.7957C17.7496 21.4191 18.6654 21.0446 19.3408 20.3759C20.0162 19.7071 20.3958 18.7989 20.3958 17.8519V8.77799C20.3958 8.01299 20.0542 7.28735 19.4631 6.79654L12.5146 1.28737C11.3059 0.321429 9.57408 0.352617 8.40145 1.36145L1.61148 6.79654C0.992443 7.27288 0.622455 8.00067 0.604172 8.77799V17.8427C0.604172 19.8165 2.21603 21.4167 4.20435 21.4167H6.20031C6.90753 21.4167 7.48229 20.8502 7.48742 20.1482L7.5154 20.1389Z"
          stroke={DarkModeValueTextColor(merchant, '#64748B')}
        />
      </svg>
    );
  };

  const SavedIcon: React.FC<any> = () => {
    return (
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.12481 0.104745C7.78106 0.124703 8.41648 0.239286 9.0321 0.448661H9.09356C9.13523 0.468453 9.16648 0.490328 9.18731 0.510119C9.41752 0.584078 9.63523 0.667411 9.84356 0.781994L10.2394 0.959078C10.3956 1.04241 10.5831 1.19762 10.6873 1.26116C10.7915 1.32262 10.9061 1.38616 10.9998 1.45804C12.1571 0.573661 13.5623 0.0944945 15.0102 0.104745C15.6675 0.104745 16.3238 0.19762 16.9477 0.406994C20.7925 1.65699 22.1779 5.87574 21.0206 9.56324C20.3644 11.4476 19.2915 13.1674 17.8863 14.5726C15.8748 16.5205 13.6675 18.2497 11.2915 19.7393L11.0311 19.8966L10.7602 19.7289C8.37586 18.2497 6.15606 16.5205 4.12586 14.5622C2.73002 13.157 1.65606 11.4476 0.989397 9.56324C-0.187686 5.87574 1.19773 1.65699 5.08419 0.385119C5.38627 0.280953 5.69773 0.208036 6.01023 0.167411H6.13523C6.42794 0.124703 6.71856 0.104745 7.01023 0.104745H7.12481ZM16.4061 3.39658C15.979 3.2497 15.5102 3.47991 15.354 3.91741C15.2081 4.35491 15.4373 4.83408 15.8748 4.98929C16.5425 5.23929 16.9894 5.89658 16.9894 6.6247V6.65699C16.9696 6.89554 17.0415 7.12574 17.1873 7.30283C17.3331 7.47991 17.5519 7.58304 17.7811 7.60491C18.2081 7.59345 18.5727 7.25074 18.604 6.8122V6.68824C18.6352 5.22887 17.7509 3.90699 16.4061 3.39658Z"
          stroke={DarkModeValueTextColor(merchant, '#64748B')}
        />
      </svg>
    );
  };

  const FeedbackIconElement = (props: any): any => {
    return GetMenuDarkMode(merchant) ? (
      <FeedbackDarkIcon {...props} />
    ) : (
      <FeedbackIcon {...props} />
    );
  };

  const SavedSelectedIcon: React.FC<any> = () => {
    return (
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.12481 0.104745C7.78106 0.124703 8.41648 0.239286 9.0321 0.448661H9.09356C9.13523 0.468453 9.16648 0.490328 9.18731 0.510119C9.41752 0.584078 9.63523 0.667411 9.84356 0.781994L10.2394 0.959078C10.3956 1.04241 10.5831 1.19762 10.6873 1.26116C10.7915 1.32262 10.9061 1.38616 10.9998 1.45804C12.1571 0.573661 13.5623 0.0944945 15.0102 0.104745C15.6675 0.104745 16.3238 0.19762 16.9477 0.406994C20.7925 1.65699 22.1779 5.87574 21.0206 9.56324C20.3644 11.4476 19.2915 13.1674 17.8863 14.5726C15.8748 16.5205 13.6675 18.2497 11.2915 19.7393L11.0311 19.8966L10.7602 19.7289C8.37586 18.2497 6.15606 16.5205 4.12586 14.5622C2.73002 13.157 1.65606 11.4476 0.989397 9.56324C-0.187686 5.87574 1.19773 1.65699 5.08419 0.385119C5.38627 0.280953 5.69773 0.208036 6.01023 0.167411H6.13523C6.42794 0.124703 6.71856 0.104745 7.01023 0.104745H7.12481ZM16.4061 3.39658C15.979 3.2497 15.5102 3.47991 15.354 3.91741C15.2081 4.35491 15.4373 4.83408 15.8748 4.98929C16.5425 5.23929 16.9894 5.89658 16.9894 6.6247V6.65699C16.9696 6.89554 17.0415 7.12574 17.1873 7.30283C17.3331 7.47991 17.5519 7.58304 17.7811 7.60491C18.2081 7.59345 18.5727 7.25074 18.604 6.8122V6.68824C18.6352 5.22887 17.7509 3.90699 16.4061 3.39658Z"
          fill={color}
        />
      </svg>
    );
  };

  const CustomizeSelectedIcon: React.FC<any> = () => {
    return (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.3345 12.1037C19.3136 12.1037 20.9166 13.6797 20.9166 15.6242C20.9166 17.5675 19.3136 19.1435 17.3345 19.1435C15.3565 19.1435 13.7523 17.5675 13.7523 15.6242C13.7523 13.6797 15.3565 12.1037 17.3345 12.1037ZM8.50341 14.1229C9.3703 14.1229 10.074 14.8142 10.074 15.6659C10.074 16.5165 9.3703 17.209 8.50341 17.209H1.65391C0.787013 17.209 0.083313 16.5165 0.083313 15.6659C0.083313 14.8142 0.787013 14.1229 1.65391 14.1229H8.50341ZM3.66547 0.625C5.64463 0.625 7.24763 2.20102 7.24763 4.14433C7.24763 6.08878 5.64463 7.66481 3.66547 7.66481C1.68747 7.66481 0.083313 6.08878 0.083313 4.14433C0.083313 2.20102 1.68747 0.625 3.66547 0.625ZM19.3472 2.60242C20.2129 2.60242 20.9166 3.29378 20.9166 4.14433C20.9166 4.99602 20.2129 5.68738 19.3472 5.68738H12.4977C11.6308 5.68738 10.9271 4.99602 10.9271 4.14433C10.9271 3.29378 11.6308 2.60242 12.4977 2.60242H19.3472Z"
          fill="#0D9488"
        />
      </svg>
    );
  };

  const FeedbackSelectedIcon: React.FC<{}> = () => {
    return (
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.1452 0.625C17.5421 0.625 18.8858 1.17708 19.8744 2.16771C20.8639 3.15625 21.4171 4.48958 21.4171 5.88542V14.1146C21.4171 17.0208 19.0525 19.375 16.1452 19.375H5.85457C2.94728 19.375 0.58374 17.0208 0.58374 14.1146V5.88542C0.58374 2.97917 2.93687 0.625 5.85457 0.625H16.1452ZM17.3233 6.04167C17.1046 6.03021 16.8962 6.10417 16.7389 6.25L12.0421 10C11.4379 10.501 10.5723 10.501 9.95874 10L5.27124 6.25C4.94728 6.01042 4.49937 6.04167 4.22957 6.32292C3.94832 6.60417 3.91707 7.05208 4.15562 7.36458L4.29207 7.5L9.03166 11.1979C9.61499 11.6562 10.3223 11.9062 11.0629 11.9062C11.8014 11.9062 12.5212 11.6562 13.1035 11.1979L17.8025 7.4375L17.8858 7.35417C18.1348 7.05208 18.1348 6.61458 17.8744 6.3125C17.7296 6.15729 17.5306 6.0625 17.3233 6.04167Z"
          fill={color}
        />
      </svg>
    );
  };

  const tMenu: any = t('menu');
  const tSaved: any = t('saved');
  const tFilter: any = t('filter');
  const tFeedBack: any = t('feedback');

  return (
    <>
      <div className="relt2">
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            borderTop: '2px solid #CBD5E1',
            zIndex: 40
          }}
          elevation={0}>
          <BottomNavigation showLabels onChange={handleChange} className="relt2">
            <CustomBottomNavigationAction
              value={menuValue}
              label={tMenu}
              icon={
                <MenuIcon
                  style={{ height: '20.83px', color: DarkModeValueTextColor(merchant, '#64748B') }}
                />
              }
              selectedIcon={<MenuSelectedIcon />}
              select={value === menuValue}
            />
            <CustomBottomNavigationAction
              value={savedValue}
              label={tSaved}
              icon={<SavedIcon style={{ height: '20.83px' }} />}
              selectedIcon={<SavedSelectedIcon />}
              select={value === savedValue}
            />
            <CustomBottomNavigationAction
              value={customizeValue}
              label={tFilter}
              icon={<CustomizedLogo />}
              selectedIcon={<CustomizeSelectedIcon />}
              select={value === customizeValue}
            />
            <CustomBottomNavigationAction
              value={feedbackValue}
              label={tFeedBack}
              icon={<FeedbackIconElement style={{ height: '20.83px' }} />}
              selectedIcon={<FeedbackSelectedIcon />}
              select={value === feedbackValue}
            />
          </BottomNavigation>
        </Paper>
      </div>

      <SwipeableDrawer
        className="customDrawer"
        anchor="bottom"
        open={openPopupFilter}
        onClose={() => closeFilter()}
        onOpen={() => {
          setOpenPopupFilter(true);
        }}
        disableBackdropTransition={!iOS}
        disableDiscovery={true}
        disableSwipeToOpen={true}>
        <PopupFilter closePopup={closeFilter} />
      </SwipeableDrawer>

      <SwipeableDrawer
        className="customDrawer"
        anchor="bottom"
        open={openPopupFeedback}
        onClose={() => {
          closeFeedback();
        }}
        onOpen={() => {
          setOpenPopupFeedback(true);
        }}
        disableBackdropTransition={!iOS}
        disableDiscovery={true}
        disableSwipeToOpen={true}>
        <PopupFeedback closePopup={closeFeedback} />
      </SwipeableDrawer>
    </>
  );
};

export default React.memo(MenuPageFooter);
