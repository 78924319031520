import React, { useContext, useEffect, useMemo, useState } from 'react';
// import { Helmet } from 'react-helmet-async';
import { Trans } from 'react-i18next';
import MenuCategory from '../components/MenuCategory';
import GA4React from 'ga-4-react';
// import { GetMenuFont } from '../utils/useFullFunctions';
// import { colorEnums } from '../utils/enums/colourEnum';
import { SpinnerCircular } from 'spinners-react';
import { useAppSelector } from '../store/hooks';
import { selectLoadingTranslations } from '../store/merchantSlice';
import MerchantContext from '../utils/contexts/MerchantContext';
import MenuPageHeader from '../components/MenuPageHeader';
import { Toaster } from 'react-hot-toast';
import MenuPageFooter from '../components/MenuPageFooter';
// import CartOverview from '../components/CartOverview';
import { GA4ReactResolveInterface } from 'ga-4-react/dist/models/gtagModels';
import { selectSelecteCookie } from '../store/appSlice';
import MenusNavigator from '../components/MenusNavigator';
import { Category } from '@neatin/api-client/dist/categories/types';
import CartOverview from '../components/CartOverview';
import { DarkModeValueTextColor } from '../utils/useFullFunctions';

//
// const MenuPageHeader = React.lazy(async () => await import('../components/MenuPageHeader'));
//

const CategoriesSection: React.FC<{}> = () => {
  const restaurant = useContext(MerchantContext);
  const loadingTranslations = useAppSelector(selectLoadingTranslations);
  const [selectedMenu, setSelectedMenu] = useState<any>(null);

  const isMenu = (restaurant: any): boolean => {
    return (
      restaurant?.categories?.[0]?.childs !== null &&
      restaurant?.categories?.[0]?.childs !== undefined
    );
  };

  const categoriesToDisplay = useMemo((): Category[] => {
    if (isMenu(restaurant)) return selectedMenu?.childs;
    return restaurant?.categories;
  }, [restaurant, selectedMenu]);

  useEffect(() => {
    const menu = restaurant?.categories.find((m: any) => m?.childs?.length > 0);
    setSelectedMenu(menu);
  }, [restaurant]);

  return (
    <>
      {loadingTranslations ? (
        <div
          className={`flex justify-center mt-24 ${loadingTranslations ? '' : 'hidden'}`}
          color="">
          <SpinnerCircular
            size={50}
            thickness={35}
            speed={100}
            color="rgba(204, 204, 204, 1)"
            secondaryColor="rgba(0, 0, 0, 0.44)"
          />
        </div>
      ) : (
        <>
          {isMenu(restaurant) && selectedMenu !== null && selectedMenu !== undefined ? (
            <>
              <MenusNavigator selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
              {selectedMenu?.description != null &&
                selectedMenu?.description !== undefined &&
                selectedMenu?.description !== '' && (
                  <div
                    className="relt text-base px-3 pt-1"
                    style={{ fontSize: 14, color: DarkModeValueTextColor(restaurant, '#64748B') }}
                    dangerouslySetInnerHTML={{ __html: selectedMenu.description }}></div>
                )}
            </>
          ) : null}
          <div className={`relt pt-5`}>
            <div id="cat-menu-div" className="relt">
              {categoriesToDisplay?.map((category: any, index: number) =>
                category.products != null && category.products.length > 0 ? (
                  <MenuCategory category={category} key={category.id} />
                ) : null
              )}
              <div className="h-36"></div>
              {(categoriesToDisplay == null || categoriesToDisplay?.length === 0) && (
                <div className="relt  pt-32 text-center text-gray-500">
                  <Trans i18nKey={'menu-not-found'}></Trans>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {restaurant?.pos_enable === 1 &&
      restaurant.table !== null &&
      restaurant.table !== undefined ? (
        <CartOverview />
      ) : null}
    </>
  );
};

const MenuPage: React.FC<{}> = () => {
  const restaurant = useContext(MerchantContext);
  const [view, setView] = useState(false);
  const selectCookie = useAppSelector(selectSelecteCookie);

  useEffect(() => {
    if (!view && restaurant?.name !== null && restaurant?.name !== undefined) {
      const path = `${window.location.pathname} ${window.location.search}/${
        restaurant?.name as string
      }`;
      const title = restaurant?.name;
      const location = window.location.pathname;

      try {
        if (selectCookie?.consent?.analytics) {
          switch (GA4React.isInitialized()) {
            case true: {
              const ga4: any = GA4React.getGA4React();
              ga4.pageview(path, location, title);
              break;
            }
            case false: {
              const gaCode = process.env.REACT_APP_GA_CODE ?? '';
              const ga4React = new GA4React(gaCode);
              ga4React.initialize().then(
                (ga4: GA4ReactResolveInterface) => {
                  ga4.pageview(path, location, title);
                },
                (err: Error) => {
                  console.error(err);
                }
              );
              break;
            }
          }

          setView(true);
        }
      } catch (error) {}
    }
  }, [restaurant?.name]);

  window.scrollTo({ top: 0, behavior: 'auto' });

  return (
    <>
      <section
        className="relt md:w-10/12 mx-auto break-words w-full"
        id="menu-page"
        style={{ maxWidth: 1000 }}>
        {restaurant !== null && restaurant !== undefined ? (
          <>
            <React.Suspense fallback={null}>
              <MenuPageHeader />
              <CategoriesSection />
            </React.Suspense>
          </>
        ) : (
          <p className="relt text-center pt-32 text-gray-500">
            <Trans i18nKey={'invalid-restaurant'}></Trans>
          </p>
        )}
      </section>
      <MenuPageFooter />

      <Toaster
        position="bottom-center"
        containerClassName=""
        toastOptions={{ className: 'rounded-full', duration: 2000 }}
      />
    </>
  );
};

export default React.memo(MenuPage);
