/* eslint-disable */
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { GetMenuColor, GetMenuFont, GetMenuDarkMode, enableOrder } from '../utils/useFullFunctions';
import MerchantContext from '../utils/contexts/MerchantContext';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { SpinnerCircular } from 'spinners-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useNeatinAPIClient from '../utils/hooks/useNeatinAPIClient';
import { useAppSelector } from '../store/hooks';
import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import { ReactComponent as BackArrow } from '../icons/back-arrow.svg';
import ElevationScroll from '../utils/helpers/ElevationScroll';
import { useAppDispatch } from '../store/hooks';
import { resetOrder } from '../store/orderSlice';
import { selectSelectedMerchantIsOnline } from '../store/appSlice';
import { selectMerchantData } from '../store/merchantSlice';

const PaymentPage: React.FC<{}> = () => {
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const merchant = useContext(MerchantContext);
  const { t } = useTranslation();
  const checkoutDivRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const client = useNeatinAPIClient();
  // const [searchParams] = useSearchParams();
  const params = useParams();
  const checkoutId = params.checkoutId;
  const orderId = params.orderId;
  const dispatch = useAppDispatch();
  const merchantIsOnline = useAppSelector(selectSelectedMerchantIsOnline);
  const merchantData = useAppSelector(selectMerchantData);

  const textColor = GetMenuDarkMode(merchant) ? '#fff' : '#000';
  // @ts-expect-error
  const checkout = new RapydCheckoutToolkit({
    pay_button_text: t('pay'),
    pay_button_color: GetMenuColor(merchant),
    id: checkoutId,
    style: {
      input: {
        base: {
          color: textColor,
          backgroundColor: !GetMenuDarkMode(merchant) ? '#fff' : '#000'
        }
      },
      cardFields: {
        title: {
          color: textColor
        }
      },
      orderDetails: {
        title: {
          color: textColor
        },
        totalLabel: {
          color: textColor
        },
        totalValue: {
          color: textColor
        }
      },
      pciMessage: {
        color: textColor
      }
    }
  });

  useEffect(() => {
    checkout.displayCheckout();
    const onLoadingEvent = (event: any): void => {
      if (event.detail.loading === false) {
        setShowLoader(false);
      }
    };

    const onCheckoutOnFailed = (event: any): void => {
      const loader = document.getElementById('error');
      if (loader === null || loader === undefined) return;
      toast(t('try-later') as any);
      navigate(-1);
    };

    const onCheckoutPaymentSuccess = (event: any): void => {
      if (orderId != null && orderId != null) {
        client
          .verifyOrderPayment(Number(orderId))
          .then(
            () => {},
            () => {}
          )
          .then(() => {
            dispatch(resetOrder());
            if (merchant?.table?.url_id !== null && merchant?.table?.url_id !== undefined) {
              navigate(
                `/${merchant.url_id}/payment-successful/${orderId}?table=${
                  merchant.table.url_id as string
                } `
              );
            } else {
              navigate(`/${merchant.url_id}/payment-successful/${orderId}`);
            }
          });
      }
    };

    const onCheckoutPaymentFailure = (event: any): void => {
      console.log(event.detail.error);
      toast(t('payment-error') as any);
      navigate(-1);
    };
    window.addEventListener('onLoading', onLoadingEvent);
    window.addEventListener('onCheckoutPaymentSuccess', onCheckoutPaymentSuccess);
    window.addEventListener('checkoutOnFailed', onCheckoutOnFailed);
    window.addEventListener('onCheckoutPaymentFailure', onCheckoutPaymentFailure);

    return () => {
      try {
        checkout.closeCheckout();
      } catch (err) {
        console.error(err);
      }
      window.removeEventListener('onLoading', onLoadingEvent);
      window.removeEventListener('onCheckoutPaymentSuccess', onCheckoutPaymentSuccess);
      window.removeEventListener('checkoutOnFailed', onCheckoutOnFailed);
      window.removeEventListener('onCheckoutPaymentFailure', onCheckoutPaymentFailure);
    };
  }, []);

  const goBack = useCallback(() => {
    if (merchant.url_id !== undefined && merchant.url_id !== null) {
      // navigate(`/${merchant.url_id}/payment-overview`);
      if (merchant?.table?.url_id !== null && merchant?.table?.url_id !== undefined) {
        navigate(`/${merchant.url_id}/payment-overview?table=${merchant.table.url_id as string}`);
      } else {
        navigate(`/${merchant.url_id}/payment-overview`);
      }
    }
  }, [merchant.url_id]);

  return (
    <>
      <div className="relt">
        <ElevationScroll>
          <AppBar color="inherit" className=" border-b border-gray-400">
            <Toolbar>
              <Typography
                align="center"
                fontSize={14}
                width="100%"
                className="flex justify-between items-center"
                fontWeight={700}
                color="#1E293B">
                <BackArrow onClick={goBack} />
                <span className="grow" style={{ fontFamily: GetMenuFont(merchant), fontSize: 14 }}>
                  <Trans i18nKey={'payment'}></Trans>
                </span>
              </Typography>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Toolbar />
        <Container>
          <Box sx={{ my: 1 }}>
            <div
              className={`flex h-screen items-center justify-center ${showLoader ? '' : 'hidden'}`}
              color="">
              <div>
                <SpinnerCircular
                  size={'5em'}
                  thickness={60}
                  speed={100}
                  color="rgba(204, 204, 204, 1)"
                  secondaryColor="rgba(0, 0, 0, 0.44)"
                  className="mx-auto"
                />
                <h1 className="text-center mt-2">
                  <Trans i18nKey={'loading-payment-page'}></Trans>
                </h1>
              </div>
            </div>
            <div
              className={`${
                showLoader || (!enableOrder(merchantData, merchantIsOnline) && 'hidden')
              }`}
              id="rapyd-checkout"
              ref={checkoutDivRef}></div>
            {!enableOrder(merchantData, merchantIsOnline) && (
              <div className="col-span-3 w-10/12 m-auto text-center">
                <span className="font-medium text-sm text-red-600 dark:text-red-500">
                  <Trans i18nKey={'merchant-cannot-accept-order'}></Trans>
                </span>
              </div>
            )}
          </Box>
        </Container>
      </div>
    </>
  );
};

export default PaymentPage;
