import Big from 'big.js';
import { getParamByISO } from 'iso-country-currency';
import React, { useContext, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import MerchantContext from '../utils/contexts/MerchantContext';
import { GetMenuColor } from '../utils/useFullFunctions';

interface VariantsProps {
  item: any;
  setVariantCb?: any; // function
}

const Variants: React.FC<VariantsProps> = ({ item, setVariantCb }) => {
  const [selected, setSelected] = useState<number | null>(null);
  const merchant = useContext(MerchantContext);

  const currencySymbol =
    merchant?.country !== null && merchant?.country !== undefined
      ? getParamByISO(merchant.country ?? 'GE', 'symbol')
      : '';

  const selectVariant = (item: any): void => {
    setSelected(item.id);
    if (setVariantCb !== null && setVariantCb !== undefined) {
      setVariantCb(item.id);
    }
  };

  const orderAndPayAndTable = useMemo(() => {
    return (
      merchant?.order_pay_enable === 1 && merchant.table !== null && merchant.table !== undefined
    );
  }, [merchant]);

  /* useEffect(() => {
    if (setVariantCb) {
      setVariantCb(selected);
    }
  }, [selected]); */

  const price = (variantPrice: number, itemPrice: number): number => {
    return Big(variantPrice).minus(itemPrice).round(2, Big.roundHalfUp).toNumber();
  };

  return (
    <>
      {item.childs?.length > 0 ? (
        <div className="my-10">
          <div className="border-b border-black flex justify-between pb-1">
            <h1 className="text-lg font-bold">
              <Trans i18nKey={'variants'}></Trans>
            </h1>
            <span
              className="border rounded-full p-1 text-sm"
              style={{ borderColor: GetMenuColor(merchant) }}>
              <Trans i18nKey={'required'}></Trans>
            </span>
          </div>
          <ul>
            {item.childs.map((variant: any) => (
              <li
                className={`grid ${
                  orderAndPayAndTable ? 'grid-cols-6' : 'grid-cols-5'
                } justify-between my-2`}
                key={variant.id}
                onClick={() => selectVariant(variant)}>
                <span className="col-span-4">{variant.name}</span>
                <span className="text-gray-400 text-xs pr-2 text-right">
                  {price(variant.price, item.price) < 0
                    ? price(variant.price, item.price)
                    : `+${price(variant.price, item.price)}`}{' '}
                  {currencySymbol}
                </span>
                {orderAndPayAndTable ? (
                  <span className="flex justify-end">
                    {selected === variant.id ? (
                      <FaCheckCircle color={GetMenuColor(merchant)} />
                    ) : (
                      <FaRegCircle color={GetMenuColor(merchant)} />
                    )}
                  </span>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default Variants;
