/* eslint-disable */ 
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

interface MerchantState {
  merchantData: any;
  loadingTranslations: boolean;
  labelsData: any;
  labels: any[];
}

const initialState: MerchantState = {
  merchantData: {},
  loadingTranslations: false,
  labelsData: {},
  labels: []
};

const MerchantSlice = createSlice({
  name: 'Merchant',
  initialState,
  reducers: {
    setMerchantData: (state: MerchantState, action: PayloadAction<any>) => {
      state.merchantData = action.payload;
    },
    setLabelsData: (state: MerchantState, action: PayloadAction<any>) => {
      state.labelsData = action.payload;
    },
    setLabels: (state: MerchantState, action: PayloadAction<any>) => {
      state.labels = action.payload;
    },
    setLoadingTranslations: (state: MerchantState, action: PayloadAction<any>) => {
      state.loadingTranslations = action.payload;
    }
  }
});

export const { setMerchantData, setLabelsData, setLabels, setLoadingTranslations } =
  MerchantSlice.actions;
export const selectMerchantData = (state: RootState) => state.merchant.merchantData;
export const selectLabelsData = (state: RootState) => state.merchant.labelsData;
export const selectLabels = (state: RootState) => state.merchant.labels;
export const selectLoadingTranslations = (state: RootState) => state.merchant.loadingTranslations;

export default MerchantSlice.reducer;
