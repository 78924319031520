import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import store from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import i18n from './i18n';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// ** styles
import './index.css';
import 'reactjs-popup/dist/index.css';
import 'react-modern-drawer/dist/index.css';

import GA4React from 'ga-4-react';
import Cookies from './components/Cookies';

const gaCode = process.env.REACT_APP_GA_CODE ?? '';
const ga4react = new GA4React(gaCode);

const AppWithTranslation = withTranslation()((props) => {
  const reltTheme = createTheme({
    typography: {
      fontFamily: [
        'Nunito',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(',')
    }
  });
  return (
    <ThemeProvider theme={reltTheme}>
      <Cookies />
      <App />
    </ThemeProvider>
  );
});

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
try {
  ga4react.initialize().then(
    () => {},
    () => {}
  );
} catch (error) {}

root.render(
  <>
    {/* <React.StrictMode> */}
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HelmetProvider>
          <React.Suspense fallback={'...'}>
            <AppWithTranslation use={i18n} ga4react={ga4react} />
          </React.Suspense>
        </HelmetProvider>
      </PersistGate>
    </Provider>
    {/* </React.StrictMode> */}
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
