import qs from 'querystringify'
import { ProductVariant, SearchProductVariantsParams, NewProductVariant, UpdateProductVariant } from './types'
import { Base, Pagination } from '../base'

const resourceName = 'product_variants'

export class ProductVariants extends Base {
    getProductVariants (params?: SearchProductVariantsParams) {
        let query = `${resourceName}`
        if (params) {
            query += qs.stringify(params, '?')
        }
        return this.request<ProductVariant[]>(query)
    }
    
    getProductVariant (id: string) {
        return this.request<ProductVariant>(`${resourceName}/${id}`)
    }

    createProductVariant (params: ProductVariant) {
        return this.request<ProductVariant>(resourceName, {
            method: 'POST',
            body: JSON.stringify({ ...params })
        })
    }

    updateProductVariant (id: string, params?: ProductVariant) {
        return this.request<ProductVariant>(`${resourceName}/${id}`, {
            method: 'PUT',
            body: JSON.stringify({ ...params })
        })
    }

    deleteProductVariant (id: string) {
        return this.request<ProductVariant>(`${resourceName}/${id}`, { method: 'DELETE' })
    }
 
}
