import React, { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectCustomTip, setCustomTip } from '../store/orderSlice';
import MerchantContext from '../utils/contexts/MerchantContext';
import { SelectModeValue, GetMenuColor } from '../utils/useFullFunctions';
import GA4React from 'ga-4-react';
import { selectSelecteCookie } from '../store/appSlice';

interface PopupOrderNoteProps {
  closePopup: () => void;
}

const PopupCustomTip: React.FC<PopupOrderNoteProps> = ({ closePopup }: any) => {
  const customTip = useAppSelector(selectCustomTip);
  const [tip, setTip] = useState<number>(() => customTip);
  const ga4: any = GA4React.getGA4React();
  const dispatch = useAppDispatch();
  const merchant = useContext(MerchantContext);
  const selectCookie = useAppSelector(selectSelecteCookie);

  const handleChange = (e: any): void => {
    setTip(e.target.value);
  };

  const handleSubmit = (e: any): void => {
    e.preventDefault();
    try {
      if (selectCookie?.consent?.analytics) {
        ga4.event('select_custom_tip', merchant?.name, 'payment', true);
      }
    } catch (error) {}
    dispatch(setCustomTip(tip));
    closePopup();
  };

  return (
    <div className="relt bg-white text-gray-800 rounded-t-3xl pt-4">
      <div className=" relt flex">
        <span
          className=" relt rounded-3xl mx-auto"
          style={{ height: 4, width: 128, backgroundColor: '#CBD5E1' }}></span>
        <div className=" relt p-2 mr-1 absolute right-0 top-2" onClick={closePopup}>
          <CloseIcon />
        </div>
      </div>

      <div className=" relt p-4">
        <h4 className="font-bold text-gray-400">
          <Trans i18nKey="custom-tip"></Trans>
        </h4>
        <div className=" relt w-full mb-4">
          <div className=" relt mx-auto bg-white w-2/6 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className=" relt flex flex-wrap w-full text-left mx-auto">
            <div className=" relt relative w-full">
              <input
                type={'number'}
                min={0}
                value={tip}
                onChange={handleChange}
                className={`${SelectModeValue(
                  merchant,
                  'customBarkBackground placeholder-white ',
                  'bg-gray-100 placeholder-gray-700 '
                )}  rounded-xl p-3 text-sm shadow focus:outline-none w-full resize-none`}
              />
            </div>
            <button
              className={` mx-auto lg:mx-0 text-white w-full text-gray-800 rounded-xl my-6 py-4 px-8`}
              style={{ backgroundColor: GetMenuColor(merchant), color: 'white' }}>
              <Trans i18nKey="save">Save</Trans>{' '}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupCustomTip;

// export default withTranslation()(PopupFilter);
