import React from 'react';
import { useScrollTrigger } from '@mui/material';

const ElevationScroll = (props: any): any => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window !== null && window !== undefined ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
};

export default ElevationScroll;
