import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// import { Product } from '@neatin/api-client/dist/products/types';
import ProductCounter from './ProductCounter';
import { getParamByISO } from 'iso-country-currency';
import MerchantContext from '../utils/contexts/MerchantContext';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  // addProductToCart,
  Order,
  updateProductToCart
  // updateProductToCart
} from '../store/orderSlice';
import { Trans } from 'react-i18next';
import { GetMenuColor } from '../utils/useFullFunctions';
import GA4React from 'ga-4-react';
import { selectSelecteCookie } from '../store/appSlice';
import Big from 'big.js';
// import Dinero from 'dinero.js';

interface OrderItemProps {
  item: Order;
  // item: Product & { quantity: number };
  // quantity: number;
}

const OrderItem: React.FC<OrderItemProps> = ({ item }) => {
  const ga4: any = GA4React.getGA4React();
  const selectCookie = useAppSelector(selectSelecteCookie);
  const merchant = useContext(MerchantContext);
  // const cart = useAppSelector(selectOrderItems) ?? [];
  const dispatch = useAppDispatch();
  const [product, setProduct] = useState<any>(null);
  const [variant, setVariant] = useState<any>(null);
  const [addons, setAdddons] = useState<any[]>([]);
  const currencySymbol =
    merchant?.country !== null && merchant?.country !== undefined
      ? getParamByISO(merchant.country ?? 'GE', 'symbol')
      : '';

  const updateCount = useCallback(
    (q: number): void => {
      try {
        if (selectCookie?.consent?.analytics) {
          ga4.event('change_item_quantity', merchant?.name, 'order', true);
        }
      } catch (error) {}
      dispatch(
        updateProductToCart({
          // productId: item.productId,
          // variant: item.variant,
          // addons: item.addons,
          ...item,
          quantity: q
        })
      );
    },
    [merchant?.name, dispatch, updateProductToCart]
  );

  const findProduct = useCallback(() => {
    let found = false;
    // in case merchant.categories are menus
    let categories: any[] = [];
    if (merchant.categories[0].childs != null) {
      for (const menu of merchant.categories) {
        for (const category of menu.childs) {
          categories.push(category);
        }
      }
    } else {
      categories = merchant.categories;
    }
    for (const category of categories) {
      for (const product of category.products) {
        if (product.id === item.productId) {
          setProduct(product);
          found = true;
          break;
        }
      }
      if (found) break;
    }
  }, [item, merchant]);

  const getSingleOrderPrice = useCallback(
    (item: any): Big | null => {
      if (product === null || product === undefined) return null;
      const variant = item.variant;
      const addons = item.addons;
      let price = new Big(product.price);
      if (variant !== null && variant !== undefined) {
        const v = product.childs.find((i: any) => i.id === variant);
        if (v !== null && v !== undefined) price = new Big(v.price);
      }
      if (addons !== null && addons !== undefined && addons.length > 0) {
        let allAddons: any[] = [];
        for (const c of product.categories) {
          allAddons = [...allAddons, ...c.products];
        }
        for (const addon of addons) {
          const productAddon = allAddons.find((a: any) => a.id === addon.addonId);
          const orderAddon = addons.find((a: any) => a.addonId === productAddon.id);
          price = price.add(new Big(productAddon.price).times(orderAddon?.quantity ?? 1));
        }
      }
      // return price.times(item.quantity).round(2, Big.roundHalfUp).valueOf();
      return price.round(2, Big.roundHalfUp);
    },
    [item, product]
  );

  const singleOrderPrice = useMemo(
    () => getSingleOrderPrice(item)?.toNumber() ?? 0,
    [item.productId, getSingleOrderPrice]
  );

  const orderPrice = useMemo(
    () => new Big(singleOrderPrice).times(item.quantity).round(2, Big.roundHalfUp).toNumber(),
    [item.quantity, singleOrderPrice]
  );

  const getVariant = (): void => {
    for (const variant of product.childs) {
      if (variant.id === item.variant) {
        setVariant(variant);
      }
    }
  };

  const getAddons = (): void => {
    let addons = [];
    if (product.categories != null) {
      for (const addonGroup of product.categories) {
        for (const addon of addonGroup.products) {
          addons.push(addon);
        }
      }
      addons = addons.filter((a) => item.addons?.map((i) => i.addonId).includes(a.id));
    }
    setAdddons(addons);
  };

  useEffect(() => {
    findProduct();
  }, [findProduct]);

  useEffect(() => {
    if (product != null) {
      getAddons();
      getVariant();
    }
  }, [product]);

  return (
    <>
      <div className="flex px-2">
        <div className="grid grid-cols-6 w-full items-center">
          <ProductCounter
            initialValue={item.quantity}
            getCount={updateCount}
            direction="vertical"
          />
          <div className="col-span-4">
            <div className="font-bold truncate">{product?.name}</div>
            {product !== null &&
            product !== undefined &&
            merchant.printer_enable === 1 &&
            product.printable === 0 ? (
              <div className="text-red-700 text-xs">
                <Trans i18nKey={'this-product-is-not-available-anymore'}></Trans>
              </div>
            ) : null}
            <div className="text-gray-400">
              <span className="font-bold" style={{ color: GetMenuColor(merchant) }}>
                {orderPrice} {currencySymbol}
              </span>{' '}
              <span>
                ({`${String(singleOrderPrice)} ${currencySymbol} / `}{' '}
                <Trans i18nKey={'item'}></Trans>)
              </span>
              <div>{variant?.name}</div>
              {addons.map((addon) => (
                <div key={addon.id}>{addon.name}</div>
              ))}
            </div>
          </div>
          <div
            className={
              (product?.image !== null && product?.image !== undefined
                ? ' aspect-video '
                : ' w-auto ') + 'h-10 flex justify-end rounded-xl'
            }
            style={{
              backgroundImage:
                product?.image !== null &&
                product?.image !== undefined &&
                process.env.REACT_APP_UPLOADS_URL !== null &&
                process.env.REACT_APP_UPLOADS_URL !== undefined
                  ? `url(${process.env.REACT_APP_UPLOADS_URL}/${product?.image as string})`
                  : undefined,
              backgroundSize: 'cover'
            }}></div>
        </div>
      </div>
    </>
  );
};

export default React.memo(OrderItem);
