/* eslint-disable */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import isEqual from 'lodash/isEqual';
import Big from 'big.js';
import { Categories } from '@neatin/api-client/dist/categories';
export interface AddonOrder {
  addonId: number;
  quantity: number;
}
export interface Order {
  id?: string;
  productId: number;
  quantity: number;
  addons?: AddonOrder[];
  variant?: number | null;
}

interface OrderState {
  orderItems: Order[];
  orderNote: string;
  orderId: number | null;
  customTip: number;
  transactionInfo: any | null;
}

const initialState: OrderState = {
  orderItems: [],
  orderNote: '',
  customTip: 0,
  orderId: null,
  transactionInfo: null
};

export const orderTotalPrice = (merchant: any, orders: Order[]): Big => {
  // console.log('orders ', orders, merchant);
  // console.log(new Big(0))
  const productPrice = new Map<number, number>();
  const addonPrice = new Map<number, number>();
  const variantPrice = new Map<number, number>();
  if (merchant?.categories == null) {
    return Big(0);
  }
  let categories = [];
  // in case the first level categories are menus
  // we extract the childs of the menus which are the actual categories
  if (merchant.categories[0]?.childs != null) {
    for (let menu of merchant.categories) {
      for (let category of menu.childs) {
        if (categories.find((c) => c.id === category.id) == null) {
          categories.push(category);
        }
      }
    }
  } else {
    // if the are not menus, we use directly
    categories = merchant.categories;
  }
  for (const category of categories) {
    if (category?.products == null) continue;
    for (const product of category.products) {
      productPrice.set(product.id, product.price);
      if (product?.categories != null) {
        for (const addonGroup of product.categories) {
          if (addonGroup?.products == null) continue;
          for (const addon of addonGroup.products) {
            addonPrice.set(addon.id, addon.price);
          }
        }
      }
      if (product.childs != null) {
        for (const variant of product.childs) {
          variantPrice.set(variant.id, variant.price);
        }
      }
    }
  }
  // console.log("addon price ", addonPrice.entries() as any)
  let total = new Big(0);
  // let totalProductPrice = Big(0);
  for (let order of orders) {
    let price = new Big(
      order.variant != null ? 0 : ((productPrice.get(order.productId) ?? 0) as any)
    );
    if (
      order.variant !== null &&
      order.variant !== undefined &&
      variantPrice.get(order.variant) != null
    ) {
      price = price.add(new Big(variantPrice.get(order.variant) as any));
    }

    let totalAddonPrice = new Big(0);
    if (order.addons) {
      for (let addon of order.addons) {
        if (addonPrice.get(addon.addonId) != null) {
          let addonTmpPrice = new Big(addonPrice.get(addon.addonId) as any).times(addon.quantity);
          totalAddonPrice = totalAddonPrice.add(addonTmpPrice);
        }
      }
      price = price.add(totalAddonPrice);
    }
    price = price.times(order.quantity);
    total = total.add(price);
  }
  return total;
};

const OrderSlice = createSlice({
  name: 'Order',
  initialState,
  reducers: {
    addProductToCart: (state: OrderState, action: PayloadAction<Order>) => {
      console.log('adding');
      try {
        let tmp =
          current(state.orderItems)?.filter((order) => {
            const a: any = { ...order };
            const b: any = { ...action.payload };
            delete a.quantity;
            delete b.quantity;
            return !isEqual(a, b);
            // return order.productId === action.payload.productId;
          }) ?? [];

         console.log('tmp : ', tmp);

        if (action.payload.quantity > 0) {
          tmp = [...tmp, { ...action.payload, id: crypto.randomUUID() }];
        }
        state.orderItems = tmp;
      } catch (error) {
        console.log(error);
      }
    },
    updateProductToCart: (state: OrderState, action: PayloadAction<Order>) => {
      const exists = current(state.orderItems)?.find((order) => {
        const a: any = { ...order };
        const b: any = { ...action.payload };
        delete a.quantity;
        delete b.quantity;
        // console.log(order, action.payload, isEqual(a, b));
        return isEqual(a, b);
      });
      // console.log('updating', exists, action.payload);
      if (exists == null) return;
      const tmp =
        current(state.orderItems)?.map((order) => {
          const a: any = { ...order };
          const b: any = { ...action.payload };
          delete a.quantity;
          delete b.quantity;
          if (isEqual(a, b)) {
            return {
              ...order,
              quantity: action.payload.quantity
            };
          }
          return order;
        }) ?? [];
      state.orderItems = tmp.filter((i) => i.quantity > 0);
    },
    removeProductFromCart: (state: OrderState, action: PayloadAction<number>) => {
      const tmp =
        current(state.orderItems)?.filter((order) => {
          return !isEqual(order, action.payload);
          // return order.productId !== action.payload;
        }) ?? [];
      state.orderItems = tmp;
    },
    resetOrder: (state: OrderState) => {
      state.orderItems = [];
    },
    setOrderNote: (state: OrderState, action: PayloadAction<string>) => {
      state.orderNote = action.payload;
    },
    setCustomTip: (state: OrderState, action: PayloadAction<number>) => {
      state.customTip = action.payload;
    },
    setOrderId: (state: OrderState, action: PayloadAction<number>) => {
      state.orderId = action.payload;
    },
    setTransactionInfo: (state: OrderState, action: PayloadAction<any>) => {
      state.transactionInfo = action.payload;
    },
    initOrder: (state: OrderState) => {
      state.orderItems = [];
      state.orderNote = '';
      state.customTip = 0;
      state.transactionInfo = null;
    }
  }
});

export const {
  addProductToCart,
  updateProductToCart,
  removeProductFromCart,
  resetOrder,
  setOrderNote,
  setCustomTip,
  setOrderId,
  setTransactionInfo,
  initOrder
} = OrderSlice.actions;

export const selectOrderItems = (state: RootState) => state.order.orderItems;
export const selectOrderNote = (state: RootState) => state.order.orderNote;
export const selectCustomTip = (state: RootState) => state.order.customTip;
export const selectOrderId = (state: RootState) => state.order.orderId;
export const selectTransactionInfo = (state: RootState) => state.order.transactionInfo;

export default OrderSlice.reducer;
