import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import MerchantContext from '../utils/contexts/MerchantContext';
import useNeatinAPIClient from '../utils/hooks/useNeatinAPIClient';
import { SelectModeValue, GetMenuColor } from '../utils/useFullFunctions';

interface PopupFeedbackProps {
  closePopup: () => void;
}

const PopupFeedback: React.FC<PopupFeedbackProps> = ({ closePopup }: any) => {
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
  const { t } = useTranslation();
  const client = useNeatinAPIClient();
  const restaurant = useContext(MerchantContext);
  const yourMessage = t('your-message');

  const handleChange = (e: any): void => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e: any): void => {
    e.preventDefault();
    if (String(message).trim() === '') return;
    setIsLoading(true);

    client
      .sendFeedback({ message, url_id: restaurant?.url_id })
      .then(() => {
        setIsLoading(false);
        setSubmitSuccess(true);
      })
      .catch((err: any) => {
        console.error(err);
        setSubmitSuccess(true);
      });

    setTimeout(() => {
      closePopup();
    }, 3000);
  };

  return (
    <div className=" relt bg-white text-gray-800 rounded-t-3xl pt-4">
      <div className=" relt flex">
        <span
          className=" relt rounded-3xl mx-auto"
          style={{ height: 4, width: 128, backgroundColor: '#CBD5E1' }}></span>
        <div className=" relt p-2 mr-1 absolute right-0 top-2" onClick={closePopup}>
          <CloseIcon />
        </div>
      </div>
      {submitSuccess ? (
        <h1 className=" relt w-full font-bold h-64 pt-16 text-center">
          <Trans i18nKey="thank-you-for-your-message">Thank you for your message!</Trans>
        </h1>
      ) : (
        <div className=" relt p-4">
          <h1 className=" relt font-bold">
            <Trans i18nKey="have-any-feedback-reach-out">Have any feedback? Reach out.</Trans>
          </h1>
          <div className=" relt w-full mb-4">
            <div className=" relt mx-auto bg-white w-2/6 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className=" relt flex flex-wrap w-full text-left mx-auto">
              <div className=" relt relative w-full">
                {message !== '' ? (
                  <label
                    className={` ${SelectModeValue(
                      restaurant,
                      'text-white',
                      'text-gray-700'
                    )} block uppercase  text-xs font-bold mb-1`}
                    htmlFor="message">
                    <Trans i18nKey="your-message">Your message</Trans>
                  </label>
                ) : null}
                <textarea
                  rows={12}
                  name="message"
                  className={`${SelectModeValue(
                    restaurant,
                    'customBarkBackground placeholder-white ',
                    'bg-gray-100 placeholder-gray-700 '
                  )}  rounded-xl p-3 text-sm shadow focus:outline-none w-full resize-none`}
                  placeholder={yourMessage as any}
                  required
                  maxLength={500}
                  onChange={handleChange}
                />
              </div>

              <button
                id="send-feedback"
                className={` mx-auto lg:mx-0 text-white w-full text-gray-800 rounded-xl my-6 py-4 px-8`}
                style={{ backgroundColor: GetMenuColor(restaurant), color: 'white' }}
                disabled={isLoading}>
                <Trans i18nKey="send">Send</Trans>{' '}
                {isLoading ? (
                  <img src="/loading.webp" className=" inline pl-2 w-8" alt="Loading.." />
                ) : null}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default PopupFeedback;

// export default withTranslation()(PopupFilter);
