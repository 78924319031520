import React from 'react';
import { selectSelecteCookie, selectSelectedFav, setSelectedFav } from '../store/appSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { ReactComponent as HeartIcon } from '../icons/heart.svg';
import { ReactComponent as HeartSelectedIcon } from '../icons/heart-selected.svg';
import { Product } from '@neatin/api-client/dist/products/types';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
interface SavedIndicatorProps {
  item: Product;
}

const SavedIndicator: React.FC<SavedIndicatorProps> = ({ item }) => {
  const selectedFav = useAppSelector(selectSelectedFav);
  const selectCookie = useAppSelector(selectSelecteCookie);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const toggleFavFilter = (): void => {
    let newFavFilter: number[];
    if (!selectedFav.includes(item.id)) {
      newFavFilter = [...selectedFav, item.id];
    } else {
      newFavFilter = selectedFav.filter((e: any) => e !== item.id);
    }

    dispatch(setSelectedFav([...newFavFilter]));
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (selectCookie?.consent?.targetedAdvertising) {
            toggleFavFilter();
          } else {
            const message: any = t('activate-cookies-preferences');
            toast.error(message);
          }
        }}
        className="p-0.5">
        {selectedFav.includes(item.id) && selectCookie?.consent?.targetedAdvertising ? (
          <HeartSelectedIcon />
        ) : (
          <HeartIcon />
        )}
      </div>
    </>
  );
};

export default SavedIndicator;
