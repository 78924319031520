/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

interface CookieInterface {
  isSet: boolean;
  enable: boolean;
  consent: {
    all: boolean;
    analytics: boolean;
    targetedAdvertising: boolean;
  };
}
interface AppState {
  seletedLabels: any[];
  selectedFav: any[];
  removeAllergens: boolean;
  language: string | undefined | null;
  cookie: CookieInterface;
  merchantIsOnline: boolean;
}

const initialState: AppState = {
  seletedLabels: [],
  selectedFav: [],
  removeAllergens: false,
  language: undefined,
  cookie: {
    isSet: false,
    enable: false,
    consent: {
      all: false,
      analytics: false,
      targetedAdvertising: false
    }
  },
  merchantIsOnline: false,
};

const AppSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    setSelectedLabels: (state: AppState, action: PayloadAction<any>) => {
      state.seletedLabels = action.payload;
    },
    setSelectedFav: (state: AppState, action: PayloadAction<any>) => {
      state.selectedFav = action.payload;
    },
    setRemoveAllergens: (state: AppState, action: PayloadAction<any>) => {
      state.removeAllergens = action.payload;
    },
    setRemovePreferences: (state: AppState, action: PayloadAction<any>) => {
      state.removeAllergens = action.payload;
      state.seletedLabels = [];
      state.selectedFav = [];
    },
    setSelectedLanguage: (state: AppState, action: PayloadAction<any>) => {
      state.language = action.payload;
    },
    setSelectedCookie: (state: AppState, action: PayloadAction<any>) => {
      state.cookie = action.payload;
    },
    setSelectedMerchantIsOnline: (state: AppState, action: PayloadAction<boolean>) => {
      state.merchantIsOnline = action.payload;
    },
  }
});

export const {
  setSelectedLabels,
  setSelectedFav,
  setRemoveAllergens,
  setSelectedLanguage,
  setSelectedCookie,
  setRemovePreferences,
  setSelectedMerchantIsOnline
} = AppSlice.actions;
export const selectSelectedLabels = (state: RootState) => state.app.seletedLabels;
export const selectSelectedFav = (state: RootState) => state.app.selectedFav;
export const selectRemoveAllergens = (state: RootState) => state.app.removeAllergens;
export const selectSelectedLanguage = (state: RootState) => state.app.language;
export const selectSelecteCookie = (state: RootState) => state.app.cookie;
export const selectSelectedMerchantIsOnline = (state: RootState) => state.app.merchantIsOnline;

export default AppSlice.reducer;
