import React, { useContext, useEffect } from 'react';
import useNeatinAPIClient from '../utils/hooks/useNeatinAPIClient';
import toast from 'react-hot-toast';
import { useAppSelector } from '../store/hooks';
import { selectSelecteCookie } from '../store/appSlice';
import GA4React from 'ga-4-react';
import MerchantContext from '../utils/contexts/MerchantContext';
import { Trans, useTranslation } from 'react-i18next';
import { GetMenuColor } from '../utils/useFullFunctions';
import { ReactComponent as DownloadIcon } from '../icons/download.svg';
interface PopupOrderPdfProps {
  closePopup: () => void;
  handlePrint: (html: string) => void;
  orderId: string | null;
}

const PopupOrderPdf: React.FC<PopupOrderPdfProps> = ({ closePopup, handlePrint, orderId }: any) => {
  const client = useNeatinAPIClient();
  const selectCookie = useAppSelector(selectSelecteCookie);
  const merchant = useContext(MerchantContext);
  const ga4: any = GA4React.getGA4React();
  const { t } = useTranslation();

  const loadPdf = async (): Promise<void> => {
    const invoiceHtml = await client.getOrderReciepTemplate(Number(orderId ?? 0));
    const domElement: any = document.getElementById('invoice');
    domElement.innerHTML = invoiceHtml.data;
  };

  useEffect(() => {
    loadPdf()
      .then(() => {
        console.log('ok');
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orderId]);

  return (
    <div className=" relt bg-white text-gray-800 rounded-t-3xl pt-4">
      <div id="invoice" className="p-4"></div>
      <p className="text-gray-800">
        <Trans key={'this-is-not-a-receipt-info'} />
      </p>
      <div className="relt  bottom-0 w-full flex justify-evenly pb-5">
        <div
          className="text-white rounded-lg h-10 border w-2/5 cursor-pointer b-1 flex items-center justify-evenly"
          onClick={() => {
            handlePrint(document.getElementById('invoice')?.innerHTML);
            closePopup();
            const message: any = t('reciept-downloaded');
            toast.success(message);

            try {
              if (selectCookie?.consent?.analytics) {
                ga4.event('download_invoice', merchant?.name, 'payment', true);
              }
            } catch (error) {}
          }}
          style={{ borderColor: GetMenuColor(merchant), color: GetMenuColor(merchant) }}>
          <DownloadIcon />
          <span className="truncate">
            <Trans i18nKey={'download'}></Trans>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PopupOrderPdf;
