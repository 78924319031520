import { Rating, SwipeableDrawer } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import JsPdf from 'jspdf';
// import { FaCheckCircle } from 'react-icons/fa';
import { GetMenuColor } from '../utils/useFullFunctions';
import { ReactComponent as CheckIcon } from '../icons/check.svg';
import { ReactComponent as EmailIcon } from '../icons/email.svg';
import { ReactComponent as EmptyStar } from '../icons/empty-star.svg';
import { ReactComponent as FullStar } from '../icons/full-star.svg';
// import { ReactComponent as HomeIcon } from '../icons/menu-selected.svg';
import MerchantContext from '../utils/contexts/MerchantContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
// import { selectOrderId } from '../store/orderSlice';
import PopupPaymentReview from '../components/PopupPaymentReview';
import PopupOrderSendMail from '../components/PopupOrderSendMail';
import useNeatinAPIClient from '../utils/hooks/useNeatinAPIClient';
import { getParamByISO } from 'iso-country-currency';
import GA4React from 'ga-4-react';
import { Order } from '@neatin/api-client/dist/orders/types';
import { Toaster } from 'react-hot-toast';
import { selectSelecteCookie } from '../store/appSlice';
import PopupOrderPdf from '../components/PopupOrderPdf';
import { FaEye } from 'react-icons/fa';

const PaymentSuccessfulPage: React.FC<{}> = () => {
  const ga4: any = GA4React.getGA4React();
  const client = useNeatinAPIClient();
  const [rating, setRating] = useState<number>(0);
  const [order, setOrder] = useState<Order | null>(null);
  const merchant = useContext(MerchantContext);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openPopupPdf, setOpenPopupPdf] = useState<boolean>(false);
  const [openPopupOrderEmail, setOpenPopupOrderEmail] = useState<boolean>(false);
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const params = useParams();
  const selectCookie = useAppSelector(selectSelecteCookie);
  const orderId = params.orderId;
  // const cart = useAppSelector(selectOrderItems);

  const currencySymbol =
    merchant?.country !== null && merchant?.country !== undefined
      ? getParamByISO(merchant.country ?? 'GE', 'symbol')
      : '';

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const settings =
    merchant?.old_settings?.value != null && merchant?.old_settings?.value !== undefined
      ? JSON.parse(merchant.old_settings.value.replace('\n', ''))
      : null;

  const showGoogleReviews: boolean =
    settings?.google?.review_url?.length > 0 && settings?.google?.active;

  const showTripAdvisor: boolean =
    settings?.tripadvisor?.review_url?.length > 0 && settings?.tripadvisor?.active;

  useEffect(() => {
    if (orderId !== null && orderId !== undefined) {
      client
        .verifyOrderPayment(Number(orderId))
        .then(() => {
          client
            .getOrder(String(orderId))
            .then((res) => {
              setOrder(res);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }

    loadPdf();

    const backToHome = (): void => {
      navigate(`/${merchant.url_id as string}?table=${merchant.table.url_id as string}`, {
        replace: true
      });
    };

    window.addEventListener('popstate', backToHome);
    return () => {
      window.removeEventListener('popstate', backToHome);
    };
  }, []);

  const onRate = async (rate: number): Promise<void> => {
    await client.updateOrder(orderId as any, { rate });
    try {
      if (selectCookie?.consent?.analytics) {
        ga4.event('rate_app', merchant?.name, 'general', true);
      }
    } catch (error) {}
  };

  const MenuSelectedIcon: React.FC<any> = () => {
    return (
      <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.5154 20.1389V16.9538C7.5154 16.1407 8.17935 15.4816 8.99837 15.4816H11.9923C12.3856 15.4816 12.7628 15.6367 13.0409 15.9128C13.319 16.1889 13.4753 16.5633 13.4753 16.9538V20.1389C13.4728 20.4769 13.6063 20.802 13.8462 21.0419C14.0861 21.2818 14.4126 21.4167 14.7531 21.4167H16.7957C17.7496 21.4191 18.6654 21.0446 19.3408 20.3759C20.0162 19.7071 20.3958 18.7989 20.3958 17.8519V8.77799C20.3958 8.01299 20.0542 7.28735 19.4631 6.79654L12.5146 1.28737C11.3059 0.321429 9.57408 0.352617 8.40145 1.36145L1.61148 6.79654C0.992443 7.27288 0.622455 8.00067 0.604172 8.77799V17.8427C0.604172 19.8165 2.21603 21.4167 4.20435 21.4167H6.20031C6.90753 21.4167 7.48229 20.8502 7.48742 20.1482L7.5154 20.1389Z"
          fill={GetMenuColor(merchant)}
        />
      </svg>
    );
  };

  const downloadPdf = (): void => {
    setOpenPopupPdf(true);
  };

  const loadPdf = (): void => {
    client
      .getOrderReciepTemplate(Number(orderId ?? 0))
      .then((invoiceHtml) => {
        handlePrint(invoiceHtml.data as string);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePrint = (receiptHtml: string): void => {
    const newDiv = document.createElement('div');
    newDiv.innerHTML = receiptHtml;
    newDiv.style.color = '#000';
    const doc = new JsPdf();
    doc
      .html(newDiv, {
        callback: function (doc) {
          doc.save('payment-cofirmation.pdf');
        },
        x: 15,
        y: 15,
        width: 170,
        windowWidth: 650
      })
      .then(() => {
        console.log('ok');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {merchant.order_pay_enable === 1 && orderId !== null && orderId !== undefined ? (
        <>
          <SwipeableDrawer
            className="customDrawer"
            anchor="bottom"
            open={openPopupOrderEmail}
            onClose={() => {
              setOpenPopupOrderEmail(false);
            }}
            onOpen={() => {
              setOpenPopupOrderEmail(true);
            }}
            disableBackdropTransition={!iOS}
            disableDiscovery={true}
            disableSwipeToOpen={true}>
            <PopupOrderSendMail
              orderId={orderId}
              closePopup={() => {
                setOpenPopupOrderEmail(false);
              }}
            />
          </SwipeableDrawer>
          <SwipeableDrawer
            className="customDrawer"
            anchor="bottom"
            open={openPopupPdf}
            onClose={() => {
              setOpenPopupPdf(false);
            }}
            onOpen={() => {
              setOpenPopupPdf(true);
            }}
            disableBackdropTransition={!iOS}
            disableDiscovery={true}
            disableSwipeToOpen={true}>
            <PopupOrderPdf
              orderId={orderId}
              closePopup={() => {
                setOpenPopupPdf(false);
              }}
              handlePrint={handlePrint}
            />
          </SwipeableDrawer>
          <SwipeableDrawer
            className="customDrawer"
            anchor="bottom"
            open={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
            onOpen={() => {
              setOpenModal(true);
            }}
            disableBackdropTransition={!iOS}
            disableDiscovery={true}
            disableSwipeToOpen={true}>
            <React.Suspense fallback={null}>
              <PopupPaymentReview
                closePopup={() => {
                  setOpenModal(false);
                }}
              />
            </React.Suspense>
          </SwipeableDrawer>
          <div className="relt p-5 text-center">
            <section className="pb-2">
              <span className="flex justify-evenly mb-5">
                {/* <FaCheckCircle size={104} color={GetMenuColor(merchant)} /> */}
                <CheckIcon />
              </span>
              <h1 className="font-bold" style={{ color: GetMenuColor(merchant), fontSize: 24 }}>
                <Trans i18nKey={'payment-successful'}></Trans>
              </h1>
              <p className="my-5 text-gray-400 mx-auto" style={{ width: 250 }}>
                <Trans i18nKey={'order-submitted'}></Trans>
              </p>
            </section>

            <section>
              <h3 className="font-bold mb-2">
                <Trans i18nKey={'you-just-paid'}></Trans> {order?.total_cost} {currencySymbol}
              </h3>
            </section>

            <section className="mt-12">
              <h3 className="text-gray-400 mb-2">
                <Trans i18nKey={'send-love'}></Trans>
              </h3>
              <Rating
                size="large"
                value={rating}
                onChange={(e, v) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setRating(v ?? 0);
                  onRate(v ?? 0).then(
                    () => {},
                    () => {}
                  );
                  if (showGoogleReviews || showTripAdvisor) {
                    setOpenModal(true);
                  }
                }}
                icon={
                  <span className="mx-1.5">
                    <FullStar />
                  </span>
                }
                emptyIcon={
                  <span className="mx-1.5">
                    <EmptyStar />
                  </span>
                }
              />
            </section>

            <div
              className="bg-gray-100 h-8 rounded-lg flex justify-center items-center p-2 mt-12"
              style={{ color: GetMenuColor(merchant) }}
              onClick={() => {
                // dispatch(resetOrder());
                if (params.restaurant_id !== undefined) {
                  if (merchant?.table?.url_id !== null && merchant?.table?.url_id !== undefined) {
                    navigate(`/${params.restaurant_id}?table=${merchant.table.url_id as string}`, {
                      replace: true
                    });
                  } else {
                    navigate(`/${params.restaurant_id}`, { replace: true });
                  }
                }
              }}>
              <span className="mr-2">
                <MenuSelectedIcon />{' '}
              </span>
              <Trans i18nKey={'go-to-the-menu'}></Trans>
            </div>
          </div>
          <section className="w-full relt p-2 mt-4">
            <p className="text-center font-bold" style={{ fontSize: 14 }}>
              <Trans i18nKey={'would-you-like-to-get-the-bill-question'}></Trans>
            </p>
            <div className="grid grid-cols-2 gap-5 mt-3 px-5">
              <div
                className="text-white rounded-lg h-10 border  cursor-pointer b-1 flex items-center justify-evenly"
                onClick={() => {
                  downloadPdf();
                }}
                style={{ borderColor: GetMenuColor(merchant), color: GetMenuColor(merchant) }}>
                <FaEye />
                <span className="truncate">
                  <Trans i18nKey={'view-reciept'}></Trans>
                </span>
              </div>
              <div
                className="text-white rounded-lg h-10 border b-1  cursor-pointer flex items-center justify-evenly"
                onClick={() => {
                  setOpenPopupOrderEmail(true);
                  try {
                    if (selectCookie?.consent?.analytics) {
                      ga4.event('email_invoice', merchant?.name, 'payment', true);
                    }
                  } catch (error) {}
                }}
                style={{ borderColor: GetMenuColor(merchant), color: GetMenuColor(merchant) }}>
                <EmailIcon />
                <span className="truncate">
                  <Trans i18nKey={'send-via-email'}></Trans>
                </span>
              </div>
            </div>
          </section>
          <Toaster
            position="top-center"
            containerClassName=""
            toastOptions={{ className: 'rounded-full', duration: 2000 }}
          />
        </>
      ) : null}
    </>
  );
};

export default PaymentSuccessfulPage;
