import React, { useContext, useState } from 'react';
// import { Product } from '@neatin/api-client/dist/products/types';
import { GetMenuColor, enableOrder } from '../utils/useFullFunctions';
import { Trans, useTranslation } from 'react-i18next';
import ProductCounter from './ProductCounter';
import MerchantContext from '../utils/contexts/MerchantContext';
import { getParamByISO } from 'iso-country-currency';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { AddonOrder, addProductToCart } from '../store/orderSlice';
import toast from 'react-hot-toast';
import GA4React from 'ga-4-react';
import { selectSelecteCookie, selectSelectedMerchantIsOnline } from '../store/appSlice';
import { selectMerchantData } from '../store/merchantSlice';
// import { Calc } from 'calc-js';
import Big from 'big.js';

interface ProductOrderProps {
  item: any; // should be product
  afterAddCb?: () => any;
  variant?: number | null;
  addons?: AddonOrder[];
}

const ProductOrder: React.FC<ProductOrderProps> = ({ item, variant, addons, afterAddCb }) => {
  const ga4: any = GA4React.getGA4React();
  const [quantity, setQuantity] = useState<number>(1);
  const { t } = useTranslation();
  const merchant = useContext(MerchantContext);
  const selectCookie = useAppSelector(selectSelecteCookie);
  const merchantIsOnline = useAppSelector(selectSelectedMerchantIsOnline);
  const dispatch = useAppDispatch();
  const currencySymbol =
    merchant?.country !== null && merchant?.country !== undefined
      ? getParamByISO(merchant.country ?? 'GE', 'symbol')
      : '';
  const merchantData = useAppSelector(selectMerchantData);

  const hasVariants = (product: any): boolean => {
    return product?.childs?.filter((p: any) => p.type === 'variant')?.length > 0;
  };

  const isValid = (): boolean => {
    // console.log(item, merchant);
    if (merchant.printer_enable === 1 && item.printable === 0) return false;
    if (!enableOrder(merchantData, merchantIsOnline)) return false;
    // if (item.printable === false) {
    //   return false;
    // }
    const hv = hasVariants(item);
    // check if required addons
    const requiredAddonGroups = item?.categories?.filter(
      (c: any) => c.type === 'addon_group' && c.skippable === 0
    );
    const variantOk = (hv && variant != null) || !hv;
    if (requiredAddonGroups?.length === 0) return variantOk;
    // let addonsOk = true;
    if (addons != null && requiredAddonGroups != null) {
      for (const addonGroup of requiredAddonGroups) {
        let ok = false;
        for (const addon of addons) {
          if (addonGroup.products.map((a: any) => a.id).includes(addon.addonId) === true) {
            ok = true;
            break;
          }
        }
        if (!ok) {
          return false;
        }
      }
    }

    return variantOk;
  };

  const addToCart = (): void => {
    if (!isValid()) return;
    try {
      if (selectCookie?.consent?.analytics) {
        ga4.event('add_to_cart', merchant?.name, 'product', true);
      }
    } catch (error) {}

    dispatch(
      addProductToCart({
        productId: item.id,
        variant,
        addons,
        quantity
      })
    );
    const message: any = t('product-added');
    toast(message);
    if (afterAddCb !== null && afterAddCb !== undefined) afterAddCb();
    // toast.custom(<div className="bg-white px-8 px-2 rounded-full">{t('product-added')}</div>);
  };

  const orderPrice = (item: any): any => {
    const hv = hasVariants(item);
    let price = Big(hv ? 0 : item.price);
    if (variant !== null && variant !== undefined) {
      const v = item.childs.find((i: any) => i.id === variant);
      if (v !== null && v !== undefined) price = price.add(v.price);
    }
    if (addons !== null && addons !== undefined && addons.length > 0) {
      let allAddons: any[] = [];
      for (const c of item.categories) {
        allAddons = [...allAddons, ...c.products];
      }
      for (const addon of addons) {
        const productAddon = allAddons.find((a: any) => a.id === addon.addonId);
        const orderAddon = addons.find((a) => a.addonId === productAddon.id);
        price = price.add(Big(productAddon.price).times(orderAddon?.quantity ?? 1));
      }
    }
    return price.times(quantity).round(2, Big.roundHalfUp).valueOf();
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-4 items-center pt-1">
        <div>
          <ProductCounter initialValue={quantity} getCount={setQuantity} />
        </div>
        <div
          className={`col-span-2 relt flex justify-between px-1 py-2 items-center rounded-lg `}
          style={{
            fontSize: undefined,
            backgroundColor: isValid() ? GetMenuColor(merchant) : '#ccc',
            color: '#fff'
          }}
          onClick={addToCart}>
          <span className="truncate px-1">
            <Trans i18nKey={'add-to-cart'}></Trans>
          </span>
          <span className="font-bold p-1 truncate">
            {orderPrice(item)} {currencySymbol}
          </span>
        </div>
        {merchant.printer_enable === 1 && item.printable === 0 ? (
          <div className="col-span-3">
            <span className="font-medium text-sm text-red-600 dark:text-red-500">
              <Trans i18nKey={'cannot-order-product'}></Trans>
            </span>
          </div>
        ) : null}
        {!enableOrder(merchantData, merchantIsOnline) &&
        (merchant.printer_enable === 0 ||
          (merchant.printer_enable === 1 && item.printable === 1)) ? (
          <div className="col-span-3">
            <span className="font-medium text-sm text-red-600 dark:text-red-500">
              <Trans i18nKey={'merchant-cannot-accept-order'}></Trans>
            </span>
          </div>
        ) : null}
      </div>
      {/* <OrderActionSnackBar /> */}
    </>
  );
};

export default ProductOrder;
