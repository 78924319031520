import qs from 'querystringify'
import { NewMerchantPos, MerchantPos, SearchMerchantPossParams, TestMerchantPos } from './types'
import { Base, Response } from '../base'

const resourceName = 'merchant-pos'

export class MerchantPoss extends Base {
    getMerchantPoss(params?: SearchMerchantPossParams) {
        let query = `${resourceName}`
        if (params) {
            query += qs.stringify(params, '?')
        }
        return this.request<MerchantPos[]>(query)
    }

    getMerchantPos(id: number) {
        return this.request<MerchantPos>(`${resourceName}/${id}`)
    }

    getMerchantPosByMerchantAndPos(id: number, posId:number) {
        return this.request<MerchantPos>(`${resourceName}/merchant/${id}/pos/${posId}`)
    }

    testAndSaveMerchantPos(params: TestMerchantPos,url_id:string) {
        return this.request<Response<String>>(`${resourceName}/test-save-credentials/${url_id}`, {
            method: 'POST',
            body: JSON.stringify({ ...params })
        })
    }

    createMerchantPos(params: NewMerchantPos) {
        return this.request<MerchantPos>(resourceName, {
            method: 'POST',
            body: JSON.stringify({ ...params })
        })
    }

    updateMerchantPos(id: number, params?: NewMerchantPos) {
        return this.request<MerchantPos>(`${resourceName}/${id}`, {
            method: 'PUT',
            body: JSON.stringify({ ...params })
        })
    }

    deleteMerchantPos(id: number) {
        return this.request<MerchantPos>(`${resourceName}/${id}`, { method: 'DELETE' })
    }

}
