import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  selectSelecteCookie,
  selectSelectedLanguage,
  setSelectedLanguage
} from '../store/appSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import ISO6391 from 'iso-639-1';
import MerchantContext from '../utils/contexts/MerchantContext';
import GA4React from 'ga-4-react';
import ReactCountryFlag from 'react-country-flag';
import { setLoadingTranslations } from '../store/merchantSlice';
import { GA4ReactResolveInterface } from 'ga-4-react/dist/models/gtagModels';
import { GetMenuColor } from '../utils/useFullFunctions';
interface PopupLanguageProps {
  closePopup: () => void;
}
const PopupLanguage: React.FC<PopupLanguageProps> = ({ closePopup }) => {
  const gaCode = process.env?.REACT_APP_GA_CODE ?? '';
  const merchant = useContext(MerchantContext);
  const selectCookie = useAppSelector(selectSelecteCookie);

  const getGa4 = (action = 'click', data = ''): void => {
    try {
      if (selectCookie?.consent?.analytics) {
        switch (GA4React.isInitialized()) {
          case true: {
            const ga4 = GA4React.getGA4React();
            if (ga4 !== null && ga4 !== undefined) {
              ga4.event(action, data, data, false);
            }
            break;
          }
          case false: {
            const ga4react = new GA4React(gaCode);
            ga4react.initialize().then(
              (ga4: GA4ReactResolveInterface) => {},
              (err: Error) => {
                console.error(err);
              }
            );
            break;
          }
        }
      }
    } catch (error) {}
  };
  const GaEventTrackerSave = (action: string): void => {
    getGa4(action, (merchant?.name as string) ?? 'menu_page');
  };

  const selectedLanguage = useAppSelector(selectSelectedLanguage);
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const restaurant = useContext(MerchantContext);

  const onSave = (): void => {
    closePopup();
  };

  const onLanguageSelect = (language: string): void => {
    if (selectedLanguage !== language) {
      dispatch(setSelectedLanguage(language));
      dispatch(setLoadingTranslations(true));
    }
    GaEventTrackerSave('change_language');
    onSave();
  };

  useEffect(() => {
    if (selectedLanguage !== null && selectedLanguage !== undefined) {
      i18n.changeLanguage(selectedLanguage).then(
        () => {},
        () => {}
      );
    }
  }, [selectedLanguage, i18n]);

  return (
    <div className=" relt bottom-0 w-full h-auto m-auto p-4 bg-white text-gray-800 rounded-t-3xl">
      <div className="flex">
        <span
          className="rounded-3xl mx-auto"
          style={{ height: 4, width: 128, backgroundColor: '#CBD5E1' }}></span>
        <div className="p-2 mr-1 absolute right-0 top-2" onClick={onSave}>
          <CloseIcon />
        </div>
      </div>

      <p style={{ fontWeight: 700, fontSize: 16 }} className="pt-5">
        <Trans i18nKey={'preferred-menu-language'}></Trans>
      </p>
      <div
        className="overflow-y-scroll overflow-x-none"
        style={{ height: window.innerHeight * 0.3 }}>
        <div>
          <div className="flex flex-wrap my-3">
            {restaurant?.languages
              ?.map((language: any) => language.iso_639_1_code)
              .map((language: string, index: number) => (
                <div
                  key={index}
                  className="rounded-3xl w-auto py-1 px-4 mr-3 mb-3 font-bold flex items-center"
                  style={{
                    backgroundColor:
                      language === selectedLanguage ? GetMenuColor(merchant) : '#F1F5F9',
                    color: language === selectedLanguage ? '#fff' : '#64748B',
                    fontSize: 14
                  }}
                  onClick={() => onLanguageSelect(language)}>
                  <ReactCountryFlag
                    countryCode={String(language ?? '') === 'en' ? 'gb' : language?.toUpperCase()}
                    svg
                  />
                  <span className="pl-1">{ISO6391.getNativeName(language.toLowerCase())}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupLanguage;
