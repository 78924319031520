/* eslint-disable */ 
import { selectRemoveAllergens, selectSelectedLabels } from '../../store/appSlice';
import { useAppSelector } from '../../store/hooks';
import { selectLabels } from '../../store/merchantSlice';

const useSortedAndFilteredProducts = (items: any[]) => {
  const labels = useAppSelector(selectLabels);
  const selectedLabels = useAppSelector(selectSelectedLabels);
  const removeAllergens = useAppSelector(selectRemoveAllergens);

  if (items == null) return [];

  const labelsArray = labels.filter((item) => selectedLabels.includes(item.id));

  const allergyLabels = labelsArray
    .filter((label: any) => label.type === 'allergen' || label.type === 'additive')
    .map((item) => parseInt(item.id));
  const preferenceLabels = labelsArray
    .filter((label: any) => label.type === 'preference')
    .map((item) => parseInt(item.id));

  const preferences = items
    .filter((item) => !item.labels.some((label: any) => allergyLabels.includes(label.id)))
    .filter((item) => item.labels.some((label: any) => preferenceLabels.includes(label.id)));

  const allergies = items.filter((item) =>
    item.labels.some((label: any) => allergyLabels.includes(label.id))
  );

  const normal = items
    .filter((item) => !allergies.find((e) => e.id === item.id))
    .filter((item) => !preferences.find((e) => e.id === item.id));
  /*const preferences = [], allergies = [], normal = []

  for (let item of items) {
    const item_labelids: any[] = item.label_id.split(',').map((id: string) => parseInt(id))
    if (item_labelids.some((id) => allergyLabels.includes(id))) {
      allergies.push(item)
    } else if (item_labelids.some((id) => preferenceLabels.includes(id))) {
      preferences.push(item)
    } else {
      normal.push(item)
    }
  }*/

  if (removeAllergens) {
    return [...preferences, ...normal];
  }
  return [...preferences, ...normal, ...allergies];
};

export default useSortedAndFilteredProducts;
