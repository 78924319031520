import qs from "querystringify";
import {
  Merchant,
  SearchMerchantsParams,
  NewMerchant,
  UpdateMerchant,
} from "./types";
import { Base, Pagination, Response } from "../base";
import { Category } from "../categories/types";
import { Product } from "../products/types";
import { MerchantSetting } from "../merchant-settings/types";
import { Language } from "../languages/types";

const resourceName = "merchants";

export class Merchants extends Base {
  getMerchants(params?: SearchMerchantsParams) {
    let query = `${resourceName}`;
    if (params) {
      query += qs.stringify(params, "?");
    }
    return this.request<Merchant[]>(query);
  }

  getMerchant(url_id: string, languageCode: string | undefined = undefined) {
    if (languageCode) {
      return this.request<Merchant>(
        `${resourceName}/${url_id}?lang=${languageCode}`
      );
    }
    return this.request<Merchant>(`${resourceName}/${url_id}`);
  }

  getMerchantCategories(
    url_id: string,
    language: string | undefined | null = undefined,
    type: string | undefined | null = undefined
  ) {
    let query = "";
    if (language) {
      query ? (query += `&lang=${language}`) : (query += `lang=${language}`);
    }
    if (type) {
      query ? (query += `&type=${type}`) : (query += `type=${type}`);
    }

    if (query) {
      return this.request<Category[]>(
        `${resourceName}/${url_id}/categories?${query}`
      );
    }
    return this.request<Category[]>(`${resourceName}/${url_id}/categories`);
  }

  getMerchantProducts(
    url_id: string,
    language: string | undefined | null = undefined,
    type: string | undefined | null = undefined
  ) {
    let query = "";
    if (language) {
      query ? (query += `&lang=${language}`) : (query += `lang=${language}`);
    }
    if (type) {
      query ? (query += `&type=${type}`) : (query += `type=${type}`);
    }
    return this.request<Product[]>(
      `${resourceName}/${url_id}/products?${query}`
    );
  }

  getMerchantPrinters(
    url_id: string,
    language: string | undefined | null = undefined,
    type: string | undefined | null = undefined
  ) {
    return this.request<Product[]>(`${resourceName}/${url_id}/printers`);
  }

  getMerchantSettingsByKey(
    url_id: string,
    key: string | null | undefined = null
  ) {
    let query = "";
    if (key) {
      query ? (query += `&key=${key}`) : (query += `key=${key}`);
    }
    if (query) {
      return this.request<MerchantSetting>(
        `${resourceName}/${url_id}/settings?${query}`
      );
    }
    return this.request<MerchantSetting>(`${resourceName}/${url_id}/settings`);
  }

  setMerchantSetting(url_id: string, key: string, value: any) {
    return this.request<Merchant>(`${resourceName}/${url_id}/settings`, {
      method: "POST",
      body: JSON.stringify({ key, value }),
      // body: JSON.stringify({ ...params })
    });
  }

  getMerchantLanguages(url_id: string) {
    return this.request<Language[]>(`${resourceName}/${url_id}/languages`);
  }

  createMerchant(params: FormData) {
    return this.request<Merchant>(resourceName, {
      method: "POST",
      body: params,
      // body: JSON.stringify({ ...params })
    });
  }

  updateMerchant(id: number, params?: FormData) {
    return this.request<Merchant>(`${resourceName}/${id}`, {
      method: "PUT",
      body: params,
      // body: JSON.stringify({ ...params })
    });
  }

  updateMerchantAttributes(id: number, params?: FormData) {
    return this.request<Response<Merchant>>(
      `${resourceName}/attributes/${id}`,
      {
        method: "PUT",
        body: params,
        // body: JSON.stringify({ ...params })
      }
    );
  }

  deleteMerchant(id: number) {
    return this.request<Merchant>(`${resourceName}/${id}`, {
      method: "DELETE",
    });
  }
  cloneMerchant(url_id: string) {
    return this.request<any>(`${resourceName}/${url_id}/clone`);
  }
}
