import qs from "querystringify";
import { response, Printer, SearchPrintersParams } from "./types";
import { Base } from "../base";

const resourceName = "printers";

export class Printers extends Base {
  getPrinters(params?: SearchPrintersParams) {
    let query = `${resourceName}`;
    if (params) {
      query += qs.stringify(params, "?");
    }
    return this.request<Printer[]>(query);
  }

  getPrinter(id: string) {
    return this.request<Printer>(`${resourceName}/${id}`);
  }

  getPrinterSupportedModels() {
    return this.request<string[]>(`${resourceName}/supported-models`);
  }

  createPrinter(printer: Printer) {
    return this.request<response<Printer>>(resourceName, {
      method: "POST",
      body: JSON.stringify({ ...printer }),
    });
  }

  updatePrinter(id: number, printer?: Partial<Printer>) {
    return this.request<response<Printer>>(`${resourceName}/${id}`, {
      method: "PUT",
      body: JSON.stringify({ ...printer }),
    });
  }

  deletePrinter(id: string | number) {
    return this.request<Printer>(`${resourceName}/${id}`, { method: "DELETE" });
  }
}
