import React, { useContext } from 'react';
import { Product } from '@neatin/api-client/dist/products/types';
import { Trans } from 'react-i18next';
import { selectSelectedLabels } from '../store/appSlice';
import { useAppSelector } from '../store/hooks';
import { GetMenuColor } from '../utils/useFullFunctions';
import MerchantContext from '../utils/contexts/MerchantContext';

interface PreferenceIndicatorProps {
  item: Product;
}

const PreferenceIndicator: React.FC<PreferenceIndicatorProps> = ({ item }) => {
  const selectedLabels = useAppSelector(selectSelectedLabels);
  const merchant = useContext(MerchantContext);

  const isPreference = (): boolean =>
    item.labels !== null && item.labels !== undefined
      ? item.labels
          .filter((label: any) => label.type === 'preference')
          .filter((label: any) => selectedLabels.includes(label.id)).length > 0
      : false;

  const isAllergen = (): boolean =>
    item.labels !== null && item.labels !== undefined
      ? item.labels
          .filter((label: any) => label.type === 'allergen' || label.type === 'additive')
          .filter((label: any) => selectedLabels.includes(label.id)).length > 0
      : false;

  return (
    <>
      {isPreference() && !isAllergen() && (
        <span
          style={{ color: '#fff', backgroundColor: GetMenuColor(merchant) }}
          className=" relt px-2 rounded-xl text-xs capitalize">
          <Trans i18nKey={'preference'}></Trans>
        </span>
      )}
      {isAllergen() && (
        <span
          style={{ color: '#fff', backgroundColor: '#D01F46' }}
          className=" relt px-2 rounded-xl text-xs capitalize">
          <Trans i18nKey={'allergen'}></Trans>
        </span>
      )}
    </>
  );
};

export default PreferenceIndicator;
