/* eslint-disable */
import React, { useContext, useRef } from 'react';
import { Category } from '@neatin/api-client/dist/categories/types';
import { GetMenuColor } from '../utils/useFullFunctions';
import MerchantContext from '../utils/contexts/MerchantContext';
import { FaCaretDown } from 'react-icons/fa';
import { SelectModeValue } from '../utils/useFullFunctions';
const scrollToWithAnimation: any = require('scrollto-with-animation').default;

interface MenusNavigatorProps {
  selectedMenu: any;
  setSelectedMenu: any;
}

const MenusNavigator: React.FC<MenusNavigatorProps> = ({ selectedMenu, setSelectedMenu }) => {
  const categoriesMenu = useRef<HTMLDivElement>(null);
  const merchant = useContext(MerchantContext);
  const isSelected = (category: Category): boolean => selectedMenu.id === category.id;
  const onSelectMenu = (e: any, category: any) => {
    const target: HTMLElement | null | undefined = e?.target;
    const parent = target?.parentElement;
    // if (e?.target?.parentElement !== null && e?.target?.parentElement !== undefined) {
    //   scrollToWithAnimation(e?.target?.parentElement, 'scrollLeft', 0, 100, 'linearTween');
    // }
    setSelectedMenu(category);
    if (target !== null && target !== undefined && parent !== null && parent !== undefined) {
      scrollToWithAnimation(parent, 'scrollLeft', target.offsetLeft, 100, 'linearTween');
    }
  };

  return (
    <div
      ref={categoriesMenu}
      className="relt px-2 flex overflow-hidden overflow-x-scroll no-scrollbar pt-5 pb-2 bg-white">
      {/* <div className="flex w-fit"> */}
      {merchant.categories?.map((category: Category) =>
        category.childs && category.childs.length > 0 ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onSelectMenu(e, category);
            }}
            key={category.id}
            style={{
              backgroundColor: isSelected(category) ? GetMenuColor(merchant) : '#f1f5f9',
              color: isSelected(category) ? '#fff' : '#64748b'
            }}
            className={`whitespace-nowrap p-1 text-xs px-3 py-2 rounded-full ml-2 item-not-selected-bg font-bold  ${SelectModeValue(
              merchant,
              'customBarkBackground text-white',
              'bg-gray-100'
            )}`}>
            {category.name}
          </div>
        ) : null
      )}
      {/* </div> */}
    </div>
  );
};

export default React.memo(MenusNavigator);
