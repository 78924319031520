import React, { useContext, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { selectSelectedFav, selectSelectedLabels, setSelectedFav } from '../store/appSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import SavedIndicator from './SavedIndicator';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { getParamByISO } from 'iso-country-currency';
import MerchantContext from '../utils/contexts/MerchantContext';
import QuillDisplay from './QuillDisplay';
import { DarkModeValueTextColor, GetMenuColor } from '../utils/useFullFunctions';
// import { Product } from '@neatin/api-client/dist/products/types';
import ProductOrder from './ProductOrder';
import PreferenceAndAllergensSection from './PreferenceAndAllergensSection';
// import ModifiersSection from './ModifiersSection';
import Variants from './Variants';
import AddonGroup from './AddonGroup';

interface PopupInfosProps {
  item: any; // Product type should be updated
  closePopup: () => void;
}
const PopupProduct: React.FC<PopupInfosProps> = ({ item, closePopup }) => {
  // console.log('ITEM : ', item);
  const selectedLabels = useAppSelector(selectSelectedLabels);
  const merchant: any = useContext(MerchantContext);
  const [selectedVariant, setSelectedVariant] = useState<number | null>(null);
  const [selectedAddons, setSelectedAddons] = useState<any>([]);
  const currencySymbol =
    merchant?.country !== null && merchant?.country !== undefined
      ? getParamByISO(merchant.country ?? 'GE', 'symbol')
      : '';
  const productNameRef = useRef<HTMLDivElement>(null);
  const productTopNameRef = useRef<HTMLDivElement>(null);
  const scrollableArea = useRef<HTMLDivElement>(null);

  const selectedFav = useAppSelector(selectSelectedFav);
  const dispatch = useAppDispatch();

  const toggleFavFilter = (): void => {
    let newFavFilter: number[];
    if (!selectedFav.includes(item.id)) {
      newFavFilter = [...selectedFav, item.id];
    } else {
      newFavFilter = selectedFav.filter((e: any) => e !== item.id);
    }

    dispatch(setSelectedFav([...newFavFilter]));
  };

  useEffect(() => {
    const scrollEvent = (): void => {
      const nameHeight =
        window.innerHeight * (item.image !== null && item.image !== undefined ? 0.19 : 0.0005);
      const scrollHeight = scrollableArea?.current?.scrollTop ?? 0;
      if (
        productTopNameRef?.current?.style !== undefined &&
        scrollHeight !== null &&
        scrollHeight !== undefined
      ) {
        productTopNameRef.current.style.opacity = String((scrollHeight - nameHeight) / 100);
      }
    };
    const element = scrollableArea?.current;
    element?.addEventListener('scroll', scrollEvent);
    return (): void => {
      element?.removeEventListener('scroll', scrollEvent);
    };
  });

  const toggleFavourite = (): void => {
    toggleFavFilter();
  };

  return (
    <>
      <div className={'relt bg-white text-gray-800 rounded-t-3xl py-4 w-full overflow-hidden'}>
        <div className=" relt flex z-10">
          <span
            className=" relt rounded-3xl mx-auto"
            style={{ height: 4, width: 128, backgroundColor: '#CBD5E1' }}></span>
          <div
            className=" relt bg-white w-full absolute h-10 top-0 rounded-t-3xl font-bold flex pl-5 items-center"
            style={{ opacity: 0 }}
            ref={productTopNameRef}>
            <p className=" relt w-5/6 truncate text-2xl">{item.name}</p>
          </div>
          <div className=" p-2 mr-1 absolute right-0 top-2" onClick={closePopup}>
            <CloseIcon />
          </div>
        </div>
        <div>
          <div
            ref={scrollableArea}
            className={`overflow-y-scroll overflow-x-none ${
              item?.image !== null && item?.image !== undefined ? '-mt-7' : ''
            }`}
            style={{
              height:
                window.innerHeight *
                (item?.image !== null && item?.image !== undefined ? 0.7 : 0.63)
            }}>
            {item.image != null && String(item.image).trim() !== '' && (
              <div
                className={
                  (item?.image !== null && item?.image !== undefined ? 'w-full ' : 'w-auto ') +
                  ' aspect-video'
                }
                style={{
                  backgroundImage:
                    item?.image !== null &&
                    item?.image !== undefined &&
                    process.env.REACT_APP_UPLOADS_URL !== null &&
                    process.env.REACT_APP_UPLOADS_URL !== undefined
                      ? `url('${process.env.REACT_APP_UPLOADS_URL}/${item.image as string}')`
                      : undefined,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              />
            )}
            <div className=" relt p-6">
              <div className={`w-full flex justify-between  mb-4`} ref={productNameRef}>
                <div className=" relt w-auto flex">
                  <div className=" relt text-2xl md:text-3xl font-bold break-all">{item.name}</div>
                </div>
                <div
                  className=" relt h-auto flex flex-nowrap justify-end"
                  onClick={(e) => {
                    toggleFavourite();
                  }}>
                  <div
                    className={
                      selectedFav.includes(item.id)
                        ? 'h-full flex flex-wrap content-center text-green-500'
                        : 'h-full flex flex-wrap content-center'
                    }>
                    <SavedIndicator item={item} />
                  </div>
                </div>
              </div>
              <div
                className=" relt text-xl font-bold mt-2"
                style={{ color: GetMenuColor(merchant) }}>
                {item.price} {currencySymbol}
              </div>
              <div>
                <QuillDisplay
                  html={item.description}
                  style={{
                    height: 'auto',
                    padding: 0,
                    color: DarkModeValueTextColor(merchant, '#64748B')
                  }}
                />
              </div>
              {item.labels !== null &&
                item.labels !== undefined &&
                item.labels
                  .filter((label: any) => label.type === 'allergen')
                  .filter((label: any) => selectedLabels.includes(label.id)).length > 0 && (
                  <p className=" relt font-bold my-2" style={{ color: '#D01F46', fontSize: 12 }}>
                    <Trans i18nKey={'this-food-contains-one-or-more-of-your-allergens'}></Trans>
                  </p>
                )}
              {/* <ModifiersSection item={item} /> */}
              <Variants item={item} setVariantCb={setSelectedVariant} />

              {item?.categories
                ?.filter((addonGroup: any) => addonGroup.products.length > 0)
                ?.sort((a: any, b: any) => a.skippable - b.skippable)
                .map((addonGroup: any) => (
                  <AddonGroup
                    item={addonGroup}
                    key={addonGroup.id}
                    setAddonsCb={setSelectedAddons}
                  />
                ))}
              <PreferenceAndAllergensSection item={item} />
            </div>
            <div className="h-10"></div>
          </div>

          {merchant?.pos_enable === 1 && merchant.table !== null && merchant.table !== undefined ? (
            <div className="px-6">
              <ProductOrder
                item={item}
                variant={selectedVariant}
                addons={selectedAddons}
                afterAddCb={() => closePopup()}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default React.memo(PopupProduct);
