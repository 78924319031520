import qs from 'querystringify'
import { Base, Pagination } from '../base'
 
const resourceName = 'payments'

export class Payments extends Base {

    createRapydPayout (url_id) {
        return this.request<any>(`${resourceName}/rapyd/payouts`, {
            method: 'POST',
            body: JSON.stringify({ url_id })
        })
    }

    refreshKycStatus (url_id: string) {
        return this.request<any>(`${resourceName}/kyc/${url_id}`)
    }

    getWallet (url_id: string) {
        return this.request<any>(`${resourceName}/wallets/${url_id}`)
    }
}
