import React, { useContext, useState } from 'react';
import { SwipeableDrawer } from '@mui/material';
import PopupLanguage from './PopupLanguages';
import { useAppSelector } from '../store/hooks';
import { selectSelectedLanguage } from '../store/appSlice';
import ReactCountryFlag from 'react-country-flag';
import { SelectModeValue } from '../utils/useFullFunctions';
import { MdKeyboardArrowDown } from 'react-icons/md';
import MerchantContext from '../utils/contexts/MerchantContext';

const LanguageIndicator: React.FC<{}> = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const merchant = useContext(MerchantContext);
  const closeModal = (): void => {
    setOpenModal(false);
  };
  const selectedLanguage = useAppSelector(selectSelectedLanguage);
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <SwipeableDrawer
        className="customDrawer"
        anchor="bottom"
        open={openModal}
        onClose={() => {
          closeModal();
        }}
        onOpen={() => {
          setOpenModal(true);
        }}
        disableBackdropTransition={!iOS}
        disableDiscovery={true}
        disableSwipeToOpen={true}>
        <React.Suspense fallback={null}>
          <PopupLanguage closePopup={closeModal} />
        </React.Suspense>
      </SwipeableDrawer>
      <div className="flex justify-end h-fit">
        <div
          className="rounded-3xl p-1 font-bold flex items-center"
          style={{
            backgroundColor: SelectModeValue(merchant, '#424543', '#F1F5F9') as any,
            color: SelectModeValue(merchant, '#fff', '#64748B') as any,
            fontSize: 12
          }}
          onClick={() => setOpenModal(true)}>
          <span className="mx-1">
            <MdKeyboardArrowDown />
          </span>
          {selectedLanguage != null && (
            <ReactCountryFlag
              countryCode={
                String(selectedLanguage ?? '') === 'en' ? 'gb' : selectedLanguage?.toUpperCase()
              }
              svg
            />
          )}
          <span className="px-1">{String(selectedLanguage).toUpperCase()}</span>
        </div>
      </div>
    </>
  );
};

export default LanguageIndicator;
