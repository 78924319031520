import { Product } from '@neatin/api-client/dist/products/types';
import React, { useCallback, useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useAppSelector } from '../store/hooks';
import { Order, orderTotalPrice, selectOrderItems } from '../store/orderSlice';
import MerchantContext from '../utils/contexts/MerchantContext';
import { getParamByISO } from 'iso-country-currency';
import { GetMenuColor } from '../utils/useFullFunctions';
// import PopupCart from './PopupCart';
import { useNavigate, useParams } from 'react-router-dom';
import Big from 'big.js';
import GA4React from 'ga-4-react';
import { selectSelecteCookie } from '../store/appSlice';

const CartOverview: React.FC<{}> = () => {
  const cart = useAppSelector(selectOrderItems) ?? [];
  const merchant = useContext(MerchantContext);
  const navigate = useNavigate();
  const params = useParams();
  const ga4: any = GA4React.getGA4React();
  const selectCookie = useAppSelector(selectSelecteCookie);

  const isMenu = useCallback(
    (merchant: any): boolean => {
      return (
        merchant?.categories?.[0]?.childs !== null &&
        merchant?.categories?.[0]?.childs !== undefined
      );
    },
    [1]
  );

  const getCategories = useCallback(
    (merchant: any): any[] => {
      if (isMenu(merchant)) {
        const categories = [];
        for (const c of merchant.categories) {
          categories.push(...c.childs);
        }
        return categories;
      }
      return merchant.categories;
    },
    [1]
  );

  const currencySymbol =
    merchant?.country !== null && merchant?.country !== undefined
      ? getParamByISO(merchant.country ?? 'GE', 'symbol')
      : '';

  const getProductsInCart = useCallback((): Order[] => {
    const res: Product[] = [];
    for (const category of getCategories(merchant)) {
      for (const product of category.products) {
        res.push(product);
      }
    }

    const inCart = cart.filter((order) =>
      res.map((product) => product.id).includes(order.productId)
    );

    return inCart;
  }, [cart]);

  const subTotal = useMemo((): Big => {
    return orderTotalPrice(merchant, cart);
  }, [cart, merchant]);

  const totalQuantity = useMemo(
    () =>
      getProductsInCart()
        ?.map((order) => order.quantity)
        ?.reduce((acc, cur) => acc + cur, 0) ?? 0,
    [getProductsInCart]
  );

  return (
    <>
      {subTotal.gt(0) ? (
        <>
          <div className="fixed w-full flex justify-evenly left-0" style={{ bottom: 70 }}>
            <div
              // className="h-10 px-3 w-10/12 max-w-2xl rounded-full text-white flex justify-between items-center shadow-lg"
              className="p-3 mx-auto max-w-2xl rounded-full text-white font-bold w-10/12 flex justify-between"
              style={{ backgroundColor: GetMenuColor(merchant) }}
              onClick={() => {
                try {
                  if (selectCookie?.consent?.analytics) {
                    ga4.event('view_order', merchant?.name, 'order', true);
                  }
                } catch (error) {}

                if (merchant?.table?.url_id !== null && merchant?.table?.url_id !== undefined) {
                  navigate(
                    `/${params.restaurant_id as string}/cart?table=${
                      merchant.table.url_id as string
                    }`
                  );
                } else {
                  navigate(`/${params.restaurant_id as string}/cart`);
                }
                // setOpenModal(true);
              }}>
              <div className="flex">
                <span
                  className="bg-white h-6 leading-6 aspect-square rounded-full text-center mr-2 p"
                  style={{ color: GetMenuColor(merchant), fontSize: 10 }}>
                  {totalQuantity}
                </span>
                <span style={{ fontSize: 16 }}>
                  <Trans i18nKey="view-order"></Trans>
                </span>
              </div>
              <div className="font-bold">
                {subTotal.valueOf()} {currencySymbol}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default CartOverview;
