import qs from 'querystringify'
import { NewMerchantPaymentSetting, MerchantPaymentSetting, SearchMerchantPaymentSettingsParams } from './types'
import { Base, Pagination } from '../base'

const resourceName = 'merchant-payment-settings'

export class MerchantPaymentSettings extends Base {
    getMerchantPaymentSettings(params?: SearchMerchantPaymentSettingsParams) {
        let query = `${resourceName}`
        if (params) {
            query += qs.stringify(params, '?')
        }
        return this.request<MerchantPaymentSetting[]>(query)
    }

    getMerchantPaymentSetting(id: number) {
        return this.request<MerchantPaymentSetting>(`${resourceName}/${id}`)
    }

    getRapydVerificationLink(id: number) {
        return this.request<MerchantPaymentSetting>(`${resourceName}/get-rapyd-verification-link/${id}`)
    }

    createMerchantPaymentSetting(params: NewMerchantPaymentSetting) {
        return this.request<MerchantPaymentSetting>(resourceName, {
            method: 'POST',
            body: JSON.stringify({ ...params })
        })
    }

    updateMerchantPaymentSetting(id: number, params?: NewMerchantPaymentSetting) {
        return this.request<MerchantPaymentSetting>(`${resourceName}/${id}`, {
            method: 'PUT',
            body: JSON.stringify({ ...params })
        })
    }

    deleteMerchantPaymentSetting(id: number) {
        return this.request<MerchantPaymentSetting>(`${resourceName}/${id}`, { method: 'DELETE' })
    }

    getMerchantPaymentSettingByMerchantAndPaymentType(merchant_id: number,payment_type_id:number) {
        return this.request<MerchantPaymentSetting>(`${resourceName}/get-by/merchant/${merchant_id}/payment_type/${payment_type_id}`)
    }

}
