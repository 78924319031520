import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from '../icons/plus.svg';
import { ReactComponent as MinusIcon } from '../icons/minus.svg';
import { GetMenuColor } from '../utils/useFullFunctions';
import α from 'color-alpha';
import MerchantContext from '../utils/contexts/MerchantContext';

interface ProductCounterProps {
  initialValue?: number;
  direction?: 'vertical' | 'horizontal';
  getCount?: (n: number) => any;
}

const ProductCounter: React.FC<ProductCounterProps> = ({
  initialValue = 1,
  direction = 'horizontal',
  getCount
}) => {
  const [count, setCount] = useState<number>(initialValue);
  const merchant = useContext(MerchantContext);
  const increment = (): void => {
    setCount((count) => count + 1);
  };

  const decrement = (): void => {
    setCount((count) => Math.max(count - 1, 0));
  };

  useEffect(() => {
    if (getCount !== null && getCount !== undefined && count !== initialValue) {
      getCount(count);
    }
  }, [count]);

  return (
    <div
      className={`grid ${
        direction === 'horizontal' ? 'grid-cols-3 w-full' : 'grid-rows-3 w-fit'
      } items-center text-center border rounded-md p-2`}
      style={{
        backgroundColor: α(GetMenuColor(merchant), 0.1),
        borderColor: GetMenuColor(merchant)
      }}>
      {direction === 'horizontal' ? (
        <span className="mx-auto" style={{ color: '#0D9488' }} onClick={decrement}>
          <MinusIcon />
        </span>
      ) : (
        <span className="mx-auto" style={{ color: '#D01F46' }} onClick={increment}>
          <PlusIcon />
        </span>
      )}
      <span className="font-bold" style={{ fontSize: 14 }}>
        {count}
      </span>

      {direction === 'horizontal' ? (
        <span className="mx-auto" style={{ color: '#D01F46' }} onClick={increment}>
          <PlusIcon />
        </span>
      ) : (
        <span className="mx-auto" style={{ color: '#0D9488' }} onClick={decrement}>
          <MinusIcon />
        </span>
      )}
    </div>
  );
};

export default React.memo(ProductCounter);
