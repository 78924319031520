import { Base } from '../base'
import { Order } from '../orders/types'

const resourceName = 'orders-parallel-system'

export class OrdersParallelSystem extends Base {
  getCurrentDayOrders(merchant_id: string): Promise<any> {
    return this.request<Order[]>(
      `${resourceName}/orders?merchant_id=${merchant_id}`
    )
  }

  updateOrderState(order_id: string, state: string): Promise<any> {
    return this.request<Order[]>(
      `${resourceName}/orders/${order_id}/${state}`,
      {
        method: 'PUT'
      }
    )
  }
}
