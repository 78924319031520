/* eslint-disable */
import React, { useContext } from 'react';
import { GetMenuColor } from '../utils/useFullFunctions';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import GoogleLogo from '../icons/google-logo.svg';
import TripadvisorLogo from '../icons/tripadvisor-logo.svg';
import { Trans } from 'react-i18next';
import MerchantContext from '../utils/contexts/MerchantContext';

const OpenApp = require('react-open-app').default;

interface PopupPaymentReviewProps {
  closePopup: () => void;
}
const PopupPaymentReview: React.FC<PopupPaymentReviewProps> = ({ closePopup }) => {
  const merchant = useContext(MerchantContext);
  const settings =
    merchant?.old_settings?.value != null && merchant?.old_settings?.value !== undefined
      ? JSON.parse(merchant.old_settings.value.replace('\n', ''))
      : null;

  const showGoogleReviews: boolean =
    settings?.google?.review_url?.length > 0 && settings?.google?.active;

  const showTripAdvisor: boolean =
    settings?.tripadvisor?.review_url?.length > 0 && settings?.tripadvisor?.active;

  return (
    <>
      <div
        className={`relt text-gray-800 rounded-t-3xl py-4 w-full overflow-hidden p-4`}
        style={{ borderColor: GetMenuColor(merchant) }}>
        <div className=" relt flex z-10">
          <span
            className=" relt rounded-3xl mx-auto"
            style={{ height: 4, width: 128, backgroundColor: '#CBD5E1' }}></span>
          <div className=" p-2 mr-1 absolute right-0 top-2" onClick={closePopup}>
            <CloseIcon />
          </div>
        </div>
        <div>
          <h3 className="font-bold mt-5">
            <Trans i18nKey={'share-some-love-on'}></Trans>
          </h3>
          <div className="py-10">
            {showGoogleReviews ? (
              <OpenApp href={settings?.google?.review_url} blank={true}>
                <button className="border border-1 w-full rounded-lg mb-2 flex justify-evenly p-3">
                  <img src={GoogleLogo} style={{ height: 30 }} />
                </button>
              </OpenApp>
            ) : null}
            {showTripAdvisor ? (
              <OpenApp href={settings?.tripadvisor?.review_url} blank={true}>
                <button className="border border-1 w-full rounded-lg flex justify-evenly p-3">
                  <img src={TripadvisorLogo} style={{ height: 30 }} />
                </button>
              </OpenApp>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupPaymentReview;
